import * as reactGA from "react-ga";
import { FieldsObject } from "react-ga";
import { TimingArgs } from "react-ga";
import { EventArgs } from "react-ga";

class DimensionsMapping {
  public static readonly translatedVideoID = "dimension1"; // session level
  public static readonly initialSegmentCount = "dimension2"; // session level
  public static readonly initialSourceWordsCount = "dimension3"; // session level
  public static readonly initialTranslatedWordsCount = "dimension4"; // session level
  public static readonly finalSegmentCount = "dimension5"; //session level
  public static readonly finalWordCount = "dimension6"; // session level
  public static readonly finalSourceWordCount = "dimension7"; // session level
  public static readonly finalTranslatedWordCount = "dimension8"; // session level;
  public static readonly segmentID = "dimension9"; // hit level
  public static readonly translatedChannelID = "dimension10"; // session level
  public static readonly userSub = "dimension11"; // user level
}

type DimensionNames = keyof typeof DimensionsMapping;
export type PapercupTrackerNames = ("default" | "experimental")[];

type DimensionFieldsObject = {
  [key in DimensionNames]?: string;
};

interface ExtendedGA {
  set(fieldsObject: FieldsObject, trackerNames?: PapercupTrackerNames): void;
  send(fieldsObject: FieldsObject, trackerNames?: PapercupTrackerNames): void;
  ga(...args: any[]): any;
  pageview(
    path: string,
    trackerNames: PapercupTrackerNames,
    title?: string
  ): void;
  modalview(name: string, trackerNames: PapercupTrackerNames): void;
  timing(args: TimingArgs, trackerNames?: PapercupTrackerNames): void;
  event(args: EventArgs, trackerNames?: PapercupTrackerNames): void;
  segmentEvent(
    args: EventArgs & { segmentID: string },
    trackerNames?: PapercupTrackerNames
  ): void;
  setDimensions(
    fieldsObject: DimensionFieldsObject,
    trackerNames?: PapercupTrackerNames
  ): void;
}

const defaultTrackers: PapercupTrackerNames = ["experimental", "default"];

export const GA: ExtendedGA = {
  ...reactGA,
  event: ({ ...eventArgs }, trackerNames = defaultTrackers) =>
    reactGA.event(eventArgs, trackerNames),
  set: (args, trackerNames = defaultTrackers) =>
    reactGA.set(args, trackerNames),
  send: (args, trackerNames = defaultTrackers) =>
    reactGA.send(args, trackerNames),
  modalview: (args, trackerNames = defaultTrackers) =>
    reactGA.modalview(args, trackerNames),
  timing: (args, trackerNames = defaultTrackers) =>
    reactGA.timing(args, trackerNames),
  setDimensions: (fieldsObject, trackerNames = defaultTrackers) => {
    const transformed = Object.keys(fieldsObject).reduce((acc, v) => {
      const dimensionName = DimensionsMapping[v as DimensionNames] as string;
      const dimensionValue = fieldsObject[v as DimensionNames] as any;
      return {
        ...acc,
        [dimensionName]: dimensionValue
      };
    }, {});
    reactGA.set(transformed, trackerNames);
  },
  segmentEvent: (
    { segmentID, ...eventArgs },
    trackerNames = defaultTrackers
  ) => {
    reactGA.set({ [DimensionsMapping["segmentID"]]: segmentID }, trackerNames);
    reactGA.event(eventArgs, trackerNames);
  }
};
