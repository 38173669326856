import { gql } from "apollo-boost";

export const SegmentFragment = gql`
  fragment SegmentFragment on Segment {
    id
    text
    translatedText
    stateValue
    stretchRate
    previewSample {
      id
      audioDuration
      audioFile {
        URL
      }
      stateValue
      papercupSpeakerId
      text
      style
      stretchRate
    }
    papercupSpeakerId
    style
    done
    endTime
    startTime
    translationStartTime
    sourceLanguage
    targetLanguage
  }
`;

export const GET_TRANSLATED_VIDEO_SEGMENTS = gql`
  query translatedVideoWithSegments($id: ID!) {
    translatedVideo(where: { id: $id }) {
      id
      segments {
        ...SegmentFragment
      }
    }
  }
  ${SegmentFragment}
`;
