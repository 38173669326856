import { useEffect } from "react";
import * as GA from "react-ga";
import {
  GA_TRACKING_ID,
  GA_TRACKING_ID_EXPERIMENTAL
} from "../../constants/env";
import { useRouter } from "next/router";

export const useGA = () => {
  const router = useRouter();
  useEffect(() => {
    // @ts-ignore
    if (window && !window.GA_INITIALIZED) {
      GA.initialize(
        [
          { trackingId: GA_TRACKING_ID, gaOptions: { name: "default" } },
          {
            trackingId: GA_TRACKING_ID_EXPERIMENTAL,
            gaOptions: { name: "experimental" }
          }
        ],
        { debug: false, alwaysSendToDefaultTracker: false }
      );
      // @ts-ignore
      window.GA_INITIALIZED = true;
      router.events.on("routeChangeComplete", (url: string) => {
        GA.set({
          page: url
        });

        // Studio emits own pageview events
        if (!url.startsWith("/studio")) {
          GA.pageview(url, ["default", "experimental"]);
        }
      });
    }
  }, [window]);
};
