export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  Json: any,
  /** 
 * The JsSafeInt scalar type represents non-fractional signed whole numeric values.
   * JsSafeInt can represent values between -(2^53 - 1) and 2^53 - 1.
 **/
  JsSafeInt: any,
  Long: any,
};

export type BatchPayload = {
   __typename?: 'BatchPayload',
  count: Scalars['Long'],
};

export enum ChannelType {
  YouTube = 'YouTube',
  Default = 'Default'
}

export type Correction = {
   __typename?: 'Correction',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  createdBy?: Maybe<User>,
  channel?: Maybe<TranslatedChannel>,
  video?: Maybe<TranslatedVideo>,
  segment?: Maybe<Segment>,
  originalText?: Maybe<Scalars['String']>,
  originalWord: Scalars['String'],
  originalSample?: Maybe<Sample>,
  language: Scalars['String'],
  pronunciationLanguage?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  applyToAllSpeakers?: Maybe<Scalars['Boolean']>,
  speakers?: Maybe<Array<Speaker>>,
  s3root: Scalars['String'],
  applicationLevel?: Maybe<CorrectionApplicationLevel>,
  appliedCorrection?: Maybe<CorrectionAttempt>,
};


export type CorrectionSpeakersArgs = {
  where?: Maybe<SpeakerWhereInput>,
  orderBy?: Maybe<SpeakerOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export enum CorrectionApplicationLevel {
  All = 'All',
  Channel = 'Channel',
  Video = 'Video',
  Segment = 'Segment'
}

export type CorrectionAttempt = {
   __typename?: 'CorrectionAttempt',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  correction: Correction,
  text: Scalars['String'],
  correctedWord: Scalars['String'],
  sample?: Maybe<Sample>,
  startTime?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  charPhones: Array<Scalars['String']>,
  state: Scalars['Json'],
  stateValue: CorrectionAttemptState,
};

export type CorrectionAttemptCreatecharPhonesInput = {
  set?: Maybe<Array<Scalars['String']>>,
};

export type CorrectionAttemptCreateWithoutCorrectionInput = {
  id?: Maybe<Scalars['ID']>,
  text: Scalars['String'],
  correctedWord: Scalars['String'],
  sample?: Maybe<SampleCreateOneInput>,
  startTime?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  charPhones?: Maybe<CorrectionAttemptCreatecharPhonesInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<CorrectionAttemptState>,
};

export enum CorrectionAttemptOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  CorrectedWordAsc = 'correctedWord_ASC',
  CorrectedWordDesc = 'correctedWord_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export enum CorrectionAttemptState {
  Initial = 'Initial',
  GeneratingPhones = 'GeneratingPhones',
  Complete = 'Complete'
}

export type CorrectionAttemptUpdatecharPhonesInput = {
  set?: Maybe<Array<Scalars['String']>>,
};

export type CorrectionAttemptUpdateOneWithoutCorrectionInput = {
  create?: Maybe<CorrectionAttemptCreateWithoutCorrectionInput>,
  update?: Maybe<CorrectionAttemptUpdateWithoutCorrectionDataInput>,
  upsert?: Maybe<CorrectionAttemptUpsertWithoutCorrectionInput>,
  delete?: Maybe<Scalars['Boolean']>,
  disconnect?: Maybe<Scalars['Boolean']>,
  connect?: Maybe<CorrectionAttemptWhereUniqueInput>,
};

export type CorrectionAttemptUpdateWithoutCorrectionDataInput = {
  text?: Maybe<Scalars['String']>,
  correctedWord?: Maybe<Scalars['String']>,
  sample?: Maybe<SampleUpdateOneInput>,
  startTime?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  charPhones?: Maybe<CorrectionAttemptUpdatecharPhonesInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<CorrectionAttemptState>,
};

export type CorrectionAttemptUpsertWithoutCorrectionInput = {
  update: CorrectionAttemptUpdateWithoutCorrectionDataInput,
  create: CorrectionAttemptCreateWithoutCorrectionInput,
};

export type CorrectionAttemptWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  correction?: Maybe<CorrectionWhereInput>,
  text?: Maybe<Scalars['String']>,
  text_not?: Maybe<Scalars['String']>,
  text_in?: Maybe<Array<Scalars['String']>>,
  text_not_in?: Maybe<Array<Scalars['String']>>,
  text_lt?: Maybe<Scalars['String']>,
  text_lte?: Maybe<Scalars['String']>,
  text_gt?: Maybe<Scalars['String']>,
  text_gte?: Maybe<Scalars['String']>,
  text_contains?: Maybe<Scalars['String']>,
  text_not_contains?: Maybe<Scalars['String']>,
  text_starts_with?: Maybe<Scalars['String']>,
  text_not_starts_with?: Maybe<Scalars['String']>,
  text_ends_with?: Maybe<Scalars['String']>,
  text_not_ends_with?: Maybe<Scalars['String']>,
  correctedWord?: Maybe<Scalars['String']>,
  correctedWord_not?: Maybe<Scalars['String']>,
  correctedWord_in?: Maybe<Array<Scalars['String']>>,
  correctedWord_not_in?: Maybe<Array<Scalars['String']>>,
  correctedWord_lt?: Maybe<Scalars['String']>,
  correctedWord_lte?: Maybe<Scalars['String']>,
  correctedWord_gt?: Maybe<Scalars['String']>,
  correctedWord_gte?: Maybe<Scalars['String']>,
  correctedWord_contains?: Maybe<Scalars['String']>,
  correctedWord_not_contains?: Maybe<Scalars['String']>,
  correctedWord_starts_with?: Maybe<Scalars['String']>,
  correctedWord_not_starts_with?: Maybe<Scalars['String']>,
  correctedWord_ends_with?: Maybe<Scalars['String']>,
  correctedWord_not_ends_with?: Maybe<Scalars['String']>,
  sample?: Maybe<SampleWhereInput>,
  startTime?: Maybe<Scalars['Float']>,
  startTime_not?: Maybe<Scalars['Float']>,
  startTime_in?: Maybe<Array<Scalars['Float']>>,
  startTime_not_in?: Maybe<Array<Scalars['Float']>>,
  startTime_lt?: Maybe<Scalars['Float']>,
  startTime_lte?: Maybe<Scalars['Float']>,
  startTime_gt?: Maybe<Scalars['Float']>,
  startTime_gte?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  endTime_not?: Maybe<Scalars['Float']>,
  endTime_in?: Maybe<Array<Scalars['Float']>>,
  endTime_not_in?: Maybe<Array<Scalars['Float']>>,
  endTime_lt?: Maybe<Scalars['Float']>,
  endTime_lte?: Maybe<Scalars['Float']>,
  endTime_gt?: Maybe<Scalars['Float']>,
  endTime_gte?: Maybe<Scalars['Float']>,
  stateValue?: Maybe<CorrectionAttemptState>,
  stateValue_not?: Maybe<CorrectionAttemptState>,
  stateValue_in?: Maybe<Array<CorrectionAttemptState>>,
  stateValue_not_in?: Maybe<Array<CorrectionAttemptState>>,
  AND?: Maybe<Array<CorrectionAttemptWhereInput>>,
  OR?: Maybe<Array<CorrectionAttemptWhereInput>>,
  NOT?: Maybe<Array<CorrectionAttemptWhereInput>>,
};

export type CorrectionAttemptWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export enum CorrectionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  OriginalTextAsc = 'originalText_ASC',
  OriginalTextDesc = 'originalText_DESC',
  OriginalWordAsc = 'originalWord_ASC',
  OriginalWordDesc = 'originalWord_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  PronunciationLanguageAsc = 'pronunciationLanguage_ASC',
  PronunciationLanguageDesc = 'pronunciationLanguage_DESC',
  PublishedAsc = 'published_ASC',
  PublishedDesc = 'published_DESC',
  ApplyToAllSpeakersAsc = 'applyToAllSpeakers_ASC',
  ApplyToAllSpeakersDesc = 'applyToAllSpeakers_DESC',
  S3rootAsc = 's3root_ASC',
  S3rootDesc = 's3root_DESC',
  ApplicationLevelAsc = 'applicationLevel_ASC',
  ApplicationLevelDesc = 'applicationLevel_DESC'
}

export type CorrectionUpdateInput = {
  createdBy?: Maybe<UserUpdateOneInput>,
  channel?: Maybe<TranslatedChannelUpdateOneInput>,
  video?: Maybe<TranslatedVideoUpdateOneInput>,
  segment?: Maybe<SegmentUpdateOneInput>,
  originalText?: Maybe<Scalars['String']>,
  originalWord?: Maybe<Scalars['String']>,
  originalSample?: Maybe<SampleUpdateOneInput>,
  language?: Maybe<Scalars['String']>,
  pronunciationLanguage?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  applyToAllSpeakers?: Maybe<Scalars['Boolean']>,
  speakers?: Maybe<SpeakerUpdateManyInput>,
  s3root?: Maybe<Scalars['String']>,
  applicationLevel?: Maybe<CorrectionApplicationLevel>,
  appliedCorrection?: Maybe<CorrectionAttemptUpdateOneWithoutCorrectionInput>,
};

export type CorrectionWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  createdBy?: Maybe<UserWhereInput>,
  channel?: Maybe<TranslatedChannelWhereInput>,
  video?: Maybe<TranslatedVideoWhereInput>,
  segment?: Maybe<SegmentWhereInput>,
  originalText?: Maybe<Scalars['String']>,
  originalText_not?: Maybe<Scalars['String']>,
  originalText_in?: Maybe<Array<Scalars['String']>>,
  originalText_not_in?: Maybe<Array<Scalars['String']>>,
  originalText_lt?: Maybe<Scalars['String']>,
  originalText_lte?: Maybe<Scalars['String']>,
  originalText_gt?: Maybe<Scalars['String']>,
  originalText_gte?: Maybe<Scalars['String']>,
  originalText_contains?: Maybe<Scalars['String']>,
  originalText_not_contains?: Maybe<Scalars['String']>,
  originalText_starts_with?: Maybe<Scalars['String']>,
  originalText_not_starts_with?: Maybe<Scalars['String']>,
  originalText_ends_with?: Maybe<Scalars['String']>,
  originalText_not_ends_with?: Maybe<Scalars['String']>,
  originalWord?: Maybe<Scalars['String']>,
  originalWord_not?: Maybe<Scalars['String']>,
  originalWord_in?: Maybe<Array<Scalars['String']>>,
  originalWord_not_in?: Maybe<Array<Scalars['String']>>,
  originalWord_lt?: Maybe<Scalars['String']>,
  originalWord_lte?: Maybe<Scalars['String']>,
  originalWord_gt?: Maybe<Scalars['String']>,
  originalWord_gte?: Maybe<Scalars['String']>,
  originalWord_contains?: Maybe<Scalars['String']>,
  originalWord_not_contains?: Maybe<Scalars['String']>,
  originalWord_starts_with?: Maybe<Scalars['String']>,
  originalWord_not_starts_with?: Maybe<Scalars['String']>,
  originalWord_ends_with?: Maybe<Scalars['String']>,
  originalWord_not_ends_with?: Maybe<Scalars['String']>,
  originalSample?: Maybe<SampleWhereInput>,
  language?: Maybe<Scalars['String']>,
  language_not?: Maybe<Scalars['String']>,
  language_in?: Maybe<Array<Scalars['String']>>,
  language_not_in?: Maybe<Array<Scalars['String']>>,
  language_lt?: Maybe<Scalars['String']>,
  language_lte?: Maybe<Scalars['String']>,
  language_gt?: Maybe<Scalars['String']>,
  language_gte?: Maybe<Scalars['String']>,
  language_contains?: Maybe<Scalars['String']>,
  language_not_contains?: Maybe<Scalars['String']>,
  language_starts_with?: Maybe<Scalars['String']>,
  language_not_starts_with?: Maybe<Scalars['String']>,
  language_ends_with?: Maybe<Scalars['String']>,
  language_not_ends_with?: Maybe<Scalars['String']>,
  pronunciationLanguage?: Maybe<Scalars['String']>,
  pronunciationLanguage_not?: Maybe<Scalars['String']>,
  pronunciationLanguage_in?: Maybe<Array<Scalars['String']>>,
  pronunciationLanguage_not_in?: Maybe<Array<Scalars['String']>>,
  pronunciationLanguage_lt?: Maybe<Scalars['String']>,
  pronunciationLanguage_lte?: Maybe<Scalars['String']>,
  pronunciationLanguage_gt?: Maybe<Scalars['String']>,
  pronunciationLanguage_gte?: Maybe<Scalars['String']>,
  pronunciationLanguage_contains?: Maybe<Scalars['String']>,
  pronunciationLanguage_not_contains?: Maybe<Scalars['String']>,
  pronunciationLanguage_starts_with?: Maybe<Scalars['String']>,
  pronunciationLanguage_not_starts_with?: Maybe<Scalars['String']>,
  pronunciationLanguage_ends_with?: Maybe<Scalars['String']>,
  pronunciationLanguage_not_ends_with?: Maybe<Scalars['String']>,
  published?: Maybe<Scalars['Boolean']>,
  published_not?: Maybe<Scalars['Boolean']>,
  applyToAllSpeakers?: Maybe<Scalars['Boolean']>,
  applyToAllSpeakers_not?: Maybe<Scalars['Boolean']>,
  speakers_every?: Maybe<SpeakerWhereInput>,
  speakers_some?: Maybe<SpeakerWhereInput>,
  speakers_none?: Maybe<SpeakerWhereInput>,
  s3root?: Maybe<Scalars['String']>,
  s3root_not?: Maybe<Scalars['String']>,
  s3root_in?: Maybe<Array<Scalars['String']>>,
  s3root_not_in?: Maybe<Array<Scalars['String']>>,
  s3root_lt?: Maybe<Scalars['String']>,
  s3root_lte?: Maybe<Scalars['String']>,
  s3root_gt?: Maybe<Scalars['String']>,
  s3root_gte?: Maybe<Scalars['String']>,
  s3root_contains?: Maybe<Scalars['String']>,
  s3root_not_contains?: Maybe<Scalars['String']>,
  s3root_starts_with?: Maybe<Scalars['String']>,
  s3root_not_starts_with?: Maybe<Scalars['String']>,
  s3root_ends_with?: Maybe<Scalars['String']>,
  s3root_not_ends_with?: Maybe<Scalars['String']>,
  applicationLevel?: Maybe<CorrectionApplicationLevel>,
  applicationLevel_not?: Maybe<CorrectionApplicationLevel>,
  applicationLevel_in?: Maybe<Array<CorrectionApplicationLevel>>,
  applicationLevel_not_in?: Maybe<Array<CorrectionApplicationLevel>>,
  appliedCorrection?: Maybe<CorrectionAttemptWhereInput>,
  AND?: Maybe<Array<CorrectionWhereInput>>,
  OR?: Maybe<Array<CorrectionWhereInput>>,
  NOT?: Maybe<Array<CorrectionWhereInput>>,
};

export type CorrectionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};


export enum DaysOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export type File = {
   __typename?: 'File',
  id: Scalars['ID'],
  /** URL for the File */
  URL: Scalars['String'],
  /** The extension of the file */
  extension: Scalars['String'],
};

export type FileCreateInput = {
  id?: Maybe<Scalars['ID']>,
  bucket?: Maybe<Scalars['String']>,
  key: Scalars['String'],
  provider?: Maybe<ProviderType>,
  region?: Maybe<Scalars['String']>,
};

export type FileCreateOneInput = {
  create?: Maybe<FileCreateInput>,
  connect?: Maybe<FileWhereUniqueInput>,
};

export type FileUpdateDataInput = {
  bucket?: Maybe<Scalars['String']>,
  key?: Maybe<Scalars['String']>,
  provider?: Maybe<ProviderType>,
  region?: Maybe<Scalars['String']>,
};

export type FileUpdateOneInput = {
  create?: Maybe<FileCreateInput>,
  update?: Maybe<FileUpdateDataInput>,
  upsert?: Maybe<FileUpsertNestedInput>,
  delete?: Maybe<Scalars['Boolean']>,
  disconnect?: Maybe<Scalars['Boolean']>,
  connect?: Maybe<FileWhereUniqueInput>,
};

export type FileUpdateOneRequiredInput = {
  create?: Maybe<FileCreateInput>,
  update?: Maybe<FileUpdateDataInput>,
  upsert?: Maybe<FileUpsertNestedInput>,
  connect?: Maybe<FileWhereUniqueInput>,
};

export type FileUpsertNestedInput = {
  update: FileUpdateDataInput,
  create: FileCreateInput,
};

export type FileWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  bucket?: Maybe<Scalars['String']>,
  bucket_not?: Maybe<Scalars['String']>,
  bucket_in?: Maybe<Array<Scalars['String']>>,
  bucket_not_in?: Maybe<Array<Scalars['String']>>,
  bucket_lt?: Maybe<Scalars['String']>,
  bucket_lte?: Maybe<Scalars['String']>,
  bucket_gt?: Maybe<Scalars['String']>,
  bucket_gte?: Maybe<Scalars['String']>,
  bucket_contains?: Maybe<Scalars['String']>,
  bucket_not_contains?: Maybe<Scalars['String']>,
  bucket_starts_with?: Maybe<Scalars['String']>,
  bucket_not_starts_with?: Maybe<Scalars['String']>,
  bucket_ends_with?: Maybe<Scalars['String']>,
  bucket_not_ends_with?: Maybe<Scalars['String']>,
  key?: Maybe<Scalars['String']>,
  key_not?: Maybe<Scalars['String']>,
  key_in?: Maybe<Array<Scalars['String']>>,
  key_not_in?: Maybe<Array<Scalars['String']>>,
  key_lt?: Maybe<Scalars['String']>,
  key_lte?: Maybe<Scalars['String']>,
  key_gt?: Maybe<Scalars['String']>,
  key_gte?: Maybe<Scalars['String']>,
  key_contains?: Maybe<Scalars['String']>,
  key_not_contains?: Maybe<Scalars['String']>,
  key_starts_with?: Maybe<Scalars['String']>,
  key_not_starts_with?: Maybe<Scalars['String']>,
  key_ends_with?: Maybe<Scalars['String']>,
  key_not_ends_with?: Maybe<Scalars['String']>,
  provider?: Maybe<ProviderType>,
  provider_not?: Maybe<ProviderType>,
  provider_in?: Maybe<Array<ProviderType>>,
  provider_not_in?: Maybe<Array<ProviderType>>,
  region?: Maybe<Scalars['String']>,
  region_not?: Maybe<Scalars['String']>,
  region_in?: Maybe<Array<Scalars['String']>>,
  region_not_in?: Maybe<Array<Scalars['String']>>,
  region_lt?: Maybe<Scalars['String']>,
  region_lte?: Maybe<Scalars['String']>,
  region_gt?: Maybe<Scalars['String']>,
  region_gte?: Maybe<Scalars['String']>,
  region_contains?: Maybe<Scalars['String']>,
  region_not_contains?: Maybe<Scalars['String']>,
  region_starts_with?: Maybe<Scalars['String']>,
  region_not_starts_with?: Maybe<Scalars['String']>,
  region_ends_with?: Maybe<Scalars['String']>,
  region_not_ends_with?: Maybe<Scalars['String']>,
  AND?: Maybe<Array<FileWhereInput>>,
  OR?: Maybe<Array<FileWhereInput>>,
  NOT?: Maybe<Array<FileWhereInput>>,
};

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export enum Frequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Biweekly = 'Biweekly',
  Monthly = 'Monthly'
}




export type Mutation = {
   __typename?: 'Mutation',
  updateSourceChannel?: Maybe<SourceChannel>,
  updateTranslatedChannel?: Maybe<TranslatedChannel>,
  updateTranslatedVideo?: Maybe<TranslatedVideo>,
  createCorrection: Correction,
  deleteCorrection?: Maybe<Correction>,
  deleteCorrectionAttempt?: Maybe<CorrectionAttempt>,
  updateCorrection?: Maybe<Correction>,
  /** Send a test Output Group complete email */
  sendTestOutputCompleteEmail: Scalars['String'],
  /** Send a test generic toAddress */
  sendTestGenericEmail: Scalars['String'],
  /** Send a test 'Ready for QA email' */
  sendTestReadyForQAEmail: Scalars['String'],
  /** Creates a new Cognito account for a user. Adds new user to Prisma. */
  createUser: User,
  /** Set a User Role for a user. */
  setUserRole: User,
  /** Disable a Cognito account for a user. Marks Prisma account as archived. */
  archiveUser?: Maybe<User>,
  syncUsersWithCognito: Scalars['String'],
  /** dev only utility to add all unsynced users to Prisma and set their role to Unknown */
  syncAllUsersWithCognito: Scalars['String'],
  addDefaultChannel: SourceChannel,
  addYouTubeChannel: SourceChannel,
  updateSourceVideo?: Maybe<SourceVideo>,
  addYouTubeVideo: SourceVideo,
  addUploadedVideo: SourceVideo,
  confirmUploadedVideo?: Maybe<SourceVideo>,
  resetSourceVideoState?: Maybe<SourceVideo>,
  /** 
 * Reset a translated video to the state when first added.
   * WARNING: This will delete all existing segments and samples for that video.
 **/
  hardResetTranslatedVideo?: Maybe<TranslatedVideo>,
  injectCorrectionsToVideo?: Maybe<TranslatedVideo>,
  injectCorrectionToVideo?: Maybe<TranslatedVideo>,
  createSegment: Segment,
  deleteSegment?: Maybe<Segment>,
  updateManySegments: BatchPayload,
  updateSegment?: Maybe<Segment>,
  /** Create a preview sample from the segment */
  createSampleFromSegment?: Maybe<Segment>,
  /** Create a preview sample from the segment */
  translateSegment?: Maybe<Segment>,
  moveSegment?: Maybe<Segment>,
  resetOutputGroupState?: Maybe<OutputGroup>,
  createVoice: Voice,
  updateVoice?: Maybe<Voice>,
  createCorrectionAttempt: CorrectionAttempt,
  moveTranslatedVideo?: Maybe<TranslatedVideo>,
};


export type MutationUpdateSourceChannelArgs = {
  data: SourceChannelUpdateInput,
  where: SourceChannelWhereUniqueInput
};


export type MutationUpdateTranslatedChannelArgs = {
  data: TranslatedChannelUpdateInput,
  where: TranslatedChannelWhereUniqueInput
};


export type MutationUpdateTranslatedVideoArgs = {
  data: TranslatedVideoUpdateInput,
  where: TranslatedVideoWhereUniqueInput
};


export type MutationCreateCorrectionArgs = {
  segmentId?: Maybe<Scalars['ID']>,
  channelId?: Maybe<Scalars['ID']>,
  language?: Maybe<Scalars['String']>,
  originalText: Scalars['String'],
  originalWord: Scalars['String'],
  pronunciationLanguage?: Maybe<Scalars['String']>,
  applicationLevel?: Maybe<CorrectionApplicationLevel>,
  published?: Maybe<Scalars['Boolean']>,
  applyToAllSpeakers?: Maybe<Scalars['Boolean']>
};


export type MutationDeleteCorrectionArgs = {
  where: CorrectionWhereUniqueInput
};


export type MutationDeleteCorrectionAttemptArgs = {
  where: CorrectionAttemptWhereUniqueInput
};


export type MutationUpdateCorrectionArgs = {
  data: CorrectionUpdateInput,
  where: CorrectionWhereUniqueInput
};


export type MutationSendTestOutputCompleteEmailArgs = {
  tvId?: Maybe<Scalars['ID']>,
  ogId?: Maybe<Scalars['ID']>,
  overrideEmail?: Maybe<Scalars['String']>
};


export type MutationSendTestGenericEmailArgs = {
  toAddress: Scalars['String'],
  subject: Scalars['String'],
  message: Scalars['String']
};


export type MutationSendTestReadyForQaEmailArgs = {
  tvId: Scalars['ID'],
  overrideEmail?: Maybe<Scalars['String']>
};


export type MutationCreateUserArgs = {
  email: Scalars['String'],
  cognitoUsername: Scalars['String'],
  userRole: UserRole
};


export type MutationSetUserRoleArgs = {
  cognitoUsername: Scalars['String'],
  userRole: UserRole
};


export type MutationArchiveUserArgs = {
  cognitoUsername: Scalars['String']
};


export type MutationAddDefaultChannelArgs = {
  title: Scalars['String'],
  targetLanguage: Scalars['String'],
  sourceLanguage: Scalars['String']
};


export type MutationAddYouTubeChannelArgs = {
  lookupType?: Maybe<YtChannelLookupType>,
  lookupValue: Scalars['String'],
  targetLanguage: Scalars['String'],
  sourceLanguage: Scalars['String'],
  ytChannelTitle?: Maybe<Scalars['String']>
};


export type MutationUpdateSourceVideoArgs = {
  data: SourceVideoUpdateInput,
  where: SourceVideoWhereUniqueInput
};


export type MutationAddYouTubeVideoArgs = {
  lookupType?: Maybe<YtVideoLookupType>,
  lookupValue: Scalars['String'],
  translatedChannelId: Scalars['ID'],
  title?: Maybe<Scalars['String']>
};


export type MutationAddUploadedVideoArgs = {
  translatedChannelId: Scalars['ID'],
  title?: Maybe<Scalars['String']>
};


export type MutationConfirmUploadedVideoArgs = {
  sourceVideoId: Scalars['ID'],
  title: Scalars['String']
};


export type MutationResetSourceVideoStateArgs = {
  id: Scalars['ID'],
  event: ResetSourceVideoEnumType
};


export type MutationHardResetTranslatedVideoArgs = {
  translatedVideoId: Scalars['ID']
};


export type MutationInjectCorrectionsToVideoArgs = {
  videoId: Scalars['ID']
};


export type MutationInjectCorrectionToVideoArgs = {
  videoId: Scalars['ID'],
  correctionId: Scalars['ID']
};


export type MutationCreateSegmentArgs = {
  data: SegmentCreateInput
};


export type MutationDeleteSegmentArgs = {
  where: SegmentWhereUniqueInput
};


export type MutationUpdateManySegmentsArgs = {
  data: SegmentUpdateManyMutationInput,
  where?: Maybe<SegmentWhereInput>
};


export type MutationUpdateSegmentArgs = {
  data: SegmentUpdateInput,
  where: SegmentWhereUniqueInput
};


export type MutationCreateSampleFromSegmentArgs = {
  id: Scalars['String']
};


export type MutationTranslateSegmentArgs = {
  id: Scalars['ID'],
  sourceLang: Scalars['String'],
  targetLang: Scalars['String']
};


export type MutationMoveSegmentArgs = {
  id: Scalars['ID'],
  state: Scalars['String']
};


export type MutationResetOutputGroupStateArgs = {
  id: Scalars['ID'],
  event: ResetOutputGroupEnumType
};


export type MutationCreateVoiceArgs = {
  data: VoiceCreateInput
};


export type MutationUpdateVoiceArgs = {
  id: Scalars['ID'],
  age?: Maybe<Scalars['Int']>,
  gender?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  styles?: Maybe<Array<Scalars['String']>>
};


export type MutationCreateCorrectionAttemptArgs = {
  correctionId: Scalars['ID'],
  text: Scalars['String'],
  correctedWord: Scalars['String']
};


export type MutationMoveTranslatedVideoArgs = {
  id: Scalars['ID'],
  state: Scalars['String']
};

export enum MutationType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED'
}

export type Output = {
   __typename?: 'Output',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  outputGroup: OutputGroup,
  type: OutputType,
  file: File,
};

export type OutputCreateManyWithoutOutputGroupInput = {
  create?: Maybe<Array<OutputCreateWithoutOutputGroupInput>>,
  connect?: Maybe<Array<OutputWhereUniqueInput>>,
};

export type OutputCreateWithoutOutputGroupInput = {
  id?: Maybe<Scalars['ID']>,
  type: OutputType,
  file: FileCreateOneInput,
};

export type OutputGroup = {
   __typename?: 'OutputGroup',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  state: Scalars['Json'],
  stateValue: OutputGroupState,
  s3root: Scalars['String'],
  translatedVideo: TranslatedVideo,
  samples?: Maybe<Array<Sample>>,
  outputs?: Maybe<Array<Output>>,
};


export type OutputGroupSamplesArgs = {
  where?: Maybe<SampleWhereInput>,
  orderBy?: Maybe<SampleOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type OutputGroupOutputsArgs = {
  where?: Maybe<OutputWhereInput>,
  orderBy?: Maybe<OutputOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type OutputGroupCreateManyWithoutTranslatedVideoInput = {
  create?: Maybe<Array<OutputGroupCreateWithoutTranslatedVideoInput>>,
  connect?: Maybe<Array<OutputGroupWhereUniqueInput>>,
};

export type OutputGroupCreateOneWithoutSamplesInput = {
  create?: Maybe<OutputGroupCreateWithoutSamplesInput>,
  connect?: Maybe<OutputGroupWhereUniqueInput>,
};

export type OutputGroupCreateWithoutSamplesInput = {
  id?: Maybe<Scalars['ID']>,
  state: Scalars['Json'],
  stateValue?: Maybe<OutputGroupState>,
  s3root: Scalars['String'],
  translatedVideo: TranslatedVideoCreateOneWithoutOutputGroupsInput,
  outputs?: Maybe<OutputCreateManyWithoutOutputGroupInput>,
};

export type OutputGroupCreateWithoutTranslatedVideoInput = {
  id?: Maybe<Scalars['ID']>,
  state: Scalars['Json'],
  stateValue?: Maybe<OutputGroupState>,
  s3root: Scalars['String'],
  samples?: Maybe<SampleCreateManyWithoutOutputGroupInput>,
  outputs?: Maybe<OutputCreateManyWithoutOutputGroupInput>,
};

export enum OutputGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  S3rootAsc = 's3root_ASC',
  S3rootDesc = 's3root_DESC'
}

export type OutputGroupScalarWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  stateValue?: Maybe<OutputGroupState>,
  stateValue_not?: Maybe<OutputGroupState>,
  stateValue_in?: Maybe<Array<OutputGroupState>>,
  stateValue_not_in?: Maybe<Array<OutputGroupState>>,
  s3root?: Maybe<Scalars['String']>,
  s3root_not?: Maybe<Scalars['String']>,
  s3root_in?: Maybe<Array<Scalars['String']>>,
  s3root_not_in?: Maybe<Array<Scalars['String']>>,
  s3root_lt?: Maybe<Scalars['String']>,
  s3root_lte?: Maybe<Scalars['String']>,
  s3root_gt?: Maybe<Scalars['String']>,
  s3root_gte?: Maybe<Scalars['String']>,
  s3root_contains?: Maybe<Scalars['String']>,
  s3root_not_contains?: Maybe<Scalars['String']>,
  s3root_starts_with?: Maybe<Scalars['String']>,
  s3root_not_starts_with?: Maybe<Scalars['String']>,
  s3root_ends_with?: Maybe<Scalars['String']>,
  s3root_not_ends_with?: Maybe<Scalars['String']>,
  AND?: Maybe<Array<OutputGroupScalarWhereInput>>,
  OR?: Maybe<Array<OutputGroupScalarWhereInput>>,
  NOT?: Maybe<Array<OutputGroupScalarWhereInput>>,
};

export enum OutputGroupState {
  Initial = 'Initial',
  GeneratingTunedSamples = 'GeneratingTunedSamples',
  GeneratingFullAudio = 'GeneratingFullAudio',
  GeneratingVideoAudioMix = 'GeneratingVideoAudioMix',
  GeneratingVideoAudioReplace = 'GeneratingVideoAudioReplace',
  GeneratingSubtitles = 'GeneratingSubtitles',
  Complete = 'Complete'
}

export type OutputGroupUpdateManyDataInput = {
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<OutputGroupState>,
  s3root?: Maybe<Scalars['String']>,
};

export type OutputGroupUpdateManyWithoutTranslatedVideoInput = {
  create?: Maybe<Array<OutputGroupCreateWithoutTranslatedVideoInput>>,
  delete?: Maybe<Array<OutputGroupWhereUniqueInput>>,
  connect?: Maybe<Array<OutputGroupWhereUniqueInput>>,
  set?: Maybe<Array<OutputGroupWhereUniqueInput>>,
  disconnect?: Maybe<Array<OutputGroupWhereUniqueInput>>,
  update?: Maybe<Array<OutputGroupUpdateWithWhereUniqueWithoutTranslatedVideoInput>>,
  upsert?: Maybe<Array<OutputGroupUpsertWithWhereUniqueWithoutTranslatedVideoInput>>,
  deleteMany?: Maybe<Array<OutputGroupScalarWhereInput>>,
  updateMany?: Maybe<Array<OutputGroupUpdateManyWithWhereNestedInput>>,
};

export type OutputGroupUpdateManyWithWhereNestedInput = {
  where: OutputGroupScalarWhereInput,
  data: OutputGroupUpdateManyDataInput,
};

export type OutputGroupUpdateOneWithoutSamplesInput = {
  create?: Maybe<OutputGroupCreateWithoutSamplesInput>,
  update?: Maybe<OutputGroupUpdateWithoutSamplesDataInput>,
  upsert?: Maybe<OutputGroupUpsertWithoutSamplesInput>,
  delete?: Maybe<Scalars['Boolean']>,
  disconnect?: Maybe<Scalars['Boolean']>,
  connect?: Maybe<OutputGroupWhereUniqueInput>,
};

export type OutputGroupUpdateWithoutSamplesDataInput = {
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<OutputGroupState>,
  s3root?: Maybe<Scalars['String']>,
  translatedVideo?: Maybe<TranslatedVideoUpdateOneRequiredWithoutOutputGroupsInput>,
  outputs?: Maybe<OutputUpdateManyWithoutOutputGroupInput>,
};

export type OutputGroupUpdateWithoutTranslatedVideoDataInput = {
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<OutputGroupState>,
  s3root?: Maybe<Scalars['String']>,
  samples?: Maybe<SampleUpdateManyWithoutOutputGroupInput>,
  outputs?: Maybe<OutputUpdateManyWithoutOutputGroupInput>,
};

export type OutputGroupUpdateWithWhereUniqueWithoutTranslatedVideoInput = {
  where: OutputGroupWhereUniqueInput,
  data: OutputGroupUpdateWithoutTranslatedVideoDataInput,
};

export type OutputGroupUpsertWithoutSamplesInput = {
  update: OutputGroupUpdateWithoutSamplesDataInput,
  create: OutputGroupCreateWithoutSamplesInput,
};

export type OutputGroupUpsertWithWhereUniqueWithoutTranslatedVideoInput = {
  where: OutputGroupWhereUniqueInput,
  update: OutputGroupUpdateWithoutTranslatedVideoDataInput,
  create: OutputGroupCreateWithoutTranslatedVideoInput,
};

export type OutputGroupWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  stateValue?: Maybe<OutputGroupState>,
  stateValue_not?: Maybe<OutputGroupState>,
  stateValue_in?: Maybe<Array<OutputGroupState>>,
  stateValue_not_in?: Maybe<Array<OutputGroupState>>,
  s3root?: Maybe<Scalars['String']>,
  s3root_not?: Maybe<Scalars['String']>,
  s3root_in?: Maybe<Array<Scalars['String']>>,
  s3root_not_in?: Maybe<Array<Scalars['String']>>,
  s3root_lt?: Maybe<Scalars['String']>,
  s3root_lte?: Maybe<Scalars['String']>,
  s3root_gt?: Maybe<Scalars['String']>,
  s3root_gte?: Maybe<Scalars['String']>,
  s3root_contains?: Maybe<Scalars['String']>,
  s3root_not_contains?: Maybe<Scalars['String']>,
  s3root_starts_with?: Maybe<Scalars['String']>,
  s3root_not_starts_with?: Maybe<Scalars['String']>,
  s3root_ends_with?: Maybe<Scalars['String']>,
  s3root_not_ends_with?: Maybe<Scalars['String']>,
  translatedVideo?: Maybe<TranslatedVideoWhereInput>,
  samples_every?: Maybe<SampleWhereInput>,
  samples_some?: Maybe<SampleWhereInput>,
  samples_none?: Maybe<SampleWhereInput>,
  outputs_every?: Maybe<OutputWhereInput>,
  outputs_some?: Maybe<OutputWhereInput>,
  outputs_none?: Maybe<OutputWhereInput>,
  AND?: Maybe<Array<OutputGroupWhereInput>>,
  OR?: Maybe<Array<OutputGroupWhereInput>>,
  NOT?: Maybe<Array<OutputGroupWhereInput>>,
};

export type OutputGroupWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export enum OutputOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC'
}

export type OutputScalarWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  type?: Maybe<OutputType>,
  type_not?: Maybe<OutputType>,
  type_in?: Maybe<Array<OutputType>>,
  type_not_in?: Maybe<Array<OutputType>>,
  AND?: Maybe<Array<OutputScalarWhereInput>>,
  OR?: Maybe<Array<OutputScalarWhereInput>>,
  NOT?: Maybe<Array<OutputScalarWhereInput>>,
};

export enum OutputType {
  VideoAudioMix = 'VideoAudioMix',
  VideoAudioReplace = 'VideoAudioReplace',
  AudioOnly = 'AudioOnly',
  Subtitles = 'Subtitles'
}

export type OutputUpdateManyDataInput = {
  type?: Maybe<OutputType>,
};

export type OutputUpdateManyWithoutOutputGroupInput = {
  create?: Maybe<Array<OutputCreateWithoutOutputGroupInput>>,
  delete?: Maybe<Array<OutputWhereUniqueInput>>,
  connect?: Maybe<Array<OutputWhereUniqueInput>>,
  set?: Maybe<Array<OutputWhereUniqueInput>>,
  disconnect?: Maybe<Array<OutputWhereUniqueInput>>,
  update?: Maybe<Array<OutputUpdateWithWhereUniqueWithoutOutputGroupInput>>,
  upsert?: Maybe<Array<OutputUpsertWithWhereUniqueWithoutOutputGroupInput>>,
  deleteMany?: Maybe<Array<OutputScalarWhereInput>>,
  updateMany?: Maybe<Array<OutputUpdateManyWithWhereNestedInput>>,
};

export type OutputUpdateManyWithWhereNestedInput = {
  where: OutputScalarWhereInput,
  data: OutputUpdateManyDataInput,
};

export type OutputUpdateWithoutOutputGroupDataInput = {
  type?: Maybe<OutputType>,
  file?: Maybe<FileUpdateOneRequiredInput>,
};

export type OutputUpdateWithWhereUniqueWithoutOutputGroupInput = {
  where: OutputWhereUniqueInput,
  data: OutputUpdateWithoutOutputGroupDataInput,
};

export type OutputUpsertWithWhereUniqueWithoutOutputGroupInput = {
  where: OutputWhereUniqueInput,
  update: OutputUpdateWithoutOutputGroupDataInput,
  create: OutputCreateWithoutOutputGroupInput,
};

export type OutputWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  outputGroup?: Maybe<OutputGroupWhereInput>,
  type?: Maybe<OutputType>,
  type_not?: Maybe<OutputType>,
  type_in?: Maybe<Array<OutputType>>,
  type_not_in?: Maybe<Array<OutputType>>,
  file?: Maybe<FileWhereInput>,
  AND?: Maybe<Array<OutputWhereInput>>,
  OR?: Maybe<Array<OutputWhereInput>>,
  NOT?: Maybe<Array<OutputWhereInput>>,
};

export type OutputWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export enum ProviderType {
  S3 = 'S3',
  Gcp = 'GCP'
}

export type Query = {
   __typename?: 'Query',
  users: Array<User>,
  sourceChannel?: Maybe<SourceChannel>,
  sourceChannels: Array<SourceChannel>,
  translatedChannel?: Maybe<TranslatedChannel>,
  translatedChannels: Array<TranslatedChannel>,
  sourceVideo?: Maybe<SourceVideo>,
  sourceVideos: Array<SourceVideo>,
  translatedVideo?: Maybe<TranslatedVideo>,
  translatedVideos: Array<TranslatedVideo>,
  segments: Array<Segment>,
  segment?: Maybe<Segment>,
  sample?: Maybe<Sample>,
  voice?: Maybe<Voice>,
  voices: Array<Voice>,
  correction?: Maybe<Correction>,
  corrections: Array<Correction>,
  correctionAttempt?: Maybe<CorrectionAttempt>,
  correctionAttempts: Array<CorrectionAttempt>,
  ytChannel: YtChannel,
  ytVideo?: Maybe<YtVideo>,
  ytChannelUploadPlaylistItems: YtChannelUploadPlaylistItems,
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>,
  orderBy?: Maybe<UserOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QuerySourceChannelArgs = {
  where: SourceChannelWhereUniqueInput
};


export type QuerySourceChannelsArgs = {
  where?: Maybe<SourceChannelWhereInput>,
  orderBy?: Maybe<SourceChannelOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTranslatedChannelArgs = {
  where: TranslatedChannelWhereUniqueInput
};


export type QueryTranslatedChannelsArgs = {
  where?: Maybe<TranslatedChannelWhereInput>,
  orderBy?: Maybe<TranslatedChannelOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QuerySourceVideoArgs = {
  where: SourceVideoWhereUniqueInput
};


export type QuerySourceVideosArgs = {
  where?: Maybe<SourceVideoWhereInput>,
  orderBy?: Maybe<SourceVideoOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTranslatedVideoArgs = {
  where: TranslatedVideoWhereUniqueInput
};


export type QueryTranslatedVideosArgs = {
  where?: Maybe<TranslatedVideoWhereInput>,
  orderBy?: Maybe<TranslatedVideoOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QuerySegmentsArgs = {
  where?: Maybe<SegmentWhereInput>,
  orderBy?: Maybe<SegmentOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QuerySegmentArgs = {
  where: SegmentWhereUniqueInput
};


export type QuerySampleArgs = {
  where: SampleWhereUniqueInput
};


export type QueryVoiceArgs = {
  where: VoiceWhereUniqueInput
};


export type QueryVoicesArgs = {
  where?: Maybe<VoiceWhereInput>,
  orderBy?: Maybe<VoiceOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryCorrectionArgs = {
  where: CorrectionWhereUniqueInput
};


export type QueryCorrectionsArgs = {
  where?: Maybe<CorrectionWhereInput>,
  orderBy?: Maybe<CorrectionOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryCorrectionAttemptArgs = {
  where: CorrectionAttemptWhereUniqueInput
};


export type QueryCorrectionAttemptsArgs = {
  where?: Maybe<CorrectionAttemptWhereInput>,
  orderBy?: Maybe<CorrectionAttemptOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryYtChannelArgs = {
  lookupType?: Maybe<YtChannelLookupType>,
  lookupValue: Scalars['String']
};


export type QueryYtVideoArgs = {
  lookupType?: Maybe<YtVideoLookupType>,
  lookupValue: Scalars['String']
};


export type QueryYtChannelUploadPlaylistItemsArgs = {
  ytChannelId?: Maybe<Scalars['ID']>
};

/** Admin power to magically change the state of an Output Group. */
export enum ResetOutputGroupEnumType {
  ResetInitial = 'Reset_Initial',
  ResetGeneratingTunedSamples = 'Reset_GeneratingTunedSamples',
  ResetGeneratingFullAudio = 'Reset_GeneratingFullAudio',
  ResetGeneratingVideoAudioMix = 'Reset_GeneratingVideoAudioMix',
  ResetGeneratingVideoAudioReplace = 'Reset_GeneratingVideoAudioReplace',
  ResetGeneratingSubtitles = 'Reset_GeneratingSubtitles',
  ResetComplete = 'Reset_Complete'
}

/** Admin power to magically change the state of a Source Video. */
export enum ResetSourceVideoEnumType {
  ResetInitial = 'Reset_Initial',
  ResetAwaitingManualUpload = 'Reset_AwaitingManualUpload',
  ResetDownloadingLowResYtVideo = 'Reset_DownloadingLowResYtVideo',
  ResetDownloadingHighResYtVideo = 'Reset_DownloadingHighResYtVideo',
  ResetExtractingAudio = 'Reset_ExtractingAudio',
  ResetAsRing = 'Reset_ASRing',
  ResetComplete = 'Reset_Complete'
}

export type Sample = {
   __typename?: 'Sample',
  video?: Maybe<TranslatedVideo>,
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  state: Scalars['Json'],
  stateValue: SampleState,
  audioFile?: Maybe<File>,
  audioDuration?: Maybe<Scalars['Float']>,
  text: Scalars['String'],
  correctionApplied: Scalars['Boolean'],
  startTime?: Maybe<Scalars['Float']>,
  papercupSpeakerId: Scalars['String'],
  stretchRate: Scalars['Float'],
  language: Scalars['String'],
  style: Scalars['String'],
  phonemizedText: Array<Scalars['Int']>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  vocoderType: VocoderType,
  outputGroup?: Maybe<OutputGroup>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
  vocoderVersion?: Maybe<Scalars['String']>,
  vocoderName?: Maybe<Scalars['String']>,
  trainingSetVersion?: Maybe<Scalars['String']>,
};

export type SampleCreateInput = {
  video?: Maybe<TranslatedVideoCreateOneInput>,
  id?: Maybe<Scalars['ID']>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SampleState>,
  audioFile?: Maybe<FileCreateOneInput>,
  audioDuration?: Maybe<Scalars['Float']>,
  text: Scalars['String'],
  correctionApplied?: Maybe<Scalars['Boolean']>,
  startTime?: Maybe<Scalars['Float']>,
  papercupSpeakerId: Scalars['String'],
  stretchRate: Scalars['Float'],
  language: Scalars['String'],
  style?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SampleCreatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  vocoderType: VocoderType,
  outputGroup?: Maybe<OutputGroupCreateOneWithoutSamplesInput>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
  vocoderVersion?: Maybe<Scalars['String']>,
  vocoderName?: Maybe<Scalars['String']>,
  trainingSetVersion?: Maybe<Scalars['String']>,
};

export type SampleCreateManyInput = {
  create?: Maybe<Array<SampleCreateInput>>,
  connect?: Maybe<Array<SampleWhereUniqueInput>>,
};

export type SampleCreateManyWithoutOutputGroupInput = {
  create?: Maybe<Array<SampleCreateWithoutOutputGroupInput>>,
  connect?: Maybe<Array<SampleWhereUniqueInput>>,
};

export type SampleCreateOneInput = {
  create?: Maybe<SampleCreateInput>,
  connect?: Maybe<SampleWhereUniqueInput>,
};

export type SampleCreatephonemizedTextInput = {
  set?: Maybe<Array<Scalars['Int']>>,
};

export type SampleCreateWithoutOutputGroupInput = {
  video?: Maybe<TranslatedVideoCreateOneInput>,
  id?: Maybe<Scalars['ID']>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SampleState>,
  audioFile?: Maybe<FileCreateOneInput>,
  audioDuration?: Maybe<Scalars['Float']>,
  text: Scalars['String'],
  correctionApplied?: Maybe<Scalars['Boolean']>,
  startTime?: Maybe<Scalars['Float']>,
  papercupSpeakerId: Scalars['String'],
  stretchRate: Scalars['Float'],
  language: Scalars['String'],
  style?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SampleCreatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  vocoderType: VocoderType,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
  vocoderVersion?: Maybe<Scalars['String']>,
  vocoderName?: Maybe<Scalars['String']>,
  trainingSetVersion?: Maybe<Scalars['String']>,
};

export enum SampleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  AudioDurationAsc = 'audioDuration_ASC',
  AudioDurationDesc = 'audioDuration_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  CorrectionAppliedAsc = 'correctionApplied_ASC',
  CorrectionAppliedDesc = 'correctionApplied_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  PapercupSpeakerIdAsc = 'papercupSpeakerId_ASC',
  PapercupSpeakerIdDesc = 'papercupSpeakerId_DESC',
  StretchRateAsc = 'stretchRate_ASC',
  StretchRateDesc = 'stretchRate_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  PhonemizationVersionAsc = 'phonemizationVersion_ASC',
  PhonemizationVersionDesc = 'phonemizationVersion_DESC',
  FeaturesS3Asc = 'featuresS3_ASC',
  FeaturesS3Desc = 'featuresS3_DESC',
  VocoderTypeAsc = 'vocoderType_ASC',
  VocoderTypeDesc = 'vocoderType_DESC',
  ModelNameAsc = 'modelName_ASC',
  ModelNameDesc = 'modelName_DESC',
  ModelVersionAsc = 'modelVersion_ASC',
  ModelVersionDesc = 'modelVersion_DESC',
  VocoderVersionAsc = 'vocoderVersion_ASC',
  VocoderVersionDesc = 'vocoderVersion_DESC',
  VocoderNameAsc = 'vocoderName_ASC',
  VocoderNameDesc = 'vocoderName_DESC',
  TrainingSetVersionAsc = 'trainingSetVersion_ASC',
  TrainingSetVersionDesc = 'trainingSetVersion_DESC'
}

export type SampleScalarWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  stateValue?: Maybe<SampleState>,
  stateValue_not?: Maybe<SampleState>,
  stateValue_in?: Maybe<Array<SampleState>>,
  stateValue_not_in?: Maybe<Array<SampleState>>,
  audioDuration?: Maybe<Scalars['Float']>,
  audioDuration_not?: Maybe<Scalars['Float']>,
  audioDuration_in?: Maybe<Array<Scalars['Float']>>,
  audioDuration_not_in?: Maybe<Array<Scalars['Float']>>,
  audioDuration_lt?: Maybe<Scalars['Float']>,
  audioDuration_lte?: Maybe<Scalars['Float']>,
  audioDuration_gt?: Maybe<Scalars['Float']>,
  audioDuration_gte?: Maybe<Scalars['Float']>,
  text?: Maybe<Scalars['String']>,
  text_not?: Maybe<Scalars['String']>,
  text_in?: Maybe<Array<Scalars['String']>>,
  text_not_in?: Maybe<Array<Scalars['String']>>,
  text_lt?: Maybe<Scalars['String']>,
  text_lte?: Maybe<Scalars['String']>,
  text_gt?: Maybe<Scalars['String']>,
  text_gte?: Maybe<Scalars['String']>,
  text_contains?: Maybe<Scalars['String']>,
  text_not_contains?: Maybe<Scalars['String']>,
  text_starts_with?: Maybe<Scalars['String']>,
  text_not_starts_with?: Maybe<Scalars['String']>,
  text_ends_with?: Maybe<Scalars['String']>,
  text_not_ends_with?: Maybe<Scalars['String']>,
  correctionApplied?: Maybe<Scalars['Boolean']>,
  correctionApplied_not?: Maybe<Scalars['Boolean']>,
  startTime?: Maybe<Scalars['Float']>,
  startTime_not?: Maybe<Scalars['Float']>,
  startTime_in?: Maybe<Array<Scalars['Float']>>,
  startTime_not_in?: Maybe<Array<Scalars['Float']>>,
  startTime_lt?: Maybe<Scalars['Float']>,
  startTime_lte?: Maybe<Scalars['Float']>,
  startTime_gt?: Maybe<Scalars['Float']>,
  startTime_gte?: Maybe<Scalars['Float']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  papercupSpeakerId_not?: Maybe<Scalars['String']>,
  papercupSpeakerId_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_not_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_lt?: Maybe<Scalars['String']>,
  papercupSpeakerId_lte?: Maybe<Scalars['String']>,
  papercupSpeakerId_gt?: Maybe<Scalars['String']>,
  papercupSpeakerId_gte?: Maybe<Scalars['String']>,
  papercupSpeakerId_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_ends_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_ends_with?: Maybe<Scalars['String']>,
  stretchRate?: Maybe<Scalars['Float']>,
  stretchRate_not?: Maybe<Scalars['Float']>,
  stretchRate_in?: Maybe<Array<Scalars['Float']>>,
  stretchRate_not_in?: Maybe<Array<Scalars['Float']>>,
  stretchRate_lt?: Maybe<Scalars['Float']>,
  stretchRate_lte?: Maybe<Scalars['Float']>,
  stretchRate_gt?: Maybe<Scalars['Float']>,
  stretchRate_gte?: Maybe<Scalars['Float']>,
  language?: Maybe<Scalars['String']>,
  language_not?: Maybe<Scalars['String']>,
  language_in?: Maybe<Array<Scalars['String']>>,
  language_not_in?: Maybe<Array<Scalars['String']>>,
  language_lt?: Maybe<Scalars['String']>,
  language_lte?: Maybe<Scalars['String']>,
  language_gt?: Maybe<Scalars['String']>,
  language_gte?: Maybe<Scalars['String']>,
  language_contains?: Maybe<Scalars['String']>,
  language_not_contains?: Maybe<Scalars['String']>,
  language_starts_with?: Maybe<Scalars['String']>,
  language_not_starts_with?: Maybe<Scalars['String']>,
  language_ends_with?: Maybe<Scalars['String']>,
  language_not_ends_with?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  style_not?: Maybe<Scalars['String']>,
  style_in?: Maybe<Array<Scalars['String']>>,
  style_not_in?: Maybe<Array<Scalars['String']>>,
  style_lt?: Maybe<Scalars['String']>,
  style_lte?: Maybe<Scalars['String']>,
  style_gt?: Maybe<Scalars['String']>,
  style_gte?: Maybe<Scalars['String']>,
  style_contains?: Maybe<Scalars['String']>,
  style_not_contains?: Maybe<Scalars['String']>,
  style_starts_with?: Maybe<Scalars['String']>,
  style_not_starts_with?: Maybe<Scalars['String']>,
  style_ends_with?: Maybe<Scalars['String']>,
  style_not_ends_with?: Maybe<Scalars['String']>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  phonemizationVersion_not?: Maybe<Scalars['String']>,
  phonemizationVersion_in?: Maybe<Array<Scalars['String']>>,
  phonemizationVersion_not_in?: Maybe<Array<Scalars['String']>>,
  phonemizationVersion_lt?: Maybe<Scalars['String']>,
  phonemizationVersion_lte?: Maybe<Scalars['String']>,
  phonemizationVersion_gt?: Maybe<Scalars['String']>,
  phonemizationVersion_gte?: Maybe<Scalars['String']>,
  phonemizationVersion_contains?: Maybe<Scalars['String']>,
  phonemizationVersion_not_contains?: Maybe<Scalars['String']>,
  phonemizationVersion_starts_with?: Maybe<Scalars['String']>,
  phonemizationVersion_not_starts_with?: Maybe<Scalars['String']>,
  phonemizationVersion_ends_with?: Maybe<Scalars['String']>,
  phonemizationVersion_not_ends_with?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  featuresS3_not?: Maybe<Scalars['String']>,
  featuresS3_in?: Maybe<Array<Scalars['String']>>,
  featuresS3_not_in?: Maybe<Array<Scalars['String']>>,
  featuresS3_lt?: Maybe<Scalars['String']>,
  featuresS3_lte?: Maybe<Scalars['String']>,
  featuresS3_gt?: Maybe<Scalars['String']>,
  featuresS3_gte?: Maybe<Scalars['String']>,
  featuresS3_contains?: Maybe<Scalars['String']>,
  featuresS3_not_contains?: Maybe<Scalars['String']>,
  featuresS3_starts_with?: Maybe<Scalars['String']>,
  featuresS3_not_starts_with?: Maybe<Scalars['String']>,
  featuresS3_ends_with?: Maybe<Scalars['String']>,
  featuresS3_not_ends_with?: Maybe<Scalars['String']>,
  vocoderType?: Maybe<VocoderType>,
  vocoderType_not?: Maybe<VocoderType>,
  vocoderType_in?: Maybe<Array<VocoderType>>,
  vocoderType_not_in?: Maybe<Array<VocoderType>>,
  modelName?: Maybe<Scalars['String']>,
  modelName_not?: Maybe<Scalars['String']>,
  modelName_in?: Maybe<Array<Scalars['String']>>,
  modelName_not_in?: Maybe<Array<Scalars['String']>>,
  modelName_lt?: Maybe<Scalars['String']>,
  modelName_lte?: Maybe<Scalars['String']>,
  modelName_gt?: Maybe<Scalars['String']>,
  modelName_gte?: Maybe<Scalars['String']>,
  modelName_contains?: Maybe<Scalars['String']>,
  modelName_not_contains?: Maybe<Scalars['String']>,
  modelName_starts_with?: Maybe<Scalars['String']>,
  modelName_not_starts_with?: Maybe<Scalars['String']>,
  modelName_ends_with?: Maybe<Scalars['String']>,
  modelName_not_ends_with?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
  modelVersion_not?: Maybe<Scalars['String']>,
  modelVersion_in?: Maybe<Array<Scalars['String']>>,
  modelVersion_not_in?: Maybe<Array<Scalars['String']>>,
  modelVersion_lt?: Maybe<Scalars['String']>,
  modelVersion_lte?: Maybe<Scalars['String']>,
  modelVersion_gt?: Maybe<Scalars['String']>,
  modelVersion_gte?: Maybe<Scalars['String']>,
  modelVersion_contains?: Maybe<Scalars['String']>,
  modelVersion_not_contains?: Maybe<Scalars['String']>,
  modelVersion_starts_with?: Maybe<Scalars['String']>,
  modelVersion_not_starts_with?: Maybe<Scalars['String']>,
  modelVersion_ends_with?: Maybe<Scalars['String']>,
  modelVersion_not_ends_with?: Maybe<Scalars['String']>,
  vocoderVersion?: Maybe<Scalars['String']>,
  vocoderVersion_not?: Maybe<Scalars['String']>,
  vocoderVersion_in?: Maybe<Array<Scalars['String']>>,
  vocoderVersion_not_in?: Maybe<Array<Scalars['String']>>,
  vocoderVersion_lt?: Maybe<Scalars['String']>,
  vocoderVersion_lte?: Maybe<Scalars['String']>,
  vocoderVersion_gt?: Maybe<Scalars['String']>,
  vocoderVersion_gte?: Maybe<Scalars['String']>,
  vocoderVersion_contains?: Maybe<Scalars['String']>,
  vocoderVersion_not_contains?: Maybe<Scalars['String']>,
  vocoderVersion_starts_with?: Maybe<Scalars['String']>,
  vocoderVersion_not_starts_with?: Maybe<Scalars['String']>,
  vocoderVersion_ends_with?: Maybe<Scalars['String']>,
  vocoderVersion_not_ends_with?: Maybe<Scalars['String']>,
  vocoderName?: Maybe<Scalars['String']>,
  vocoderName_not?: Maybe<Scalars['String']>,
  vocoderName_in?: Maybe<Array<Scalars['String']>>,
  vocoderName_not_in?: Maybe<Array<Scalars['String']>>,
  vocoderName_lt?: Maybe<Scalars['String']>,
  vocoderName_lte?: Maybe<Scalars['String']>,
  vocoderName_gt?: Maybe<Scalars['String']>,
  vocoderName_gte?: Maybe<Scalars['String']>,
  vocoderName_contains?: Maybe<Scalars['String']>,
  vocoderName_not_contains?: Maybe<Scalars['String']>,
  vocoderName_starts_with?: Maybe<Scalars['String']>,
  vocoderName_not_starts_with?: Maybe<Scalars['String']>,
  vocoderName_ends_with?: Maybe<Scalars['String']>,
  vocoderName_not_ends_with?: Maybe<Scalars['String']>,
  trainingSetVersion?: Maybe<Scalars['String']>,
  trainingSetVersion_not?: Maybe<Scalars['String']>,
  trainingSetVersion_in?: Maybe<Array<Scalars['String']>>,
  trainingSetVersion_not_in?: Maybe<Array<Scalars['String']>>,
  trainingSetVersion_lt?: Maybe<Scalars['String']>,
  trainingSetVersion_lte?: Maybe<Scalars['String']>,
  trainingSetVersion_gt?: Maybe<Scalars['String']>,
  trainingSetVersion_gte?: Maybe<Scalars['String']>,
  trainingSetVersion_contains?: Maybe<Scalars['String']>,
  trainingSetVersion_not_contains?: Maybe<Scalars['String']>,
  trainingSetVersion_starts_with?: Maybe<Scalars['String']>,
  trainingSetVersion_not_starts_with?: Maybe<Scalars['String']>,
  trainingSetVersion_ends_with?: Maybe<Scalars['String']>,
  trainingSetVersion_not_ends_with?: Maybe<Scalars['String']>,
  AND?: Maybe<Array<SampleScalarWhereInput>>,
  OR?: Maybe<Array<SampleScalarWhereInput>>,
  NOT?: Maybe<Array<SampleScalarWhereInput>>,
};

export enum SampleState {
  Initial = 'Initial',
  Generating = 'Generating',
  Complete = 'Complete'
}

export type SampleUpdateDataInput = {
  video?: Maybe<TranslatedVideoUpdateOneInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SampleState>,
  audioFile?: Maybe<FileUpdateOneInput>,
  audioDuration?: Maybe<Scalars['Float']>,
  text?: Maybe<Scalars['String']>,
  correctionApplied?: Maybe<Scalars['Boolean']>,
  startTime?: Maybe<Scalars['Float']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  stretchRate?: Maybe<Scalars['Float']>,
  language?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SampleUpdatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  vocoderType?: Maybe<VocoderType>,
  outputGroup?: Maybe<OutputGroupUpdateOneWithoutSamplesInput>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
  vocoderVersion?: Maybe<Scalars['String']>,
  vocoderName?: Maybe<Scalars['String']>,
  trainingSetVersion?: Maybe<Scalars['String']>,
};

export type SampleUpdateManyDataInput = {
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SampleState>,
  audioDuration?: Maybe<Scalars['Float']>,
  text?: Maybe<Scalars['String']>,
  correctionApplied?: Maybe<Scalars['Boolean']>,
  startTime?: Maybe<Scalars['Float']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  stretchRate?: Maybe<Scalars['Float']>,
  language?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SampleUpdatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  vocoderType?: Maybe<VocoderType>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
  vocoderVersion?: Maybe<Scalars['String']>,
  vocoderName?: Maybe<Scalars['String']>,
  trainingSetVersion?: Maybe<Scalars['String']>,
};

export type SampleUpdateManyWithoutOutputGroupInput = {
  create?: Maybe<Array<SampleCreateWithoutOutputGroupInput>>,
  delete?: Maybe<Array<SampleWhereUniqueInput>>,
  connect?: Maybe<Array<SampleWhereUniqueInput>>,
  set?: Maybe<Array<SampleWhereUniqueInput>>,
  disconnect?: Maybe<Array<SampleWhereUniqueInput>>,
  update?: Maybe<Array<SampleUpdateWithWhereUniqueWithoutOutputGroupInput>>,
  upsert?: Maybe<Array<SampleUpsertWithWhereUniqueWithoutOutputGroupInput>>,
  deleteMany?: Maybe<Array<SampleScalarWhereInput>>,
  updateMany?: Maybe<Array<SampleUpdateManyWithWhereNestedInput>>,
};

export type SampleUpdateManyWithWhereNestedInput = {
  where: SampleScalarWhereInput,
  data: SampleUpdateManyDataInput,
};

export type SampleUpdateOneInput = {
  create?: Maybe<SampleCreateInput>,
  update?: Maybe<SampleUpdateDataInput>,
  upsert?: Maybe<SampleUpsertNestedInput>,
  delete?: Maybe<Scalars['Boolean']>,
  disconnect?: Maybe<Scalars['Boolean']>,
  connect?: Maybe<SampleWhereUniqueInput>,
};

export type SampleUpdatephonemizedTextInput = {
  set?: Maybe<Array<Scalars['Int']>>,
};

export type SampleUpdateWithoutOutputGroupDataInput = {
  video?: Maybe<TranslatedVideoUpdateOneInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SampleState>,
  audioFile?: Maybe<FileUpdateOneInput>,
  audioDuration?: Maybe<Scalars['Float']>,
  text?: Maybe<Scalars['String']>,
  correctionApplied?: Maybe<Scalars['Boolean']>,
  startTime?: Maybe<Scalars['Float']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  stretchRate?: Maybe<Scalars['Float']>,
  language?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SampleUpdatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  vocoderType?: Maybe<VocoderType>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
  vocoderVersion?: Maybe<Scalars['String']>,
  vocoderName?: Maybe<Scalars['String']>,
  trainingSetVersion?: Maybe<Scalars['String']>,
};

export type SampleUpdateWithWhereUniqueWithoutOutputGroupInput = {
  where: SampleWhereUniqueInput,
  data: SampleUpdateWithoutOutputGroupDataInput,
};

export type SampleUpsertNestedInput = {
  update: SampleUpdateDataInput,
  create: SampleCreateInput,
};

export type SampleUpsertWithWhereUniqueWithoutOutputGroupInput = {
  where: SampleWhereUniqueInput,
  update: SampleUpdateWithoutOutputGroupDataInput,
  create: SampleCreateWithoutOutputGroupInput,
};

export type SampleWhereInput = {
  video?: Maybe<TranslatedVideoWhereInput>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  stateValue?: Maybe<SampleState>,
  stateValue_not?: Maybe<SampleState>,
  stateValue_in?: Maybe<Array<SampleState>>,
  stateValue_not_in?: Maybe<Array<SampleState>>,
  audioFile?: Maybe<FileWhereInput>,
  audioDuration?: Maybe<Scalars['Float']>,
  audioDuration_not?: Maybe<Scalars['Float']>,
  audioDuration_in?: Maybe<Array<Scalars['Float']>>,
  audioDuration_not_in?: Maybe<Array<Scalars['Float']>>,
  audioDuration_lt?: Maybe<Scalars['Float']>,
  audioDuration_lte?: Maybe<Scalars['Float']>,
  audioDuration_gt?: Maybe<Scalars['Float']>,
  audioDuration_gte?: Maybe<Scalars['Float']>,
  text?: Maybe<Scalars['String']>,
  text_not?: Maybe<Scalars['String']>,
  text_in?: Maybe<Array<Scalars['String']>>,
  text_not_in?: Maybe<Array<Scalars['String']>>,
  text_lt?: Maybe<Scalars['String']>,
  text_lte?: Maybe<Scalars['String']>,
  text_gt?: Maybe<Scalars['String']>,
  text_gte?: Maybe<Scalars['String']>,
  text_contains?: Maybe<Scalars['String']>,
  text_not_contains?: Maybe<Scalars['String']>,
  text_starts_with?: Maybe<Scalars['String']>,
  text_not_starts_with?: Maybe<Scalars['String']>,
  text_ends_with?: Maybe<Scalars['String']>,
  text_not_ends_with?: Maybe<Scalars['String']>,
  correctionApplied?: Maybe<Scalars['Boolean']>,
  correctionApplied_not?: Maybe<Scalars['Boolean']>,
  startTime?: Maybe<Scalars['Float']>,
  startTime_not?: Maybe<Scalars['Float']>,
  startTime_in?: Maybe<Array<Scalars['Float']>>,
  startTime_not_in?: Maybe<Array<Scalars['Float']>>,
  startTime_lt?: Maybe<Scalars['Float']>,
  startTime_lte?: Maybe<Scalars['Float']>,
  startTime_gt?: Maybe<Scalars['Float']>,
  startTime_gte?: Maybe<Scalars['Float']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  papercupSpeakerId_not?: Maybe<Scalars['String']>,
  papercupSpeakerId_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_not_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_lt?: Maybe<Scalars['String']>,
  papercupSpeakerId_lte?: Maybe<Scalars['String']>,
  papercupSpeakerId_gt?: Maybe<Scalars['String']>,
  papercupSpeakerId_gte?: Maybe<Scalars['String']>,
  papercupSpeakerId_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_ends_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_ends_with?: Maybe<Scalars['String']>,
  stretchRate?: Maybe<Scalars['Float']>,
  stretchRate_not?: Maybe<Scalars['Float']>,
  stretchRate_in?: Maybe<Array<Scalars['Float']>>,
  stretchRate_not_in?: Maybe<Array<Scalars['Float']>>,
  stretchRate_lt?: Maybe<Scalars['Float']>,
  stretchRate_lte?: Maybe<Scalars['Float']>,
  stretchRate_gt?: Maybe<Scalars['Float']>,
  stretchRate_gte?: Maybe<Scalars['Float']>,
  language?: Maybe<Scalars['String']>,
  language_not?: Maybe<Scalars['String']>,
  language_in?: Maybe<Array<Scalars['String']>>,
  language_not_in?: Maybe<Array<Scalars['String']>>,
  language_lt?: Maybe<Scalars['String']>,
  language_lte?: Maybe<Scalars['String']>,
  language_gt?: Maybe<Scalars['String']>,
  language_gte?: Maybe<Scalars['String']>,
  language_contains?: Maybe<Scalars['String']>,
  language_not_contains?: Maybe<Scalars['String']>,
  language_starts_with?: Maybe<Scalars['String']>,
  language_not_starts_with?: Maybe<Scalars['String']>,
  language_ends_with?: Maybe<Scalars['String']>,
  language_not_ends_with?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  style_not?: Maybe<Scalars['String']>,
  style_in?: Maybe<Array<Scalars['String']>>,
  style_not_in?: Maybe<Array<Scalars['String']>>,
  style_lt?: Maybe<Scalars['String']>,
  style_lte?: Maybe<Scalars['String']>,
  style_gt?: Maybe<Scalars['String']>,
  style_gte?: Maybe<Scalars['String']>,
  style_contains?: Maybe<Scalars['String']>,
  style_not_contains?: Maybe<Scalars['String']>,
  style_starts_with?: Maybe<Scalars['String']>,
  style_not_starts_with?: Maybe<Scalars['String']>,
  style_ends_with?: Maybe<Scalars['String']>,
  style_not_ends_with?: Maybe<Scalars['String']>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  phonemizationVersion_not?: Maybe<Scalars['String']>,
  phonemizationVersion_in?: Maybe<Array<Scalars['String']>>,
  phonemizationVersion_not_in?: Maybe<Array<Scalars['String']>>,
  phonemizationVersion_lt?: Maybe<Scalars['String']>,
  phonemizationVersion_lte?: Maybe<Scalars['String']>,
  phonemizationVersion_gt?: Maybe<Scalars['String']>,
  phonemizationVersion_gte?: Maybe<Scalars['String']>,
  phonemizationVersion_contains?: Maybe<Scalars['String']>,
  phonemizationVersion_not_contains?: Maybe<Scalars['String']>,
  phonemizationVersion_starts_with?: Maybe<Scalars['String']>,
  phonemizationVersion_not_starts_with?: Maybe<Scalars['String']>,
  phonemizationVersion_ends_with?: Maybe<Scalars['String']>,
  phonemizationVersion_not_ends_with?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  featuresS3_not?: Maybe<Scalars['String']>,
  featuresS3_in?: Maybe<Array<Scalars['String']>>,
  featuresS3_not_in?: Maybe<Array<Scalars['String']>>,
  featuresS3_lt?: Maybe<Scalars['String']>,
  featuresS3_lte?: Maybe<Scalars['String']>,
  featuresS3_gt?: Maybe<Scalars['String']>,
  featuresS3_gte?: Maybe<Scalars['String']>,
  featuresS3_contains?: Maybe<Scalars['String']>,
  featuresS3_not_contains?: Maybe<Scalars['String']>,
  featuresS3_starts_with?: Maybe<Scalars['String']>,
  featuresS3_not_starts_with?: Maybe<Scalars['String']>,
  featuresS3_ends_with?: Maybe<Scalars['String']>,
  featuresS3_not_ends_with?: Maybe<Scalars['String']>,
  vocoderType?: Maybe<VocoderType>,
  vocoderType_not?: Maybe<VocoderType>,
  vocoderType_in?: Maybe<Array<VocoderType>>,
  vocoderType_not_in?: Maybe<Array<VocoderType>>,
  outputGroup?: Maybe<OutputGroupWhereInput>,
  modelName?: Maybe<Scalars['String']>,
  modelName_not?: Maybe<Scalars['String']>,
  modelName_in?: Maybe<Array<Scalars['String']>>,
  modelName_not_in?: Maybe<Array<Scalars['String']>>,
  modelName_lt?: Maybe<Scalars['String']>,
  modelName_lte?: Maybe<Scalars['String']>,
  modelName_gt?: Maybe<Scalars['String']>,
  modelName_gte?: Maybe<Scalars['String']>,
  modelName_contains?: Maybe<Scalars['String']>,
  modelName_not_contains?: Maybe<Scalars['String']>,
  modelName_starts_with?: Maybe<Scalars['String']>,
  modelName_not_starts_with?: Maybe<Scalars['String']>,
  modelName_ends_with?: Maybe<Scalars['String']>,
  modelName_not_ends_with?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
  modelVersion_not?: Maybe<Scalars['String']>,
  modelVersion_in?: Maybe<Array<Scalars['String']>>,
  modelVersion_not_in?: Maybe<Array<Scalars['String']>>,
  modelVersion_lt?: Maybe<Scalars['String']>,
  modelVersion_lte?: Maybe<Scalars['String']>,
  modelVersion_gt?: Maybe<Scalars['String']>,
  modelVersion_gte?: Maybe<Scalars['String']>,
  modelVersion_contains?: Maybe<Scalars['String']>,
  modelVersion_not_contains?: Maybe<Scalars['String']>,
  modelVersion_starts_with?: Maybe<Scalars['String']>,
  modelVersion_not_starts_with?: Maybe<Scalars['String']>,
  modelVersion_ends_with?: Maybe<Scalars['String']>,
  modelVersion_not_ends_with?: Maybe<Scalars['String']>,
  vocoderVersion?: Maybe<Scalars['String']>,
  vocoderVersion_not?: Maybe<Scalars['String']>,
  vocoderVersion_in?: Maybe<Array<Scalars['String']>>,
  vocoderVersion_not_in?: Maybe<Array<Scalars['String']>>,
  vocoderVersion_lt?: Maybe<Scalars['String']>,
  vocoderVersion_lte?: Maybe<Scalars['String']>,
  vocoderVersion_gt?: Maybe<Scalars['String']>,
  vocoderVersion_gte?: Maybe<Scalars['String']>,
  vocoderVersion_contains?: Maybe<Scalars['String']>,
  vocoderVersion_not_contains?: Maybe<Scalars['String']>,
  vocoderVersion_starts_with?: Maybe<Scalars['String']>,
  vocoderVersion_not_starts_with?: Maybe<Scalars['String']>,
  vocoderVersion_ends_with?: Maybe<Scalars['String']>,
  vocoderVersion_not_ends_with?: Maybe<Scalars['String']>,
  vocoderName?: Maybe<Scalars['String']>,
  vocoderName_not?: Maybe<Scalars['String']>,
  vocoderName_in?: Maybe<Array<Scalars['String']>>,
  vocoderName_not_in?: Maybe<Array<Scalars['String']>>,
  vocoderName_lt?: Maybe<Scalars['String']>,
  vocoderName_lte?: Maybe<Scalars['String']>,
  vocoderName_gt?: Maybe<Scalars['String']>,
  vocoderName_gte?: Maybe<Scalars['String']>,
  vocoderName_contains?: Maybe<Scalars['String']>,
  vocoderName_not_contains?: Maybe<Scalars['String']>,
  vocoderName_starts_with?: Maybe<Scalars['String']>,
  vocoderName_not_starts_with?: Maybe<Scalars['String']>,
  vocoderName_ends_with?: Maybe<Scalars['String']>,
  vocoderName_not_ends_with?: Maybe<Scalars['String']>,
  trainingSetVersion?: Maybe<Scalars['String']>,
  trainingSetVersion_not?: Maybe<Scalars['String']>,
  trainingSetVersion_in?: Maybe<Array<Scalars['String']>>,
  trainingSetVersion_not_in?: Maybe<Array<Scalars['String']>>,
  trainingSetVersion_lt?: Maybe<Scalars['String']>,
  trainingSetVersion_lte?: Maybe<Scalars['String']>,
  trainingSetVersion_gt?: Maybe<Scalars['String']>,
  trainingSetVersion_gte?: Maybe<Scalars['String']>,
  trainingSetVersion_contains?: Maybe<Scalars['String']>,
  trainingSetVersion_not_contains?: Maybe<Scalars['String']>,
  trainingSetVersion_starts_with?: Maybe<Scalars['String']>,
  trainingSetVersion_not_starts_with?: Maybe<Scalars['String']>,
  trainingSetVersion_ends_with?: Maybe<Scalars['String']>,
  trainingSetVersion_not_ends_with?: Maybe<Scalars['String']>,
  AND?: Maybe<Array<SampleWhereInput>>,
  OR?: Maybe<Array<SampleWhereInput>>,
  NOT?: Maybe<Array<SampleWhereInput>>,
};

export type SampleWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type Segment = {
   __typename?: 'Segment',
  video: TranslatedVideo,
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  state: Scalars['Json'],
  stateValue: SegmentState,
  done?: Maybe<Scalars['Boolean']>,
  sourceWords: Array<Scalars['Json']>,
  text?: Maybe<Scalars['String']>,
  startTime: Scalars['Float'],
  endTime: Scalars['Float'],
  autoTranslation?: Maybe<Scalars['String']>,
  sourceLanguage?: Maybe<Scalars['String']>,
  targetLanguage?: Maybe<Scalars['String']>,
  papercupSpeakerId: Scalars['String'],
  style: Scalars['String'],
  translationStartTime: Scalars['Float'],
  stretchRate: Scalars['Float'],
  translatedTextParsed?: Maybe<Scalars['String']>,
  translatedText?: Maybe<Scalars['String']>,
  phonemizedText: Array<Scalars['Int']>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  previewSample?: Maybe<Sample>,
  tunedSample?: Maybe<Sample>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
};

export type SegmentCreateInput = {
  video: TranslatedVideoCreateOneWithoutSegmentsInput,
  id?: Maybe<Scalars['ID']>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SegmentState>,
  done?: Maybe<Scalars['Boolean']>,
  sourceWords?: Maybe<SegmentCreatesourceWordsInput>,
  text?: Maybe<Scalars['String']>,
  startTime: Scalars['Float'],
  endTime: Scalars['Float'],
  autoTranslation?: Maybe<Scalars['String']>,
  sourceLanguage?: Maybe<Scalars['String']>,
  targetLanguage?: Maybe<Scalars['String']>,
  papercupSpeakerId: Scalars['String'],
  style?: Maybe<Scalars['String']>,
  translationStartTime: Scalars['Float'],
  stretchRate?: Maybe<Scalars['Float']>,
  translatedTextParsed?: Maybe<Scalars['String']>,
  translatedText?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SegmentCreatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  previewSample?: Maybe<SampleCreateOneInput>,
  tunedSample?: Maybe<SampleCreateOneInput>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
};

export type SegmentCreateManyWithoutVideoInput = {
  create?: Maybe<Array<SegmentCreateWithoutVideoInput>>,
  connect?: Maybe<Array<SegmentWhereUniqueInput>>,
};

export type SegmentCreatephonemizedTextInput = {
  set?: Maybe<Array<Scalars['Int']>>,
};

export type SegmentCreatesourceWordsInput = {
  set?: Maybe<Array<Scalars['Json']>>,
};

export type SegmentCreateWithoutVideoInput = {
  id?: Maybe<Scalars['ID']>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SegmentState>,
  done?: Maybe<Scalars['Boolean']>,
  sourceWords?: Maybe<SegmentCreatesourceWordsInput>,
  text?: Maybe<Scalars['String']>,
  startTime: Scalars['Float'],
  endTime: Scalars['Float'],
  autoTranslation?: Maybe<Scalars['String']>,
  sourceLanguage?: Maybe<Scalars['String']>,
  targetLanguage?: Maybe<Scalars['String']>,
  papercupSpeakerId: Scalars['String'],
  style?: Maybe<Scalars['String']>,
  translationStartTime: Scalars['Float'],
  stretchRate?: Maybe<Scalars['Float']>,
  translatedTextParsed?: Maybe<Scalars['String']>,
  translatedText?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SegmentCreatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  previewSample?: Maybe<SampleCreateOneInput>,
  tunedSample?: Maybe<SampleCreateOneInput>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
};

export enum SegmentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  DoneAsc = 'done_ASC',
  DoneDesc = 'done_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  EndTimeAsc = 'endTime_ASC',
  EndTimeDesc = 'endTime_DESC',
  AutoTranslationAsc = 'autoTranslation_ASC',
  AutoTranslationDesc = 'autoTranslation_DESC',
  SourceLanguageAsc = 'sourceLanguage_ASC',
  SourceLanguageDesc = 'sourceLanguage_DESC',
  TargetLanguageAsc = 'targetLanguage_ASC',
  TargetLanguageDesc = 'targetLanguage_DESC',
  PapercupSpeakerIdAsc = 'papercupSpeakerId_ASC',
  PapercupSpeakerIdDesc = 'papercupSpeakerId_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  TranslationStartTimeAsc = 'translationStartTime_ASC',
  TranslationStartTimeDesc = 'translationStartTime_DESC',
  StretchRateAsc = 'stretchRate_ASC',
  StretchRateDesc = 'stretchRate_DESC',
  TranslatedTextParsedAsc = 'translatedTextParsed_ASC',
  TranslatedTextParsedDesc = 'translatedTextParsed_DESC',
  TranslatedTextAsc = 'translatedText_ASC',
  TranslatedTextDesc = 'translatedText_DESC',
  PhonemizationVersionAsc = 'phonemizationVersion_ASC',
  PhonemizationVersionDesc = 'phonemizationVersion_DESC',
  FeaturesS3Asc = 'featuresS3_ASC',
  FeaturesS3Desc = 'featuresS3_DESC',
  ModelNameAsc = 'modelName_ASC',
  ModelNameDesc = 'modelName_DESC',
  ModelVersionAsc = 'modelVersion_ASC',
  ModelVersionDesc = 'modelVersion_DESC'
}

export type SegmentScalarWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  stateValue?: Maybe<SegmentState>,
  stateValue_not?: Maybe<SegmentState>,
  stateValue_in?: Maybe<Array<SegmentState>>,
  stateValue_not_in?: Maybe<Array<SegmentState>>,
  done?: Maybe<Scalars['Boolean']>,
  done_not?: Maybe<Scalars['Boolean']>,
  text?: Maybe<Scalars['String']>,
  text_not?: Maybe<Scalars['String']>,
  text_in?: Maybe<Array<Scalars['String']>>,
  text_not_in?: Maybe<Array<Scalars['String']>>,
  text_lt?: Maybe<Scalars['String']>,
  text_lte?: Maybe<Scalars['String']>,
  text_gt?: Maybe<Scalars['String']>,
  text_gte?: Maybe<Scalars['String']>,
  text_contains?: Maybe<Scalars['String']>,
  text_not_contains?: Maybe<Scalars['String']>,
  text_starts_with?: Maybe<Scalars['String']>,
  text_not_starts_with?: Maybe<Scalars['String']>,
  text_ends_with?: Maybe<Scalars['String']>,
  text_not_ends_with?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['Float']>,
  startTime_not?: Maybe<Scalars['Float']>,
  startTime_in?: Maybe<Array<Scalars['Float']>>,
  startTime_not_in?: Maybe<Array<Scalars['Float']>>,
  startTime_lt?: Maybe<Scalars['Float']>,
  startTime_lte?: Maybe<Scalars['Float']>,
  startTime_gt?: Maybe<Scalars['Float']>,
  startTime_gte?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  endTime_not?: Maybe<Scalars['Float']>,
  endTime_in?: Maybe<Array<Scalars['Float']>>,
  endTime_not_in?: Maybe<Array<Scalars['Float']>>,
  endTime_lt?: Maybe<Scalars['Float']>,
  endTime_lte?: Maybe<Scalars['Float']>,
  endTime_gt?: Maybe<Scalars['Float']>,
  endTime_gte?: Maybe<Scalars['Float']>,
  autoTranslation?: Maybe<Scalars['String']>,
  autoTranslation_not?: Maybe<Scalars['String']>,
  autoTranslation_in?: Maybe<Array<Scalars['String']>>,
  autoTranslation_not_in?: Maybe<Array<Scalars['String']>>,
  autoTranslation_lt?: Maybe<Scalars['String']>,
  autoTranslation_lte?: Maybe<Scalars['String']>,
  autoTranslation_gt?: Maybe<Scalars['String']>,
  autoTranslation_gte?: Maybe<Scalars['String']>,
  autoTranslation_contains?: Maybe<Scalars['String']>,
  autoTranslation_not_contains?: Maybe<Scalars['String']>,
  autoTranslation_starts_with?: Maybe<Scalars['String']>,
  autoTranslation_not_starts_with?: Maybe<Scalars['String']>,
  autoTranslation_ends_with?: Maybe<Scalars['String']>,
  autoTranslation_not_ends_with?: Maybe<Scalars['String']>,
  sourceLanguage?: Maybe<Scalars['String']>,
  sourceLanguage_not?: Maybe<Scalars['String']>,
  sourceLanguage_in?: Maybe<Array<Scalars['String']>>,
  sourceLanguage_not_in?: Maybe<Array<Scalars['String']>>,
  sourceLanguage_lt?: Maybe<Scalars['String']>,
  sourceLanguage_lte?: Maybe<Scalars['String']>,
  sourceLanguage_gt?: Maybe<Scalars['String']>,
  sourceLanguage_gte?: Maybe<Scalars['String']>,
  sourceLanguage_contains?: Maybe<Scalars['String']>,
  sourceLanguage_not_contains?: Maybe<Scalars['String']>,
  sourceLanguage_starts_with?: Maybe<Scalars['String']>,
  sourceLanguage_not_starts_with?: Maybe<Scalars['String']>,
  sourceLanguage_ends_with?: Maybe<Scalars['String']>,
  sourceLanguage_not_ends_with?: Maybe<Scalars['String']>,
  targetLanguage?: Maybe<Scalars['String']>,
  targetLanguage_not?: Maybe<Scalars['String']>,
  targetLanguage_in?: Maybe<Array<Scalars['String']>>,
  targetLanguage_not_in?: Maybe<Array<Scalars['String']>>,
  targetLanguage_lt?: Maybe<Scalars['String']>,
  targetLanguage_lte?: Maybe<Scalars['String']>,
  targetLanguage_gt?: Maybe<Scalars['String']>,
  targetLanguage_gte?: Maybe<Scalars['String']>,
  targetLanguage_contains?: Maybe<Scalars['String']>,
  targetLanguage_not_contains?: Maybe<Scalars['String']>,
  targetLanguage_starts_with?: Maybe<Scalars['String']>,
  targetLanguage_not_starts_with?: Maybe<Scalars['String']>,
  targetLanguage_ends_with?: Maybe<Scalars['String']>,
  targetLanguage_not_ends_with?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  papercupSpeakerId_not?: Maybe<Scalars['String']>,
  papercupSpeakerId_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_not_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_lt?: Maybe<Scalars['String']>,
  papercupSpeakerId_lte?: Maybe<Scalars['String']>,
  papercupSpeakerId_gt?: Maybe<Scalars['String']>,
  papercupSpeakerId_gte?: Maybe<Scalars['String']>,
  papercupSpeakerId_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_ends_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_ends_with?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  style_not?: Maybe<Scalars['String']>,
  style_in?: Maybe<Array<Scalars['String']>>,
  style_not_in?: Maybe<Array<Scalars['String']>>,
  style_lt?: Maybe<Scalars['String']>,
  style_lte?: Maybe<Scalars['String']>,
  style_gt?: Maybe<Scalars['String']>,
  style_gte?: Maybe<Scalars['String']>,
  style_contains?: Maybe<Scalars['String']>,
  style_not_contains?: Maybe<Scalars['String']>,
  style_starts_with?: Maybe<Scalars['String']>,
  style_not_starts_with?: Maybe<Scalars['String']>,
  style_ends_with?: Maybe<Scalars['String']>,
  style_not_ends_with?: Maybe<Scalars['String']>,
  translationStartTime?: Maybe<Scalars['Float']>,
  translationStartTime_not?: Maybe<Scalars['Float']>,
  translationStartTime_in?: Maybe<Array<Scalars['Float']>>,
  translationStartTime_not_in?: Maybe<Array<Scalars['Float']>>,
  translationStartTime_lt?: Maybe<Scalars['Float']>,
  translationStartTime_lte?: Maybe<Scalars['Float']>,
  translationStartTime_gt?: Maybe<Scalars['Float']>,
  translationStartTime_gte?: Maybe<Scalars['Float']>,
  stretchRate?: Maybe<Scalars['Float']>,
  stretchRate_not?: Maybe<Scalars['Float']>,
  stretchRate_in?: Maybe<Array<Scalars['Float']>>,
  stretchRate_not_in?: Maybe<Array<Scalars['Float']>>,
  stretchRate_lt?: Maybe<Scalars['Float']>,
  stretchRate_lte?: Maybe<Scalars['Float']>,
  stretchRate_gt?: Maybe<Scalars['Float']>,
  stretchRate_gte?: Maybe<Scalars['Float']>,
  translatedTextParsed?: Maybe<Scalars['String']>,
  translatedTextParsed_not?: Maybe<Scalars['String']>,
  translatedTextParsed_in?: Maybe<Array<Scalars['String']>>,
  translatedTextParsed_not_in?: Maybe<Array<Scalars['String']>>,
  translatedTextParsed_lt?: Maybe<Scalars['String']>,
  translatedTextParsed_lte?: Maybe<Scalars['String']>,
  translatedTextParsed_gt?: Maybe<Scalars['String']>,
  translatedTextParsed_gte?: Maybe<Scalars['String']>,
  translatedTextParsed_contains?: Maybe<Scalars['String']>,
  translatedTextParsed_not_contains?: Maybe<Scalars['String']>,
  translatedTextParsed_starts_with?: Maybe<Scalars['String']>,
  translatedTextParsed_not_starts_with?: Maybe<Scalars['String']>,
  translatedTextParsed_ends_with?: Maybe<Scalars['String']>,
  translatedTextParsed_not_ends_with?: Maybe<Scalars['String']>,
  translatedText?: Maybe<Scalars['String']>,
  translatedText_not?: Maybe<Scalars['String']>,
  translatedText_in?: Maybe<Array<Scalars['String']>>,
  translatedText_not_in?: Maybe<Array<Scalars['String']>>,
  translatedText_lt?: Maybe<Scalars['String']>,
  translatedText_lte?: Maybe<Scalars['String']>,
  translatedText_gt?: Maybe<Scalars['String']>,
  translatedText_gte?: Maybe<Scalars['String']>,
  translatedText_contains?: Maybe<Scalars['String']>,
  translatedText_not_contains?: Maybe<Scalars['String']>,
  translatedText_starts_with?: Maybe<Scalars['String']>,
  translatedText_not_starts_with?: Maybe<Scalars['String']>,
  translatedText_ends_with?: Maybe<Scalars['String']>,
  translatedText_not_ends_with?: Maybe<Scalars['String']>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  phonemizationVersion_not?: Maybe<Scalars['String']>,
  phonemizationVersion_in?: Maybe<Array<Scalars['String']>>,
  phonemizationVersion_not_in?: Maybe<Array<Scalars['String']>>,
  phonemizationVersion_lt?: Maybe<Scalars['String']>,
  phonemizationVersion_lte?: Maybe<Scalars['String']>,
  phonemizationVersion_gt?: Maybe<Scalars['String']>,
  phonemizationVersion_gte?: Maybe<Scalars['String']>,
  phonemizationVersion_contains?: Maybe<Scalars['String']>,
  phonemizationVersion_not_contains?: Maybe<Scalars['String']>,
  phonemizationVersion_starts_with?: Maybe<Scalars['String']>,
  phonemizationVersion_not_starts_with?: Maybe<Scalars['String']>,
  phonemizationVersion_ends_with?: Maybe<Scalars['String']>,
  phonemizationVersion_not_ends_with?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  featuresS3_not?: Maybe<Scalars['String']>,
  featuresS3_in?: Maybe<Array<Scalars['String']>>,
  featuresS3_not_in?: Maybe<Array<Scalars['String']>>,
  featuresS3_lt?: Maybe<Scalars['String']>,
  featuresS3_lte?: Maybe<Scalars['String']>,
  featuresS3_gt?: Maybe<Scalars['String']>,
  featuresS3_gte?: Maybe<Scalars['String']>,
  featuresS3_contains?: Maybe<Scalars['String']>,
  featuresS3_not_contains?: Maybe<Scalars['String']>,
  featuresS3_starts_with?: Maybe<Scalars['String']>,
  featuresS3_not_starts_with?: Maybe<Scalars['String']>,
  featuresS3_ends_with?: Maybe<Scalars['String']>,
  featuresS3_not_ends_with?: Maybe<Scalars['String']>,
  modelName?: Maybe<Scalars['String']>,
  modelName_not?: Maybe<Scalars['String']>,
  modelName_in?: Maybe<Array<Scalars['String']>>,
  modelName_not_in?: Maybe<Array<Scalars['String']>>,
  modelName_lt?: Maybe<Scalars['String']>,
  modelName_lte?: Maybe<Scalars['String']>,
  modelName_gt?: Maybe<Scalars['String']>,
  modelName_gte?: Maybe<Scalars['String']>,
  modelName_contains?: Maybe<Scalars['String']>,
  modelName_not_contains?: Maybe<Scalars['String']>,
  modelName_starts_with?: Maybe<Scalars['String']>,
  modelName_not_starts_with?: Maybe<Scalars['String']>,
  modelName_ends_with?: Maybe<Scalars['String']>,
  modelName_not_ends_with?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
  modelVersion_not?: Maybe<Scalars['String']>,
  modelVersion_in?: Maybe<Array<Scalars['String']>>,
  modelVersion_not_in?: Maybe<Array<Scalars['String']>>,
  modelVersion_lt?: Maybe<Scalars['String']>,
  modelVersion_lte?: Maybe<Scalars['String']>,
  modelVersion_gt?: Maybe<Scalars['String']>,
  modelVersion_gte?: Maybe<Scalars['String']>,
  modelVersion_contains?: Maybe<Scalars['String']>,
  modelVersion_not_contains?: Maybe<Scalars['String']>,
  modelVersion_starts_with?: Maybe<Scalars['String']>,
  modelVersion_not_starts_with?: Maybe<Scalars['String']>,
  modelVersion_ends_with?: Maybe<Scalars['String']>,
  modelVersion_not_ends_with?: Maybe<Scalars['String']>,
  AND?: Maybe<Array<SegmentScalarWhereInput>>,
  OR?: Maybe<Array<SegmentScalarWhereInput>>,
  NOT?: Maybe<Array<SegmentScalarWhereInput>>,
};

export enum SegmentState {
  Initial = 'Initial',
  Translating = 'Translating',
  GeneratingPhones = 'GeneratingPhones',
  GeneratingFeatures = 'GeneratingFeatures',
  Complete = 'Complete'
}

export type SegmentUpdateDataInput = {
  video?: Maybe<TranslatedVideoUpdateOneRequiredWithoutSegmentsInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SegmentState>,
  done?: Maybe<Scalars['Boolean']>,
  sourceWords?: Maybe<SegmentUpdatesourceWordsInput>,
  text?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  autoTranslation?: Maybe<Scalars['String']>,
  sourceLanguage?: Maybe<Scalars['String']>,
  targetLanguage?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  translationStartTime?: Maybe<Scalars['Float']>,
  stretchRate?: Maybe<Scalars['Float']>,
  translatedTextParsed?: Maybe<Scalars['String']>,
  translatedText?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SegmentUpdatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  previewSample?: Maybe<SampleUpdateOneInput>,
  tunedSample?: Maybe<SampleUpdateOneInput>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
};

export type SegmentUpdateInput = {
  video?: Maybe<TranslatedVideoUpdateOneRequiredWithoutSegmentsInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SegmentState>,
  done?: Maybe<Scalars['Boolean']>,
  sourceWords?: Maybe<SegmentUpdatesourceWordsInput>,
  text?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  autoTranslation?: Maybe<Scalars['String']>,
  sourceLanguage?: Maybe<Scalars['String']>,
  targetLanguage?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  translationStartTime?: Maybe<Scalars['Float']>,
  stretchRate?: Maybe<Scalars['Float']>,
  translatedTextParsed?: Maybe<Scalars['String']>,
  translatedText?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SegmentUpdatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  previewSample?: Maybe<SampleUpdateOneInput>,
  tunedSample?: Maybe<SampleUpdateOneInput>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
};

export type SegmentUpdateManyDataInput = {
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SegmentState>,
  done?: Maybe<Scalars['Boolean']>,
  sourceWords?: Maybe<SegmentUpdatesourceWordsInput>,
  text?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  autoTranslation?: Maybe<Scalars['String']>,
  sourceLanguage?: Maybe<Scalars['String']>,
  targetLanguage?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  translationStartTime?: Maybe<Scalars['Float']>,
  stretchRate?: Maybe<Scalars['Float']>,
  translatedTextParsed?: Maybe<Scalars['String']>,
  translatedText?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SegmentUpdatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
};

export type SegmentUpdateManyMutationInput = {
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SegmentState>,
  done?: Maybe<Scalars['Boolean']>,
  sourceWords?: Maybe<SegmentUpdatesourceWordsInput>,
  text?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  autoTranslation?: Maybe<Scalars['String']>,
  sourceLanguage?: Maybe<Scalars['String']>,
  targetLanguage?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  translationStartTime?: Maybe<Scalars['Float']>,
  stretchRate?: Maybe<Scalars['Float']>,
  translatedTextParsed?: Maybe<Scalars['String']>,
  translatedText?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SegmentUpdatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
};

export type SegmentUpdateManyWithoutVideoInput = {
  create?: Maybe<Array<SegmentCreateWithoutVideoInput>>,
  delete?: Maybe<Array<SegmentWhereUniqueInput>>,
  connect?: Maybe<Array<SegmentWhereUniqueInput>>,
  set?: Maybe<Array<SegmentWhereUniqueInput>>,
  disconnect?: Maybe<Array<SegmentWhereUniqueInput>>,
  update?: Maybe<Array<SegmentUpdateWithWhereUniqueWithoutVideoInput>>,
  upsert?: Maybe<Array<SegmentUpsertWithWhereUniqueWithoutVideoInput>>,
  deleteMany?: Maybe<Array<SegmentScalarWhereInput>>,
  updateMany?: Maybe<Array<SegmentUpdateManyWithWhereNestedInput>>,
};

export type SegmentUpdateManyWithWhereNestedInput = {
  where: SegmentScalarWhereInput,
  data: SegmentUpdateManyDataInput,
};

export type SegmentUpdateOneInput = {
  create?: Maybe<SegmentCreateInput>,
  update?: Maybe<SegmentUpdateDataInput>,
  upsert?: Maybe<SegmentUpsertNestedInput>,
  delete?: Maybe<Scalars['Boolean']>,
  disconnect?: Maybe<Scalars['Boolean']>,
  connect?: Maybe<SegmentWhereUniqueInput>,
};

export type SegmentUpdatephonemizedTextInput = {
  set?: Maybe<Array<Scalars['Int']>>,
};

export type SegmentUpdatesourceWordsInput = {
  set?: Maybe<Array<Scalars['Json']>>,
};

export type SegmentUpdateWithoutVideoDataInput = {
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SegmentState>,
  done?: Maybe<Scalars['Boolean']>,
  sourceWords?: Maybe<SegmentUpdatesourceWordsInput>,
  text?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  autoTranslation?: Maybe<Scalars['String']>,
  sourceLanguage?: Maybe<Scalars['String']>,
  targetLanguage?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  translationStartTime?: Maybe<Scalars['Float']>,
  stretchRate?: Maybe<Scalars['Float']>,
  translatedTextParsed?: Maybe<Scalars['String']>,
  translatedText?: Maybe<Scalars['String']>,
  phonemizedText?: Maybe<SegmentUpdatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  previewSample?: Maybe<SampleUpdateOneInput>,
  tunedSample?: Maybe<SampleUpdateOneInput>,
  modelName?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
};

export type SegmentUpdateWithWhereUniqueWithoutVideoInput = {
  where: SegmentWhereUniqueInput,
  data: SegmentUpdateWithoutVideoDataInput,
};

export type SegmentUpsertNestedInput = {
  update: SegmentUpdateDataInput,
  create: SegmentCreateInput,
};

export type SegmentUpsertWithWhereUniqueWithoutVideoInput = {
  where: SegmentWhereUniqueInput,
  update: SegmentUpdateWithoutVideoDataInput,
  create: SegmentCreateWithoutVideoInput,
};

export type SegmentWhereInput = {
  video?: Maybe<TranslatedVideoWhereInput>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  stateValue?: Maybe<SegmentState>,
  stateValue_not?: Maybe<SegmentState>,
  stateValue_in?: Maybe<Array<SegmentState>>,
  stateValue_not_in?: Maybe<Array<SegmentState>>,
  done?: Maybe<Scalars['Boolean']>,
  done_not?: Maybe<Scalars['Boolean']>,
  text?: Maybe<Scalars['String']>,
  text_not?: Maybe<Scalars['String']>,
  text_in?: Maybe<Array<Scalars['String']>>,
  text_not_in?: Maybe<Array<Scalars['String']>>,
  text_lt?: Maybe<Scalars['String']>,
  text_lte?: Maybe<Scalars['String']>,
  text_gt?: Maybe<Scalars['String']>,
  text_gte?: Maybe<Scalars['String']>,
  text_contains?: Maybe<Scalars['String']>,
  text_not_contains?: Maybe<Scalars['String']>,
  text_starts_with?: Maybe<Scalars['String']>,
  text_not_starts_with?: Maybe<Scalars['String']>,
  text_ends_with?: Maybe<Scalars['String']>,
  text_not_ends_with?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['Float']>,
  startTime_not?: Maybe<Scalars['Float']>,
  startTime_in?: Maybe<Array<Scalars['Float']>>,
  startTime_not_in?: Maybe<Array<Scalars['Float']>>,
  startTime_lt?: Maybe<Scalars['Float']>,
  startTime_lte?: Maybe<Scalars['Float']>,
  startTime_gt?: Maybe<Scalars['Float']>,
  startTime_gte?: Maybe<Scalars['Float']>,
  endTime?: Maybe<Scalars['Float']>,
  endTime_not?: Maybe<Scalars['Float']>,
  endTime_in?: Maybe<Array<Scalars['Float']>>,
  endTime_not_in?: Maybe<Array<Scalars['Float']>>,
  endTime_lt?: Maybe<Scalars['Float']>,
  endTime_lte?: Maybe<Scalars['Float']>,
  endTime_gt?: Maybe<Scalars['Float']>,
  endTime_gte?: Maybe<Scalars['Float']>,
  autoTranslation?: Maybe<Scalars['String']>,
  autoTranslation_not?: Maybe<Scalars['String']>,
  autoTranslation_in?: Maybe<Array<Scalars['String']>>,
  autoTranslation_not_in?: Maybe<Array<Scalars['String']>>,
  autoTranslation_lt?: Maybe<Scalars['String']>,
  autoTranslation_lte?: Maybe<Scalars['String']>,
  autoTranslation_gt?: Maybe<Scalars['String']>,
  autoTranslation_gte?: Maybe<Scalars['String']>,
  autoTranslation_contains?: Maybe<Scalars['String']>,
  autoTranslation_not_contains?: Maybe<Scalars['String']>,
  autoTranslation_starts_with?: Maybe<Scalars['String']>,
  autoTranslation_not_starts_with?: Maybe<Scalars['String']>,
  autoTranslation_ends_with?: Maybe<Scalars['String']>,
  autoTranslation_not_ends_with?: Maybe<Scalars['String']>,
  sourceLanguage?: Maybe<Scalars['String']>,
  sourceLanguage_not?: Maybe<Scalars['String']>,
  sourceLanguage_in?: Maybe<Array<Scalars['String']>>,
  sourceLanguage_not_in?: Maybe<Array<Scalars['String']>>,
  sourceLanguage_lt?: Maybe<Scalars['String']>,
  sourceLanguage_lte?: Maybe<Scalars['String']>,
  sourceLanguage_gt?: Maybe<Scalars['String']>,
  sourceLanguage_gte?: Maybe<Scalars['String']>,
  sourceLanguage_contains?: Maybe<Scalars['String']>,
  sourceLanguage_not_contains?: Maybe<Scalars['String']>,
  sourceLanguage_starts_with?: Maybe<Scalars['String']>,
  sourceLanguage_not_starts_with?: Maybe<Scalars['String']>,
  sourceLanguage_ends_with?: Maybe<Scalars['String']>,
  sourceLanguage_not_ends_with?: Maybe<Scalars['String']>,
  targetLanguage?: Maybe<Scalars['String']>,
  targetLanguage_not?: Maybe<Scalars['String']>,
  targetLanguage_in?: Maybe<Array<Scalars['String']>>,
  targetLanguage_not_in?: Maybe<Array<Scalars['String']>>,
  targetLanguage_lt?: Maybe<Scalars['String']>,
  targetLanguage_lte?: Maybe<Scalars['String']>,
  targetLanguage_gt?: Maybe<Scalars['String']>,
  targetLanguage_gte?: Maybe<Scalars['String']>,
  targetLanguage_contains?: Maybe<Scalars['String']>,
  targetLanguage_not_contains?: Maybe<Scalars['String']>,
  targetLanguage_starts_with?: Maybe<Scalars['String']>,
  targetLanguage_not_starts_with?: Maybe<Scalars['String']>,
  targetLanguage_ends_with?: Maybe<Scalars['String']>,
  targetLanguage_not_ends_with?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  papercupSpeakerId_not?: Maybe<Scalars['String']>,
  papercupSpeakerId_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_not_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_lt?: Maybe<Scalars['String']>,
  papercupSpeakerId_lte?: Maybe<Scalars['String']>,
  papercupSpeakerId_gt?: Maybe<Scalars['String']>,
  papercupSpeakerId_gte?: Maybe<Scalars['String']>,
  papercupSpeakerId_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_ends_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_ends_with?: Maybe<Scalars['String']>,
  style?: Maybe<Scalars['String']>,
  style_not?: Maybe<Scalars['String']>,
  style_in?: Maybe<Array<Scalars['String']>>,
  style_not_in?: Maybe<Array<Scalars['String']>>,
  style_lt?: Maybe<Scalars['String']>,
  style_lte?: Maybe<Scalars['String']>,
  style_gt?: Maybe<Scalars['String']>,
  style_gte?: Maybe<Scalars['String']>,
  style_contains?: Maybe<Scalars['String']>,
  style_not_contains?: Maybe<Scalars['String']>,
  style_starts_with?: Maybe<Scalars['String']>,
  style_not_starts_with?: Maybe<Scalars['String']>,
  style_ends_with?: Maybe<Scalars['String']>,
  style_not_ends_with?: Maybe<Scalars['String']>,
  translationStartTime?: Maybe<Scalars['Float']>,
  translationStartTime_not?: Maybe<Scalars['Float']>,
  translationStartTime_in?: Maybe<Array<Scalars['Float']>>,
  translationStartTime_not_in?: Maybe<Array<Scalars['Float']>>,
  translationStartTime_lt?: Maybe<Scalars['Float']>,
  translationStartTime_lte?: Maybe<Scalars['Float']>,
  translationStartTime_gt?: Maybe<Scalars['Float']>,
  translationStartTime_gte?: Maybe<Scalars['Float']>,
  stretchRate?: Maybe<Scalars['Float']>,
  stretchRate_not?: Maybe<Scalars['Float']>,
  stretchRate_in?: Maybe<Array<Scalars['Float']>>,
  stretchRate_not_in?: Maybe<Array<Scalars['Float']>>,
  stretchRate_lt?: Maybe<Scalars['Float']>,
  stretchRate_lte?: Maybe<Scalars['Float']>,
  stretchRate_gt?: Maybe<Scalars['Float']>,
  stretchRate_gte?: Maybe<Scalars['Float']>,
  translatedTextParsed?: Maybe<Scalars['String']>,
  translatedTextParsed_not?: Maybe<Scalars['String']>,
  translatedTextParsed_in?: Maybe<Array<Scalars['String']>>,
  translatedTextParsed_not_in?: Maybe<Array<Scalars['String']>>,
  translatedTextParsed_lt?: Maybe<Scalars['String']>,
  translatedTextParsed_lte?: Maybe<Scalars['String']>,
  translatedTextParsed_gt?: Maybe<Scalars['String']>,
  translatedTextParsed_gte?: Maybe<Scalars['String']>,
  translatedTextParsed_contains?: Maybe<Scalars['String']>,
  translatedTextParsed_not_contains?: Maybe<Scalars['String']>,
  translatedTextParsed_starts_with?: Maybe<Scalars['String']>,
  translatedTextParsed_not_starts_with?: Maybe<Scalars['String']>,
  translatedTextParsed_ends_with?: Maybe<Scalars['String']>,
  translatedTextParsed_not_ends_with?: Maybe<Scalars['String']>,
  translatedText?: Maybe<Scalars['String']>,
  translatedText_not?: Maybe<Scalars['String']>,
  translatedText_in?: Maybe<Array<Scalars['String']>>,
  translatedText_not_in?: Maybe<Array<Scalars['String']>>,
  translatedText_lt?: Maybe<Scalars['String']>,
  translatedText_lte?: Maybe<Scalars['String']>,
  translatedText_gt?: Maybe<Scalars['String']>,
  translatedText_gte?: Maybe<Scalars['String']>,
  translatedText_contains?: Maybe<Scalars['String']>,
  translatedText_not_contains?: Maybe<Scalars['String']>,
  translatedText_starts_with?: Maybe<Scalars['String']>,
  translatedText_not_starts_with?: Maybe<Scalars['String']>,
  translatedText_ends_with?: Maybe<Scalars['String']>,
  translatedText_not_ends_with?: Maybe<Scalars['String']>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  phonemizationVersion_not?: Maybe<Scalars['String']>,
  phonemizationVersion_in?: Maybe<Array<Scalars['String']>>,
  phonemizationVersion_not_in?: Maybe<Array<Scalars['String']>>,
  phonemizationVersion_lt?: Maybe<Scalars['String']>,
  phonemizationVersion_lte?: Maybe<Scalars['String']>,
  phonemizationVersion_gt?: Maybe<Scalars['String']>,
  phonemizationVersion_gte?: Maybe<Scalars['String']>,
  phonemizationVersion_contains?: Maybe<Scalars['String']>,
  phonemizationVersion_not_contains?: Maybe<Scalars['String']>,
  phonemizationVersion_starts_with?: Maybe<Scalars['String']>,
  phonemizationVersion_not_starts_with?: Maybe<Scalars['String']>,
  phonemizationVersion_ends_with?: Maybe<Scalars['String']>,
  phonemizationVersion_not_ends_with?: Maybe<Scalars['String']>,
  featuresS3?: Maybe<Scalars['String']>,
  featuresS3_not?: Maybe<Scalars['String']>,
  featuresS3_in?: Maybe<Array<Scalars['String']>>,
  featuresS3_not_in?: Maybe<Array<Scalars['String']>>,
  featuresS3_lt?: Maybe<Scalars['String']>,
  featuresS3_lte?: Maybe<Scalars['String']>,
  featuresS3_gt?: Maybe<Scalars['String']>,
  featuresS3_gte?: Maybe<Scalars['String']>,
  featuresS3_contains?: Maybe<Scalars['String']>,
  featuresS3_not_contains?: Maybe<Scalars['String']>,
  featuresS3_starts_with?: Maybe<Scalars['String']>,
  featuresS3_not_starts_with?: Maybe<Scalars['String']>,
  featuresS3_ends_with?: Maybe<Scalars['String']>,
  featuresS3_not_ends_with?: Maybe<Scalars['String']>,
  previewSample?: Maybe<SampleWhereInput>,
  tunedSample?: Maybe<SampleWhereInput>,
  modelName?: Maybe<Scalars['String']>,
  modelName_not?: Maybe<Scalars['String']>,
  modelName_in?: Maybe<Array<Scalars['String']>>,
  modelName_not_in?: Maybe<Array<Scalars['String']>>,
  modelName_lt?: Maybe<Scalars['String']>,
  modelName_lte?: Maybe<Scalars['String']>,
  modelName_gt?: Maybe<Scalars['String']>,
  modelName_gte?: Maybe<Scalars['String']>,
  modelName_contains?: Maybe<Scalars['String']>,
  modelName_not_contains?: Maybe<Scalars['String']>,
  modelName_starts_with?: Maybe<Scalars['String']>,
  modelName_not_starts_with?: Maybe<Scalars['String']>,
  modelName_ends_with?: Maybe<Scalars['String']>,
  modelName_not_ends_with?: Maybe<Scalars['String']>,
  modelVersion?: Maybe<Scalars['String']>,
  modelVersion_not?: Maybe<Scalars['String']>,
  modelVersion_in?: Maybe<Array<Scalars['String']>>,
  modelVersion_not_in?: Maybe<Array<Scalars['String']>>,
  modelVersion_lt?: Maybe<Scalars['String']>,
  modelVersion_lte?: Maybe<Scalars['String']>,
  modelVersion_gt?: Maybe<Scalars['String']>,
  modelVersion_gte?: Maybe<Scalars['String']>,
  modelVersion_contains?: Maybe<Scalars['String']>,
  modelVersion_not_contains?: Maybe<Scalars['String']>,
  modelVersion_starts_with?: Maybe<Scalars['String']>,
  modelVersion_not_starts_with?: Maybe<Scalars['String']>,
  modelVersion_ends_with?: Maybe<Scalars['String']>,
  modelVersion_not_ends_with?: Maybe<Scalars['String']>,
  AND?: Maybe<Array<SegmentWhereInput>>,
  OR?: Maybe<Array<SegmentWhereInput>>,
  NOT?: Maybe<Array<SegmentWhereInput>>,
};

export type SegmentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type SourceChannel = {
   __typename?: 'SourceChannel',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  channelType?: Maybe<ChannelType>,
  ytChannelId?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  s3root: Scalars['String'],
  channelOwner?: Maybe<User>,
  language: Scalars['String'],
  typicalVideoDuration?: Maybe<TypicalVideoDuration>,
  translatedChannels?: Maybe<Array<TranslatedChannel>>,
  sourceVideos?: Maybe<Array<SourceVideo>>,
  /** Channel Information from YouTube data API */
  ytChannel?: Maybe<YtChannel>,
};


export type SourceChannelTranslatedChannelsArgs = {
  where?: Maybe<TranslatedChannelWhereInput>,
  orderBy?: Maybe<TranslatedChannelOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type SourceChannelSourceVideosArgs = {
  where?: Maybe<SourceVideoWhereInput>,
  orderBy?: Maybe<SourceVideoOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type SourceChannelCreateOneWithoutSourceVideosInput = {
  create?: Maybe<SourceChannelCreateWithoutSourceVideosInput>,
  connect?: Maybe<SourceChannelWhereUniqueInput>,
};

export type SourceChannelCreateOneWithoutTranslatedChannelsInput = {
  create?: Maybe<SourceChannelCreateWithoutTranslatedChannelsInput>,
  connect?: Maybe<SourceChannelWhereUniqueInput>,
};

export type SourceChannelCreateWithoutSourceVideosInput = {
  id?: Maybe<Scalars['ID']>,
  channelType?: Maybe<ChannelType>,
  ytChannelId?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  s3root: Scalars['String'],
  channelOwner?: Maybe<UserCreateOneInput>,
  language: Scalars['String'],
  typicalVideoDuration?: Maybe<TypicalVideoDuration>,
  translatedChannels?: Maybe<TranslatedChannelCreateManyWithoutSourceChannelInput>,
};

export type SourceChannelCreateWithoutTranslatedChannelsInput = {
  id?: Maybe<Scalars['ID']>,
  channelType?: Maybe<ChannelType>,
  ytChannelId?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  s3root: Scalars['String'],
  channelOwner?: Maybe<UserCreateOneInput>,
  language: Scalars['String'],
  typicalVideoDuration?: Maybe<TypicalVideoDuration>,
  sourceVideos?: Maybe<SourceVideoCreateManyWithoutSourceChannelInput>,
};

export enum SourceChannelOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ChannelTypeAsc = 'channelType_ASC',
  ChannelTypeDesc = 'channelType_DESC',
  YtChannelIdAsc = 'ytChannelId_ASC',
  YtChannelIdDesc = 'ytChannelId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  S3rootAsc = 's3root_ASC',
  S3rootDesc = 's3root_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  TypicalVideoDurationAsc = 'typicalVideoDuration_ASC',
  TypicalVideoDurationDesc = 'typicalVideoDuration_DESC'
}

export type SourceChannelUpdateInput = {
  channelType?: Maybe<ChannelType>,
  ytChannelId?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  channelOwner?: Maybe<UserUpdateOneInput>,
  language?: Maybe<Scalars['String']>,
  typicalVideoDuration?: Maybe<TypicalVideoDuration>,
  translatedChannels?: Maybe<TranslatedChannelUpdateManyWithoutSourceChannelInput>,
  sourceVideos?: Maybe<SourceVideoUpdateManyWithoutSourceChannelInput>,
};

export type SourceChannelUpdateOneRequiredWithoutSourceVideosInput = {
  create?: Maybe<SourceChannelCreateWithoutSourceVideosInput>,
  update?: Maybe<SourceChannelUpdateWithoutSourceVideosDataInput>,
  upsert?: Maybe<SourceChannelUpsertWithoutSourceVideosInput>,
  connect?: Maybe<SourceChannelWhereUniqueInput>,
};

export type SourceChannelUpdateOneRequiredWithoutTranslatedChannelsInput = {
  create?: Maybe<SourceChannelCreateWithoutTranslatedChannelsInput>,
  update?: Maybe<SourceChannelUpdateWithoutTranslatedChannelsDataInput>,
  upsert?: Maybe<SourceChannelUpsertWithoutTranslatedChannelsInput>,
  connect?: Maybe<SourceChannelWhereUniqueInput>,
};

export type SourceChannelUpdateWithoutSourceVideosDataInput = {
  channelType?: Maybe<ChannelType>,
  ytChannelId?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  channelOwner?: Maybe<UserUpdateOneInput>,
  language?: Maybe<Scalars['String']>,
  typicalVideoDuration?: Maybe<TypicalVideoDuration>,
  translatedChannels?: Maybe<TranslatedChannelUpdateManyWithoutSourceChannelInput>,
};

export type SourceChannelUpdateWithoutTranslatedChannelsDataInput = {
  channelType?: Maybe<ChannelType>,
  ytChannelId?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  channelOwner?: Maybe<UserUpdateOneInput>,
  language?: Maybe<Scalars['String']>,
  typicalVideoDuration?: Maybe<TypicalVideoDuration>,
  sourceVideos?: Maybe<SourceVideoUpdateManyWithoutSourceChannelInput>,
};

export type SourceChannelUpsertWithoutSourceVideosInput = {
  update: SourceChannelUpdateWithoutSourceVideosDataInput,
  create: SourceChannelCreateWithoutSourceVideosInput,
};

export type SourceChannelUpsertWithoutTranslatedChannelsInput = {
  update: SourceChannelUpdateWithoutTranslatedChannelsDataInput,
  create: SourceChannelCreateWithoutTranslatedChannelsInput,
};

export type SourceChannelWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  channelType?: Maybe<ChannelType>,
  channelType_not?: Maybe<ChannelType>,
  channelType_in?: Maybe<Array<ChannelType>>,
  channelType_not_in?: Maybe<Array<ChannelType>>,
  ytChannelId?: Maybe<Scalars['String']>,
  ytChannelId_not?: Maybe<Scalars['String']>,
  ytChannelId_in?: Maybe<Array<Scalars['String']>>,
  ytChannelId_not_in?: Maybe<Array<Scalars['String']>>,
  ytChannelId_lt?: Maybe<Scalars['String']>,
  ytChannelId_lte?: Maybe<Scalars['String']>,
  ytChannelId_gt?: Maybe<Scalars['String']>,
  ytChannelId_gte?: Maybe<Scalars['String']>,
  ytChannelId_contains?: Maybe<Scalars['String']>,
  ytChannelId_not_contains?: Maybe<Scalars['String']>,
  ytChannelId_starts_with?: Maybe<Scalars['String']>,
  ytChannelId_not_starts_with?: Maybe<Scalars['String']>,
  ytChannelId_ends_with?: Maybe<Scalars['String']>,
  ytChannelId_not_ends_with?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  title_not?: Maybe<Scalars['String']>,
  title_in?: Maybe<Array<Scalars['String']>>,
  title_not_in?: Maybe<Array<Scalars['String']>>,
  title_lt?: Maybe<Scalars['String']>,
  title_lte?: Maybe<Scalars['String']>,
  title_gt?: Maybe<Scalars['String']>,
  title_gte?: Maybe<Scalars['String']>,
  title_contains?: Maybe<Scalars['String']>,
  title_not_contains?: Maybe<Scalars['String']>,
  title_starts_with?: Maybe<Scalars['String']>,
  title_not_starts_with?: Maybe<Scalars['String']>,
  title_ends_with?: Maybe<Scalars['String']>,
  title_not_ends_with?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  s3root_not?: Maybe<Scalars['String']>,
  s3root_in?: Maybe<Array<Scalars['String']>>,
  s3root_not_in?: Maybe<Array<Scalars['String']>>,
  s3root_lt?: Maybe<Scalars['String']>,
  s3root_lte?: Maybe<Scalars['String']>,
  s3root_gt?: Maybe<Scalars['String']>,
  s3root_gte?: Maybe<Scalars['String']>,
  s3root_contains?: Maybe<Scalars['String']>,
  s3root_not_contains?: Maybe<Scalars['String']>,
  s3root_starts_with?: Maybe<Scalars['String']>,
  s3root_not_starts_with?: Maybe<Scalars['String']>,
  s3root_ends_with?: Maybe<Scalars['String']>,
  s3root_not_ends_with?: Maybe<Scalars['String']>,
  channelOwner?: Maybe<UserWhereInput>,
  language?: Maybe<Scalars['String']>,
  language_not?: Maybe<Scalars['String']>,
  language_in?: Maybe<Array<Scalars['String']>>,
  language_not_in?: Maybe<Array<Scalars['String']>>,
  language_lt?: Maybe<Scalars['String']>,
  language_lte?: Maybe<Scalars['String']>,
  language_gt?: Maybe<Scalars['String']>,
  language_gte?: Maybe<Scalars['String']>,
  language_contains?: Maybe<Scalars['String']>,
  language_not_contains?: Maybe<Scalars['String']>,
  language_starts_with?: Maybe<Scalars['String']>,
  language_not_starts_with?: Maybe<Scalars['String']>,
  language_ends_with?: Maybe<Scalars['String']>,
  language_not_ends_with?: Maybe<Scalars['String']>,
  typicalVideoDuration?: Maybe<TypicalVideoDuration>,
  typicalVideoDuration_not?: Maybe<TypicalVideoDuration>,
  typicalVideoDuration_in?: Maybe<Array<TypicalVideoDuration>>,
  typicalVideoDuration_not_in?: Maybe<Array<TypicalVideoDuration>>,
  translatedChannels_every?: Maybe<TranslatedChannelWhereInput>,
  translatedChannels_some?: Maybe<TranslatedChannelWhereInput>,
  translatedChannels_none?: Maybe<TranslatedChannelWhereInput>,
  sourceVideos_every?: Maybe<SourceVideoWhereInput>,
  sourceVideos_some?: Maybe<SourceVideoWhereInput>,
  sourceVideos_none?: Maybe<SourceVideoWhereInput>,
  AND?: Maybe<Array<SourceChannelWhereInput>>,
  OR?: Maybe<Array<SourceChannelWhereInput>>,
  NOT?: Maybe<Array<SourceChannelWhereInput>>,
};

export type SourceChannelWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  ytChannelId?: Maybe<Scalars['String']>,
};

export type SourceVideo = {
   __typename?: 'SourceVideo',
  sourceChannel: SourceChannel,
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  title?: Maybe<Scalars['String']>,
  videoSource: SourceVideoSource,
  s3root: Scalars['String'],
  presignedS3Policy?: Maybe<Scalars['Json']>,
  ytVideoId?: Maybe<Scalars['String']>,
  uploader?: Maybe<User>,
  state: Scalars['Json'],
  stateValue: SourceVideoState,
  duration?: Maybe<Scalars['Float']>,
  videoFile?: Maybe<File>,
  highResVideoFile?: Maybe<File>,
  extractedAudioS3?: Maybe<File>,
  extractedAudioGCP?: Maybe<File>,
  translatedVideos?: Maybe<Array<TranslatedVideo>>,
  asrRaw?: Maybe<Scalars['Json']>,
  asrSegments?: Maybe<Scalars['Json']>,
  /** Video Information from YouTube data API */
  ytVideo?: Maybe<YtVideo>,
};


export type SourceVideoTranslatedVideosArgs = {
  where?: Maybe<TranslatedVideoWhereInput>,
  orderBy?: Maybe<TranslatedVideoOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type SourceVideoCreateManyWithoutSourceChannelInput = {
  create?: Maybe<Array<SourceVideoCreateWithoutSourceChannelInput>>,
  connect?: Maybe<Array<SourceVideoWhereUniqueInput>>,
};

export type SourceVideoCreateOneWithoutTranslatedVideosInput = {
  create?: Maybe<SourceVideoCreateWithoutTranslatedVideosInput>,
  connect?: Maybe<SourceVideoWhereUniqueInput>,
};

export type SourceVideoCreateWithoutSourceChannelInput = {
  id?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  videoSource: SourceVideoSource,
  s3root: Scalars['String'],
  presignedS3Policy?: Maybe<Scalars['Json']>,
  ytVideoId?: Maybe<Scalars['String']>,
  uploader?: Maybe<UserCreateOneInput>,
  state: Scalars['Json'],
  stateValue?: Maybe<SourceVideoState>,
  duration?: Maybe<Scalars['Float']>,
  videoFile?: Maybe<FileCreateOneInput>,
  highResVideoFile?: Maybe<FileCreateOneInput>,
  extractedAudioS3?: Maybe<FileCreateOneInput>,
  extractedAudioGCP?: Maybe<FileCreateOneInput>,
  translatedVideos?: Maybe<TranslatedVideoCreateManyWithoutSourceVideoInput>,
  asrRaw?: Maybe<Scalars['Json']>,
  asrSegments?: Maybe<Scalars['Json']>,
};

export type SourceVideoCreateWithoutTranslatedVideosInput = {
  sourceChannel: SourceChannelCreateOneWithoutSourceVideosInput,
  id?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  videoSource: SourceVideoSource,
  s3root: Scalars['String'],
  presignedS3Policy?: Maybe<Scalars['Json']>,
  ytVideoId?: Maybe<Scalars['String']>,
  uploader?: Maybe<UserCreateOneInput>,
  state: Scalars['Json'],
  stateValue?: Maybe<SourceVideoState>,
  duration?: Maybe<Scalars['Float']>,
  videoFile?: Maybe<FileCreateOneInput>,
  highResVideoFile?: Maybe<FileCreateOneInput>,
  extractedAudioS3?: Maybe<FileCreateOneInput>,
  extractedAudioGCP?: Maybe<FileCreateOneInput>,
  asrRaw?: Maybe<Scalars['Json']>,
  asrSegments?: Maybe<Scalars['Json']>,
};

export enum SourceVideoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VideoSourceAsc = 'videoSource_ASC',
  VideoSourceDesc = 'videoSource_DESC',
  S3rootAsc = 's3root_ASC',
  S3rootDesc = 's3root_DESC',
  PresignedS3PolicyAsc = 'presignedS3Policy_ASC',
  PresignedS3PolicyDesc = 'presignedS3Policy_DESC',
  YtVideoIdAsc = 'ytVideoId_ASC',
  YtVideoIdDesc = 'ytVideoId_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  AsrRawAsc = 'asrRaw_ASC',
  AsrRawDesc = 'asrRaw_DESC',
  AsrSegmentsAsc = 'asrSegments_ASC',
  AsrSegmentsDesc = 'asrSegments_DESC'
}

export type SourceVideoPreviousValues = {
   __typename?: 'SourceVideoPreviousValues',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  title?: Maybe<Scalars['String']>,
  videoSource: SourceVideoSource,
  s3root: Scalars['String'],
  presignedS3Policy?: Maybe<Scalars['Json']>,
  ytVideoId?: Maybe<Scalars['String']>,
  state: Scalars['Json'],
  stateValue: SourceVideoState,
  duration?: Maybe<Scalars['Float']>,
  asrRaw?: Maybe<Scalars['Json']>,
  asrSegments?: Maybe<Scalars['Json']>,
};

export type SourceVideoScalarWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  title?: Maybe<Scalars['String']>,
  title_not?: Maybe<Scalars['String']>,
  title_in?: Maybe<Array<Scalars['String']>>,
  title_not_in?: Maybe<Array<Scalars['String']>>,
  title_lt?: Maybe<Scalars['String']>,
  title_lte?: Maybe<Scalars['String']>,
  title_gt?: Maybe<Scalars['String']>,
  title_gte?: Maybe<Scalars['String']>,
  title_contains?: Maybe<Scalars['String']>,
  title_not_contains?: Maybe<Scalars['String']>,
  title_starts_with?: Maybe<Scalars['String']>,
  title_not_starts_with?: Maybe<Scalars['String']>,
  title_ends_with?: Maybe<Scalars['String']>,
  title_not_ends_with?: Maybe<Scalars['String']>,
  videoSource?: Maybe<SourceVideoSource>,
  videoSource_not?: Maybe<SourceVideoSource>,
  videoSource_in?: Maybe<Array<SourceVideoSource>>,
  videoSource_not_in?: Maybe<Array<SourceVideoSource>>,
  s3root?: Maybe<Scalars['String']>,
  s3root_not?: Maybe<Scalars['String']>,
  s3root_in?: Maybe<Array<Scalars['String']>>,
  s3root_not_in?: Maybe<Array<Scalars['String']>>,
  s3root_lt?: Maybe<Scalars['String']>,
  s3root_lte?: Maybe<Scalars['String']>,
  s3root_gt?: Maybe<Scalars['String']>,
  s3root_gte?: Maybe<Scalars['String']>,
  s3root_contains?: Maybe<Scalars['String']>,
  s3root_not_contains?: Maybe<Scalars['String']>,
  s3root_starts_with?: Maybe<Scalars['String']>,
  s3root_not_starts_with?: Maybe<Scalars['String']>,
  s3root_ends_with?: Maybe<Scalars['String']>,
  s3root_not_ends_with?: Maybe<Scalars['String']>,
  ytVideoId?: Maybe<Scalars['String']>,
  ytVideoId_not?: Maybe<Scalars['String']>,
  ytVideoId_in?: Maybe<Array<Scalars['String']>>,
  ytVideoId_not_in?: Maybe<Array<Scalars['String']>>,
  ytVideoId_lt?: Maybe<Scalars['String']>,
  ytVideoId_lte?: Maybe<Scalars['String']>,
  ytVideoId_gt?: Maybe<Scalars['String']>,
  ytVideoId_gte?: Maybe<Scalars['String']>,
  ytVideoId_contains?: Maybe<Scalars['String']>,
  ytVideoId_not_contains?: Maybe<Scalars['String']>,
  ytVideoId_starts_with?: Maybe<Scalars['String']>,
  ytVideoId_not_starts_with?: Maybe<Scalars['String']>,
  ytVideoId_ends_with?: Maybe<Scalars['String']>,
  ytVideoId_not_ends_with?: Maybe<Scalars['String']>,
  stateValue?: Maybe<SourceVideoState>,
  stateValue_not?: Maybe<SourceVideoState>,
  stateValue_in?: Maybe<Array<SourceVideoState>>,
  stateValue_not_in?: Maybe<Array<SourceVideoState>>,
  duration?: Maybe<Scalars['Float']>,
  duration_not?: Maybe<Scalars['Float']>,
  duration_in?: Maybe<Array<Scalars['Float']>>,
  duration_not_in?: Maybe<Array<Scalars['Float']>>,
  duration_lt?: Maybe<Scalars['Float']>,
  duration_lte?: Maybe<Scalars['Float']>,
  duration_gt?: Maybe<Scalars['Float']>,
  duration_gte?: Maybe<Scalars['Float']>,
  AND?: Maybe<Array<SourceVideoScalarWhereInput>>,
  OR?: Maybe<Array<SourceVideoScalarWhereInput>>,
  NOT?: Maybe<Array<SourceVideoScalarWhereInput>>,
};

export enum SourceVideoSource {
  YouTube = 'YouTube',
  ManualUpload = 'ManualUpload'
}

export enum SourceVideoState {
  Initial = 'Initial',
  AwaitingManualUpload = 'AwaitingManualUpload',
  DownloadingLowResYtVideo = 'DownloadingLowResYtVideo',
  DownloadingHighResYtVideo = 'DownloadingHighResYtVideo',
  ExtractingAudio = 'ExtractingAudio',
  AsRing = 'ASRing',
  Complete = 'Complete'
}

export type SourceVideoSubscriptionPayload = {
   __typename?: 'SourceVideoSubscriptionPayload',
  mutation: MutationType,
  node?: Maybe<SourceVideo>,
  updatedFields?: Maybe<Array<Scalars['String']>>,
  previousValues?: Maybe<SourceVideoPreviousValues>,
};

export type SourceVideoUpdateInput = {
  sourceChannel?: Maybe<SourceChannelUpdateOneRequiredWithoutSourceVideosInput>,
  title?: Maybe<Scalars['String']>,
  videoSource?: Maybe<SourceVideoSource>,
  s3root?: Maybe<Scalars['String']>,
  presignedS3Policy?: Maybe<Scalars['Json']>,
  ytVideoId?: Maybe<Scalars['String']>,
  uploader?: Maybe<UserUpdateOneInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SourceVideoState>,
  duration?: Maybe<Scalars['Float']>,
  videoFile?: Maybe<FileUpdateOneInput>,
  highResVideoFile?: Maybe<FileUpdateOneInput>,
  extractedAudioS3?: Maybe<FileUpdateOneInput>,
  extractedAudioGCP?: Maybe<FileUpdateOneInput>,
  translatedVideos?: Maybe<TranslatedVideoUpdateManyWithoutSourceVideoInput>,
  asrRaw?: Maybe<Scalars['Json']>,
  asrSegments?: Maybe<Scalars['Json']>,
};

export type SourceVideoUpdateManyDataInput = {
  title?: Maybe<Scalars['String']>,
  videoSource?: Maybe<SourceVideoSource>,
  s3root?: Maybe<Scalars['String']>,
  presignedS3Policy?: Maybe<Scalars['Json']>,
  ytVideoId?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SourceVideoState>,
  duration?: Maybe<Scalars['Float']>,
  asrRaw?: Maybe<Scalars['Json']>,
  asrSegments?: Maybe<Scalars['Json']>,
};

export type SourceVideoUpdateManyWithoutSourceChannelInput = {
  create?: Maybe<Array<SourceVideoCreateWithoutSourceChannelInput>>,
  delete?: Maybe<Array<SourceVideoWhereUniqueInput>>,
  connect?: Maybe<Array<SourceVideoWhereUniqueInput>>,
  set?: Maybe<Array<SourceVideoWhereUniqueInput>>,
  disconnect?: Maybe<Array<SourceVideoWhereUniqueInput>>,
  update?: Maybe<Array<SourceVideoUpdateWithWhereUniqueWithoutSourceChannelInput>>,
  upsert?: Maybe<Array<SourceVideoUpsertWithWhereUniqueWithoutSourceChannelInput>>,
  deleteMany?: Maybe<Array<SourceVideoScalarWhereInput>>,
  updateMany?: Maybe<Array<SourceVideoUpdateManyWithWhereNestedInput>>,
};

export type SourceVideoUpdateManyWithWhereNestedInput = {
  where: SourceVideoScalarWhereInput,
  data: SourceVideoUpdateManyDataInput,
};

export type SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput = {
  create?: Maybe<SourceVideoCreateWithoutTranslatedVideosInput>,
  update?: Maybe<SourceVideoUpdateWithoutTranslatedVideosDataInput>,
  upsert?: Maybe<SourceVideoUpsertWithoutTranslatedVideosInput>,
  connect?: Maybe<SourceVideoWhereUniqueInput>,
};

export type SourceVideoUpdateWithoutSourceChannelDataInput = {
  title?: Maybe<Scalars['String']>,
  videoSource?: Maybe<SourceVideoSource>,
  s3root?: Maybe<Scalars['String']>,
  presignedS3Policy?: Maybe<Scalars['Json']>,
  ytVideoId?: Maybe<Scalars['String']>,
  uploader?: Maybe<UserUpdateOneInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SourceVideoState>,
  duration?: Maybe<Scalars['Float']>,
  videoFile?: Maybe<FileUpdateOneInput>,
  highResVideoFile?: Maybe<FileUpdateOneInput>,
  extractedAudioS3?: Maybe<FileUpdateOneInput>,
  extractedAudioGCP?: Maybe<FileUpdateOneInput>,
  translatedVideos?: Maybe<TranslatedVideoUpdateManyWithoutSourceVideoInput>,
  asrRaw?: Maybe<Scalars['Json']>,
  asrSegments?: Maybe<Scalars['Json']>,
};

export type SourceVideoUpdateWithoutTranslatedVideosDataInput = {
  sourceChannel?: Maybe<SourceChannelUpdateOneRequiredWithoutSourceVideosInput>,
  title?: Maybe<Scalars['String']>,
  videoSource?: Maybe<SourceVideoSource>,
  s3root?: Maybe<Scalars['String']>,
  presignedS3Policy?: Maybe<Scalars['Json']>,
  ytVideoId?: Maybe<Scalars['String']>,
  uploader?: Maybe<UserUpdateOneInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<SourceVideoState>,
  duration?: Maybe<Scalars['Float']>,
  videoFile?: Maybe<FileUpdateOneInput>,
  highResVideoFile?: Maybe<FileUpdateOneInput>,
  extractedAudioS3?: Maybe<FileUpdateOneInput>,
  extractedAudioGCP?: Maybe<FileUpdateOneInput>,
  asrRaw?: Maybe<Scalars['Json']>,
  asrSegments?: Maybe<Scalars['Json']>,
};

export type SourceVideoUpdateWithWhereUniqueWithoutSourceChannelInput = {
  where: SourceVideoWhereUniqueInput,
  data: SourceVideoUpdateWithoutSourceChannelDataInput,
};

export type SourceVideoUpsertWithoutTranslatedVideosInput = {
  update: SourceVideoUpdateWithoutTranslatedVideosDataInput,
  create: SourceVideoCreateWithoutTranslatedVideosInput,
};

export type SourceVideoUpsertWithWhereUniqueWithoutSourceChannelInput = {
  where: SourceVideoWhereUniqueInput,
  update: SourceVideoUpdateWithoutSourceChannelDataInput,
  create: SourceVideoCreateWithoutSourceChannelInput,
};

export type SourceVideoWhereInput = {
  sourceChannel?: Maybe<SourceChannelWhereInput>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  title?: Maybe<Scalars['String']>,
  title_not?: Maybe<Scalars['String']>,
  title_in?: Maybe<Array<Scalars['String']>>,
  title_not_in?: Maybe<Array<Scalars['String']>>,
  title_lt?: Maybe<Scalars['String']>,
  title_lte?: Maybe<Scalars['String']>,
  title_gt?: Maybe<Scalars['String']>,
  title_gte?: Maybe<Scalars['String']>,
  title_contains?: Maybe<Scalars['String']>,
  title_not_contains?: Maybe<Scalars['String']>,
  title_starts_with?: Maybe<Scalars['String']>,
  title_not_starts_with?: Maybe<Scalars['String']>,
  title_ends_with?: Maybe<Scalars['String']>,
  title_not_ends_with?: Maybe<Scalars['String']>,
  videoSource?: Maybe<SourceVideoSource>,
  videoSource_not?: Maybe<SourceVideoSource>,
  videoSource_in?: Maybe<Array<SourceVideoSource>>,
  videoSource_not_in?: Maybe<Array<SourceVideoSource>>,
  s3root?: Maybe<Scalars['String']>,
  s3root_not?: Maybe<Scalars['String']>,
  s3root_in?: Maybe<Array<Scalars['String']>>,
  s3root_not_in?: Maybe<Array<Scalars['String']>>,
  s3root_lt?: Maybe<Scalars['String']>,
  s3root_lte?: Maybe<Scalars['String']>,
  s3root_gt?: Maybe<Scalars['String']>,
  s3root_gte?: Maybe<Scalars['String']>,
  s3root_contains?: Maybe<Scalars['String']>,
  s3root_not_contains?: Maybe<Scalars['String']>,
  s3root_starts_with?: Maybe<Scalars['String']>,
  s3root_not_starts_with?: Maybe<Scalars['String']>,
  s3root_ends_with?: Maybe<Scalars['String']>,
  s3root_not_ends_with?: Maybe<Scalars['String']>,
  ytVideoId?: Maybe<Scalars['String']>,
  ytVideoId_not?: Maybe<Scalars['String']>,
  ytVideoId_in?: Maybe<Array<Scalars['String']>>,
  ytVideoId_not_in?: Maybe<Array<Scalars['String']>>,
  ytVideoId_lt?: Maybe<Scalars['String']>,
  ytVideoId_lte?: Maybe<Scalars['String']>,
  ytVideoId_gt?: Maybe<Scalars['String']>,
  ytVideoId_gte?: Maybe<Scalars['String']>,
  ytVideoId_contains?: Maybe<Scalars['String']>,
  ytVideoId_not_contains?: Maybe<Scalars['String']>,
  ytVideoId_starts_with?: Maybe<Scalars['String']>,
  ytVideoId_not_starts_with?: Maybe<Scalars['String']>,
  ytVideoId_ends_with?: Maybe<Scalars['String']>,
  ytVideoId_not_ends_with?: Maybe<Scalars['String']>,
  uploader?: Maybe<UserWhereInput>,
  stateValue?: Maybe<SourceVideoState>,
  stateValue_not?: Maybe<SourceVideoState>,
  stateValue_in?: Maybe<Array<SourceVideoState>>,
  stateValue_not_in?: Maybe<Array<SourceVideoState>>,
  duration?: Maybe<Scalars['Float']>,
  duration_not?: Maybe<Scalars['Float']>,
  duration_in?: Maybe<Array<Scalars['Float']>>,
  duration_not_in?: Maybe<Array<Scalars['Float']>>,
  duration_lt?: Maybe<Scalars['Float']>,
  duration_lte?: Maybe<Scalars['Float']>,
  duration_gt?: Maybe<Scalars['Float']>,
  duration_gte?: Maybe<Scalars['Float']>,
  videoFile?: Maybe<FileWhereInput>,
  highResVideoFile?: Maybe<FileWhereInput>,
  extractedAudioS3?: Maybe<FileWhereInput>,
  extractedAudioGCP?: Maybe<FileWhereInput>,
  translatedVideos_every?: Maybe<TranslatedVideoWhereInput>,
  translatedVideos_some?: Maybe<TranslatedVideoWhereInput>,
  translatedVideos_none?: Maybe<TranslatedVideoWhereInput>,
  AND?: Maybe<Array<SourceVideoWhereInput>>,
  OR?: Maybe<Array<SourceVideoWhereInput>>,
  NOT?: Maybe<Array<SourceVideoWhereInput>>,
};

export type SourceVideoWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  ytVideoId?: Maybe<Scalars['String']>,
};

export type Speaker = {
   __typename?: 'Speaker',
  channel?: Maybe<TranslatedChannel>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  id: Scalars['ID'],
  style: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  papercupSpeakerId: Scalars['String'],
};

export type SpeakerCreateInput = {
  channel?: Maybe<TranslatedChannelCreateOneWithoutSpeakersInput>,
  id?: Maybe<Scalars['ID']>,
  style?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  papercupSpeakerId: Scalars['String'],
};

export type SpeakerCreateManyWithoutChannelInput = {
  create?: Maybe<Array<SpeakerCreateWithoutChannelInput>>,
  connect?: Maybe<Array<SpeakerWhereUniqueInput>>,
};

export type SpeakerCreateWithoutChannelInput = {
  id?: Maybe<Scalars['ID']>,
  style?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  papercupSpeakerId: Scalars['String'],
};

export enum SpeakerOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PapercupSpeakerIdAsc = 'papercupSpeakerId_ASC',
  PapercupSpeakerIdDesc = 'papercupSpeakerId_DESC'
}

export type SpeakerScalarWhereInput = {
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  style?: Maybe<Scalars['String']>,
  style_not?: Maybe<Scalars['String']>,
  style_in?: Maybe<Array<Scalars['String']>>,
  style_not_in?: Maybe<Array<Scalars['String']>>,
  style_lt?: Maybe<Scalars['String']>,
  style_lte?: Maybe<Scalars['String']>,
  style_gt?: Maybe<Scalars['String']>,
  style_gte?: Maybe<Scalars['String']>,
  style_contains?: Maybe<Scalars['String']>,
  style_not_contains?: Maybe<Scalars['String']>,
  style_starts_with?: Maybe<Scalars['String']>,
  style_not_starts_with?: Maybe<Scalars['String']>,
  style_ends_with?: Maybe<Scalars['String']>,
  style_not_ends_with?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  name_not?: Maybe<Scalars['String']>,
  name_in?: Maybe<Array<Scalars['String']>>,
  name_not_in?: Maybe<Array<Scalars['String']>>,
  name_lt?: Maybe<Scalars['String']>,
  name_lte?: Maybe<Scalars['String']>,
  name_gt?: Maybe<Scalars['String']>,
  name_gte?: Maybe<Scalars['String']>,
  name_contains?: Maybe<Scalars['String']>,
  name_not_contains?: Maybe<Scalars['String']>,
  name_starts_with?: Maybe<Scalars['String']>,
  name_not_starts_with?: Maybe<Scalars['String']>,
  name_ends_with?: Maybe<Scalars['String']>,
  name_not_ends_with?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  papercupSpeakerId_not?: Maybe<Scalars['String']>,
  papercupSpeakerId_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_not_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_lt?: Maybe<Scalars['String']>,
  papercupSpeakerId_lte?: Maybe<Scalars['String']>,
  papercupSpeakerId_gt?: Maybe<Scalars['String']>,
  papercupSpeakerId_gte?: Maybe<Scalars['String']>,
  papercupSpeakerId_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_ends_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_ends_with?: Maybe<Scalars['String']>,
  AND?: Maybe<Array<SpeakerScalarWhereInput>>,
  OR?: Maybe<Array<SpeakerScalarWhereInput>>,
  NOT?: Maybe<Array<SpeakerScalarWhereInput>>,
};

export type SpeakerUpdateDataInput = {
  channel?: Maybe<TranslatedChannelUpdateOneWithoutSpeakersInput>,
  style?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
};

export type SpeakerUpdateManyDataInput = {
  style?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
};

export type SpeakerUpdateManyInput = {
  create?: Maybe<Array<SpeakerCreateInput>>,
  update?: Maybe<Array<SpeakerUpdateWithWhereUniqueNestedInput>>,
  upsert?: Maybe<Array<SpeakerUpsertWithWhereUniqueNestedInput>>,
  delete?: Maybe<Array<SpeakerWhereUniqueInput>>,
  connect?: Maybe<Array<SpeakerWhereUniqueInput>>,
  set?: Maybe<Array<SpeakerWhereUniqueInput>>,
  disconnect?: Maybe<Array<SpeakerWhereUniqueInput>>,
  deleteMany?: Maybe<Array<SpeakerScalarWhereInput>>,
  updateMany?: Maybe<Array<SpeakerUpdateManyWithWhereNestedInput>>,
};

export type SpeakerUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<SpeakerCreateWithoutChannelInput>>,
  delete?: Maybe<Array<SpeakerWhereUniqueInput>>,
  connect?: Maybe<Array<SpeakerWhereUniqueInput>>,
  set?: Maybe<Array<SpeakerWhereUniqueInput>>,
  disconnect?: Maybe<Array<SpeakerWhereUniqueInput>>,
  update?: Maybe<Array<SpeakerUpdateWithWhereUniqueWithoutChannelInput>>,
  upsert?: Maybe<Array<SpeakerUpsertWithWhereUniqueWithoutChannelInput>>,
  deleteMany?: Maybe<Array<SpeakerScalarWhereInput>>,
  updateMany?: Maybe<Array<SpeakerUpdateManyWithWhereNestedInput>>,
};

export type SpeakerUpdateManyWithWhereNestedInput = {
  where: SpeakerScalarWhereInput,
  data: SpeakerUpdateManyDataInput,
};

export type SpeakerUpdateWithoutChannelDataInput = {
  style?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
};

export type SpeakerUpdateWithWhereUniqueNestedInput = {
  where: SpeakerWhereUniqueInput,
  data: SpeakerUpdateDataInput,
};

export type SpeakerUpdateWithWhereUniqueWithoutChannelInput = {
  where: SpeakerWhereUniqueInput,
  data: SpeakerUpdateWithoutChannelDataInput,
};

export type SpeakerUpsertWithWhereUniqueNestedInput = {
  where: SpeakerWhereUniqueInput,
  update: SpeakerUpdateDataInput,
  create: SpeakerCreateInput,
};

export type SpeakerUpsertWithWhereUniqueWithoutChannelInput = {
  where: SpeakerWhereUniqueInput,
  update: SpeakerUpdateWithoutChannelDataInput,
  create: SpeakerCreateWithoutChannelInput,
};

export type SpeakerWhereInput = {
  channel?: Maybe<TranslatedChannelWhereInput>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  style?: Maybe<Scalars['String']>,
  style_not?: Maybe<Scalars['String']>,
  style_in?: Maybe<Array<Scalars['String']>>,
  style_not_in?: Maybe<Array<Scalars['String']>>,
  style_lt?: Maybe<Scalars['String']>,
  style_lte?: Maybe<Scalars['String']>,
  style_gt?: Maybe<Scalars['String']>,
  style_gte?: Maybe<Scalars['String']>,
  style_contains?: Maybe<Scalars['String']>,
  style_not_contains?: Maybe<Scalars['String']>,
  style_starts_with?: Maybe<Scalars['String']>,
  style_not_starts_with?: Maybe<Scalars['String']>,
  style_ends_with?: Maybe<Scalars['String']>,
  style_not_ends_with?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  name_not?: Maybe<Scalars['String']>,
  name_in?: Maybe<Array<Scalars['String']>>,
  name_not_in?: Maybe<Array<Scalars['String']>>,
  name_lt?: Maybe<Scalars['String']>,
  name_lte?: Maybe<Scalars['String']>,
  name_gt?: Maybe<Scalars['String']>,
  name_gte?: Maybe<Scalars['String']>,
  name_contains?: Maybe<Scalars['String']>,
  name_not_contains?: Maybe<Scalars['String']>,
  name_starts_with?: Maybe<Scalars['String']>,
  name_not_starts_with?: Maybe<Scalars['String']>,
  name_ends_with?: Maybe<Scalars['String']>,
  name_not_ends_with?: Maybe<Scalars['String']>,
  papercupSpeakerId?: Maybe<Scalars['String']>,
  papercupSpeakerId_not?: Maybe<Scalars['String']>,
  papercupSpeakerId_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_not_in?: Maybe<Array<Scalars['String']>>,
  papercupSpeakerId_lt?: Maybe<Scalars['String']>,
  papercupSpeakerId_lte?: Maybe<Scalars['String']>,
  papercupSpeakerId_gt?: Maybe<Scalars['String']>,
  papercupSpeakerId_gte?: Maybe<Scalars['String']>,
  papercupSpeakerId_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_contains?: Maybe<Scalars['String']>,
  papercupSpeakerId_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_starts_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_ends_with?: Maybe<Scalars['String']>,
  papercupSpeakerId_not_ends_with?: Maybe<Scalars['String']>,
  AND?: Maybe<Array<SpeakerWhereInput>>,
  OR?: Maybe<Array<SpeakerWhereInput>>,
  NOT?: Maybe<Array<SpeakerWhereInput>>,
};

export type SpeakerWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type Subscription = {
   __typename?: 'Subscription',
  videoStateChange: SourceVideoSubscriptionPayload,
};


export type SubscriptionVideoStateChangeArgs = {
  id: Scalars['ID']
};

export type TranslatedChannel = {
   __typename?: 'TranslatedChannel',
  sourceChannel: SourceChannel,
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  ytChannelId?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  language: Scalars['String'],
  QAer?: Maybe<User>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays: Array<DaysOfWeek>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  speakers?: Maybe<Array<Speaker>>,
  translatedVideos?: Maybe<Array<TranslatedVideo>>,
  /** Channel Information from YouTube data API */
  ytChannel?: Maybe<YtChannel>,
};


export type TranslatedChannelSpeakersArgs = {
  where?: Maybe<SpeakerWhereInput>,
  orderBy?: Maybe<SpeakerOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type TranslatedChannelTranslatedVideosArgs = {
  where?: Maybe<TranslatedVideoWhereInput>,
  orderBy?: Maybe<TranslatedVideoOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type TranslatedChannelCreateInput = {
  sourceChannel: SourceChannelCreateOneWithoutTranslatedChannelsInput,
  id?: Maybe<Scalars['ID']>,
  ytChannelId?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  language: Scalars['String'],
  QAer?: Maybe<UserCreateOneInput>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays?: Maybe<TranslatedChannelCreatescheduleDaysInput>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  speakers?: Maybe<SpeakerCreateManyWithoutChannelInput>,
  translatedVideos?: Maybe<TranslatedVideoCreateManyWithoutChannelInput>,
};

export type TranslatedChannelCreateManyWithoutSourceChannelInput = {
  create?: Maybe<Array<TranslatedChannelCreateWithoutSourceChannelInput>>,
  connect?: Maybe<Array<TranslatedChannelWhereUniqueInput>>,
};

export type TranslatedChannelCreateOneWithoutSpeakersInput = {
  create?: Maybe<TranslatedChannelCreateWithoutSpeakersInput>,
  connect?: Maybe<TranslatedChannelWhereUniqueInput>,
};

export type TranslatedChannelCreateOneWithoutTranslatedVideosInput = {
  create?: Maybe<TranslatedChannelCreateWithoutTranslatedVideosInput>,
  connect?: Maybe<TranslatedChannelWhereUniqueInput>,
};

export type TranslatedChannelCreatescheduleDaysInput = {
  set?: Maybe<Array<DaysOfWeek>>,
};

export type TranslatedChannelCreateWithoutSourceChannelInput = {
  id?: Maybe<Scalars['ID']>,
  ytChannelId?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  language: Scalars['String'],
  QAer?: Maybe<UserCreateOneInput>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays?: Maybe<TranslatedChannelCreatescheduleDaysInput>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  speakers?: Maybe<SpeakerCreateManyWithoutChannelInput>,
  translatedVideos?: Maybe<TranslatedVideoCreateManyWithoutChannelInput>,
};

export type TranslatedChannelCreateWithoutSpeakersInput = {
  sourceChannel: SourceChannelCreateOneWithoutTranslatedChannelsInput,
  id?: Maybe<Scalars['ID']>,
  ytChannelId?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  language: Scalars['String'],
  QAer?: Maybe<UserCreateOneInput>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays?: Maybe<TranslatedChannelCreatescheduleDaysInput>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  translatedVideos?: Maybe<TranslatedVideoCreateManyWithoutChannelInput>,
};

export type TranslatedChannelCreateWithoutTranslatedVideosInput = {
  sourceChannel: SourceChannelCreateOneWithoutTranslatedChannelsInput,
  id?: Maybe<Scalars['ID']>,
  ytChannelId?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  language: Scalars['String'],
  QAer?: Maybe<UserCreateOneInput>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays?: Maybe<TranslatedChannelCreatescheduleDaysInput>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  speakers?: Maybe<SpeakerCreateManyWithoutChannelInput>,
};

export enum TranslatedChannelOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  YtChannelIdAsc = 'ytChannelId_ASC',
  YtChannelIdDesc = 'ytChannelId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  ScheduleUploadsAsc = 'scheduleUploads_ASC',
  ScheduleUploadsDesc = 'scheduleUploads_DESC',
  ScheduleFrequencyAsc = 'scheduleFrequency_ASC',
  ScheduleFrequencyDesc = 'scheduleFrequency_DESC',
  SchedulePublishingTimeAsc = 'schedulePublishingTime_ASC',
  SchedulePublishingTimeDesc = 'schedulePublishingTime_DESC'
}

export type TranslatedChannelScalarWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  ytChannelId?: Maybe<Scalars['String']>,
  ytChannelId_not?: Maybe<Scalars['String']>,
  ytChannelId_in?: Maybe<Array<Scalars['String']>>,
  ytChannelId_not_in?: Maybe<Array<Scalars['String']>>,
  ytChannelId_lt?: Maybe<Scalars['String']>,
  ytChannelId_lte?: Maybe<Scalars['String']>,
  ytChannelId_gt?: Maybe<Scalars['String']>,
  ytChannelId_gte?: Maybe<Scalars['String']>,
  ytChannelId_contains?: Maybe<Scalars['String']>,
  ytChannelId_not_contains?: Maybe<Scalars['String']>,
  ytChannelId_starts_with?: Maybe<Scalars['String']>,
  ytChannelId_not_starts_with?: Maybe<Scalars['String']>,
  ytChannelId_ends_with?: Maybe<Scalars['String']>,
  ytChannelId_not_ends_with?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  title_not?: Maybe<Scalars['String']>,
  title_in?: Maybe<Array<Scalars['String']>>,
  title_not_in?: Maybe<Array<Scalars['String']>>,
  title_lt?: Maybe<Scalars['String']>,
  title_lte?: Maybe<Scalars['String']>,
  title_gt?: Maybe<Scalars['String']>,
  title_gte?: Maybe<Scalars['String']>,
  title_contains?: Maybe<Scalars['String']>,
  title_not_contains?: Maybe<Scalars['String']>,
  title_starts_with?: Maybe<Scalars['String']>,
  title_not_starts_with?: Maybe<Scalars['String']>,
  title_ends_with?: Maybe<Scalars['String']>,
  title_not_ends_with?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  language_not?: Maybe<Scalars['String']>,
  language_in?: Maybe<Array<Scalars['String']>>,
  language_not_in?: Maybe<Array<Scalars['String']>>,
  language_lt?: Maybe<Scalars['String']>,
  language_lte?: Maybe<Scalars['String']>,
  language_gt?: Maybe<Scalars['String']>,
  language_gte?: Maybe<Scalars['String']>,
  language_contains?: Maybe<Scalars['String']>,
  language_not_contains?: Maybe<Scalars['String']>,
  language_starts_with?: Maybe<Scalars['String']>,
  language_not_starts_with?: Maybe<Scalars['String']>,
  language_ends_with?: Maybe<Scalars['String']>,
  language_not_ends_with?: Maybe<Scalars['String']>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleUploads_not?: Maybe<Scalars['Int']>,
  scheduleUploads_in?: Maybe<Array<Scalars['Int']>>,
  scheduleUploads_not_in?: Maybe<Array<Scalars['Int']>>,
  scheduleUploads_lt?: Maybe<Scalars['Int']>,
  scheduleUploads_lte?: Maybe<Scalars['Int']>,
  scheduleUploads_gt?: Maybe<Scalars['Int']>,
  scheduleUploads_gte?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleFrequency_not?: Maybe<Frequency>,
  scheduleFrequency_in?: Maybe<Array<Frequency>>,
  scheduleFrequency_not_in?: Maybe<Array<Frequency>>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  schedulePublishingTime_not?: Maybe<Scalars['DateTime']>,
  schedulePublishingTime_in?: Maybe<Array<Scalars['DateTime']>>,
  schedulePublishingTime_not_in?: Maybe<Array<Scalars['DateTime']>>,
  schedulePublishingTime_lt?: Maybe<Scalars['DateTime']>,
  schedulePublishingTime_lte?: Maybe<Scalars['DateTime']>,
  schedulePublishingTime_gt?: Maybe<Scalars['DateTime']>,
  schedulePublishingTime_gte?: Maybe<Scalars['DateTime']>,
  AND?: Maybe<Array<TranslatedChannelScalarWhereInput>>,
  OR?: Maybe<Array<TranslatedChannelScalarWhereInput>>,
  NOT?: Maybe<Array<TranslatedChannelScalarWhereInput>>,
};

export type TranslatedChannelUpdateDataInput = {
  sourceChannel?: Maybe<SourceChannelUpdateOneRequiredWithoutTranslatedChannelsInput>,
  ytChannelId?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  QAer?: Maybe<UserUpdateOneInput>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays?: Maybe<TranslatedChannelUpdatescheduleDaysInput>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  speakers?: Maybe<SpeakerUpdateManyWithoutChannelInput>,
  translatedVideos?: Maybe<TranslatedVideoUpdateManyWithoutChannelInput>,
};

export type TranslatedChannelUpdateInput = {
  sourceChannel?: Maybe<SourceChannelUpdateOneRequiredWithoutTranslatedChannelsInput>,
  ytChannelId?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  QAer?: Maybe<UserUpdateOneInput>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays?: Maybe<TranslatedChannelUpdatescheduleDaysInput>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  speakers?: Maybe<SpeakerUpdateManyWithoutChannelInput>,
  translatedVideos?: Maybe<TranslatedVideoUpdateManyWithoutChannelInput>,
};

export type TranslatedChannelUpdateManyDataInput = {
  ytChannelId?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays?: Maybe<TranslatedChannelUpdatescheduleDaysInput>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
};

export type TranslatedChannelUpdateManyWithoutSourceChannelInput = {
  create?: Maybe<Array<TranslatedChannelCreateWithoutSourceChannelInput>>,
  delete?: Maybe<Array<TranslatedChannelWhereUniqueInput>>,
  connect?: Maybe<Array<TranslatedChannelWhereUniqueInput>>,
  set?: Maybe<Array<TranslatedChannelWhereUniqueInput>>,
  disconnect?: Maybe<Array<TranslatedChannelWhereUniqueInput>>,
  update?: Maybe<Array<TranslatedChannelUpdateWithWhereUniqueWithoutSourceChannelInput>>,
  upsert?: Maybe<Array<TranslatedChannelUpsertWithWhereUniqueWithoutSourceChannelInput>>,
  deleteMany?: Maybe<Array<TranslatedChannelScalarWhereInput>>,
  updateMany?: Maybe<Array<TranslatedChannelUpdateManyWithWhereNestedInput>>,
};

export type TranslatedChannelUpdateManyWithWhereNestedInput = {
  where: TranslatedChannelScalarWhereInput,
  data: TranslatedChannelUpdateManyDataInput,
};

export type TranslatedChannelUpdateOneInput = {
  create?: Maybe<TranslatedChannelCreateInput>,
  update?: Maybe<TranslatedChannelUpdateDataInput>,
  upsert?: Maybe<TranslatedChannelUpsertNestedInput>,
  delete?: Maybe<Scalars['Boolean']>,
  disconnect?: Maybe<Scalars['Boolean']>,
  connect?: Maybe<TranslatedChannelWhereUniqueInput>,
};

export type TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput = {
  create?: Maybe<TranslatedChannelCreateWithoutTranslatedVideosInput>,
  update?: Maybe<TranslatedChannelUpdateWithoutTranslatedVideosDataInput>,
  upsert?: Maybe<TranslatedChannelUpsertWithoutTranslatedVideosInput>,
  connect?: Maybe<TranslatedChannelWhereUniqueInput>,
};

export type TranslatedChannelUpdateOneWithoutSpeakersInput = {
  create?: Maybe<TranslatedChannelCreateWithoutSpeakersInput>,
  update?: Maybe<TranslatedChannelUpdateWithoutSpeakersDataInput>,
  upsert?: Maybe<TranslatedChannelUpsertWithoutSpeakersInput>,
  delete?: Maybe<Scalars['Boolean']>,
  disconnect?: Maybe<Scalars['Boolean']>,
  connect?: Maybe<TranslatedChannelWhereUniqueInput>,
};

export type TranslatedChannelUpdatescheduleDaysInput = {
  set?: Maybe<Array<DaysOfWeek>>,
};

export type TranslatedChannelUpdateWithoutSourceChannelDataInput = {
  ytChannelId?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  QAer?: Maybe<UserUpdateOneInput>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays?: Maybe<TranslatedChannelUpdatescheduleDaysInput>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  speakers?: Maybe<SpeakerUpdateManyWithoutChannelInput>,
  translatedVideos?: Maybe<TranslatedVideoUpdateManyWithoutChannelInput>,
};

export type TranslatedChannelUpdateWithoutSpeakersDataInput = {
  sourceChannel?: Maybe<SourceChannelUpdateOneRequiredWithoutTranslatedChannelsInput>,
  ytChannelId?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  QAer?: Maybe<UserUpdateOneInput>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays?: Maybe<TranslatedChannelUpdatescheduleDaysInput>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  translatedVideos?: Maybe<TranslatedVideoUpdateManyWithoutChannelInput>,
};

export type TranslatedChannelUpdateWithoutTranslatedVideosDataInput = {
  sourceChannel?: Maybe<SourceChannelUpdateOneRequiredWithoutTranslatedChannelsInput>,
  ytChannelId?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  QAer?: Maybe<UserUpdateOneInput>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleDays?: Maybe<TranslatedChannelUpdatescheduleDaysInput>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  speakers?: Maybe<SpeakerUpdateManyWithoutChannelInput>,
};

export type TranslatedChannelUpdateWithWhereUniqueWithoutSourceChannelInput = {
  where: TranslatedChannelWhereUniqueInput,
  data: TranslatedChannelUpdateWithoutSourceChannelDataInput,
};

export type TranslatedChannelUpsertNestedInput = {
  update: TranslatedChannelUpdateDataInput,
  create: TranslatedChannelCreateInput,
};

export type TranslatedChannelUpsertWithoutSpeakersInput = {
  update: TranslatedChannelUpdateWithoutSpeakersDataInput,
  create: TranslatedChannelCreateWithoutSpeakersInput,
};

export type TranslatedChannelUpsertWithoutTranslatedVideosInput = {
  update: TranslatedChannelUpdateWithoutTranslatedVideosDataInput,
  create: TranslatedChannelCreateWithoutTranslatedVideosInput,
};

export type TranslatedChannelUpsertWithWhereUniqueWithoutSourceChannelInput = {
  where: TranslatedChannelWhereUniqueInput,
  update: TranslatedChannelUpdateWithoutSourceChannelDataInput,
  create: TranslatedChannelCreateWithoutSourceChannelInput,
};

export type TranslatedChannelWhereInput = {
  sourceChannel?: Maybe<SourceChannelWhereInput>,
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  ytChannelId?: Maybe<Scalars['String']>,
  ytChannelId_not?: Maybe<Scalars['String']>,
  ytChannelId_in?: Maybe<Array<Scalars['String']>>,
  ytChannelId_not_in?: Maybe<Array<Scalars['String']>>,
  ytChannelId_lt?: Maybe<Scalars['String']>,
  ytChannelId_lte?: Maybe<Scalars['String']>,
  ytChannelId_gt?: Maybe<Scalars['String']>,
  ytChannelId_gte?: Maybe<Scalars['String']>,
  ytChannelId_contains?: Maybe<Scalars['String']>,
  ytChannelId_not_contains?: Maybe<Scalars['String']>,
  ytChannelId_starts_with?: Maybe<Scalars['String']>,
  ytChannelId_not_starts_with?: Maybe<Scalars['String']>,
  ytChannelId_ends_with?: Maybe<Scalars['String']>,
  ytChannelId_not_ends_with?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  title_not?: Maybe<Scalars['String']>,
  title_in?: Maybe<Array<Scalars['String']>>,
  title_not_in?: Maybe<Array<Scalars['String']>>,
  title_lt?: Maybe<Scalars['String']>,
  title_lte?: Maybe<Scalars['String']>,
  title_gt?: Maybe<Scalars['String']>,
  title_gte?: Maybe<Scalars['String']>,
  title_contains?: Maybe<Scalars['String']>,
  title_not_contains?: Maybe<Scalars['String']>,
  title_starts_with?: Maybe<Scalars['String']>,
  title_not_starts_with?: Maybe<Scalars['String']>,
  title_ends_with?: Maybe<Scalars['String']>,
  title_not_ends_with?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  language_not?: Maybe<Scalars['String']>,
  language_in?: Maybe<Array<Scalars['String']>>,
  language_not_in?: Maybe<Array<Scalars['String']>>,
  language_lt?: Maybe<Scalars['String']>,
  language_lte?: Maybe<Scalars['String']>,
  language_gt?: Maybe<Scalars['String']>,
  language_gte?: Maybe<Scalars['String']>,
  language_contains?: Maybe<Scalars['String']>,
  language_not_contains?: Maybe<Scalars['String']>,
  language_starts_with?: Maybe<Scalars['String']>,
  language_not_starts_with?: Maybe<Scalars['String']>,
  language_ends_with?: Maybe<Scalars['String']>,
  language_not_ends_with?: Maybe<Scalars['String']>,
  QAer?: Maybe<UserWhereInput>,
  scheduleUploads?: Maybe<Scalars['Int']>,
  scheduleUploads_not?: Maybe<Scalars['Int']>,
  scheduleUploads_in?: Maybe<Array<Scalars['Int']>>,
  scheduleUploads_not_in?: Maybe<Array<Scalars['Int']>>,
  scheduleUploads_lt?: Maybe<Scalars['Int']>,
  scheduleUploads_lte?: Maybe<Scalars['Int']>,
  scheduleUploads_gt?: Maybe<Scalars['Int']>,
  scheduleUploads_gte?: Maybe<Scalars['Int']>,
  scheduleFrequency?: Maybe<Frequency>,
  scheduleFrequency_not?: Maybe<Frequency>,
  scheduleFrequency_in?: Maybe<Array<Frequency>>,
  scheduleFrequency_not_in?: Maybe<Array<Frequency>>,
  schedulePublishingTime?: Maybe<Scalars['DateTime']>,
  schedulePublishingTime_not?: Maybe<Scalars['DateTime']>,
  schedulePublishingTime_in?: Maybe<Array<Scalars['DateTime']>>,
  schedulePublishingTime_not_in?: Maybe<Array<Scalars['DateTime']>>,
  schedulePublishingTime_lt?: Maybe<Scalars['DateTime']>,
  schedulePublishingTime_lte?: Maybe<Scalars['DateTime']>,
  schedulePublishingTime_gt?: Maybe<Scalars['DateTime']>,
  schedulePublishingTime_gte?: Maybe<Scalars['DateTime']>,
  speakers_every?: Maybe<SpeakerWhereInput>,
  speakers_some?: Maybe<SpeakerWhereInput>,
  speakers_none?: Maybe<SpeakerWhereInput>,
  translatedVideos_every?: Maybe<TranslatedVideoWhereInput>,
  translatedVideos_some?: Maybe<TranslatedVideoWhereInput>,
  translatedVideos_none?: Maybe<TranslatedVideoWhereInput>,
  AND?: Maybe<Array<TranslatedChannelWhereInput>>,
  OR?: Maybe<Array<TranslatedChannelWhereInput>>,
  NOT?: Maybe<Array<TranslatedChannelWhereInput>>,
};

export type TranslatedChannelWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export type TranslatedVideo = {
   __typename?: 'TranslatedVideo',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  title?: Maybe<Scalars['String']>,
  channel: TranslatedChannel,
  sourceVideo: SourceVideo,
  state: Scalars['Json'],
  stateValue: TranslatedVideoState,
  qaer?: Maybe<User>,
  asrModel: Scalars['String'],
  s3root: Scalars['String'],
  dueDate?: Maybe<Scalars['DateTime']>,
  segments?: Maybe<Array<Segment>>,
  outputGroups?: Maybe<Array<OutputGroup>>,
  /** Video Information from YouTube data API */
  ytVideo?: Maybe<YtVideo>,
  /** The most recent OutputGroup generated for this video */
  latestOutputGroup?: Maybe<OutputGroup>,
};


export type TranslatedVideoSegmentsArgs = {
  where?: Maybe<SegmentWhereInput>,
  orderBy?: Maybe<SegmentOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type TranslatedVideoOutputGroupsArgs = {
  where?: Maybe<OutputGroupWhereInput>,
  orderBy?: Maybe<OutputGroupOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type TranslatedVideoCreateInput = {
  id?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput,
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserCreateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root: Scalars['String'],
  dueDate?: Maybe<Scalars['DateTime']>,
  segments?: Maybe<SegmentCreateManyWithoutVideoInput>,
  outputGroups?: Maybe<OutputGroupCreateManyWithoutTranslatedVideoInput>,
};

export type TranslatedVideoCreateManyWithoutChannelInput = {
  create?: Maybe<Array<TranslatedVideoCreateWithoutChannelInput>>,
  connect?: Maybe<Array<TranslatedVideoWhereUniqueInput>>,
};

export type TranslatedVideoCreateManyWithoutSourceVideoInput = {
  create?: Maybe<Array<TranslatedVideoCreateWithoutSourceVideoInput>>,
  connect?: Maybe<Array<TranslatedVideoWhereUniqueInput>>,
};

export type TranslatedVideoCreateOneInput = {
  create?: Maybe<TranslatedVideoCreateInput>,
  connect?: Maybe<TranslatedVideoWhereUniqueInput>,
};

export type TranslatedVideoCreateOneWithoutOutputGroupsInput = {
  create?: Maybe<TranslatedVideoCreateWithoutOutputGroupsInput>,
  connect?: Maybe<TranslatedVideoWhereUniqueInput>,
};

export type TranslatedVideoCreateOneWithoutSegmentsInput = {
  create?: Maybe<TranslatedVideoCreateWithoutSegmentsInput>,
  connect?: Maybe<TranslatedVideoWhereUniqueInput>,
};

export type TranslatedVideoCreateWithoutChannelInput = {
  id?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserCreateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root: Scalars['String'],
  dueDate?: Maybe<Scalars['DateTime']>,
  segments?: Maybe<SegmentCreateManyWithoutVideoInput>,
  outputGroups?: Maybe<OutputGroupCreateManyWithoutTranslatedVideoInput>,
};

export type TranslatedVideoCreateWithoutOutputGroupsInput = {
  id?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput,
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserCreateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root: Scalars['String'],
  dueDate?: Maybe<Scalars['DateTime']>,
  segments?: Maybe<SegmentCreateManyWithoutVideoInput>,
};

export type TranslatedVideoCreateWithoutSegmentsInput = {
  id?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput,
  sourceVideo: SourceVideoCreateOneWithoutTranslatedVideosInput,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserCreateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root: Scalars['String'],
  dueDate?: Maybe<Scalars['DateTime']>,
  outputGroups?: Maybe<OutputGroupCreateManyWithoutTranslatedVideoInput>,
};

export type TranslatedVideoCreateWithoutSourceVideoInput = {
  id?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  channel: TranslatedChannelCreateOneWithoutTranslatedVideosInput,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserCreateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root: Scalars['String'],
  dueDate?: Maybe<Scalars['DateTime']>,
  segments?: Maybe<SegmentCreateManyWithoutVideoInput>,
  outputGroups?: Maybe<OutputGroupCreateManyWithoutTranslatedVideoInput>,
};

export enum TranslatedVideoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  AsrModelAsc = 'asrModel_ASC',
  AsrModelDesc = 'asrModel_DESC',
  S3rootAsc = 's3root_ASC',
  S3rootDesc = 's3root_DESC',
  DueDateAsc = 'dueDate_ASC',
  DueDateDesc = 'dueDate_DESC'
}

export type TranslatedVideoScalarWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  title?: Maybe<Scalars['String']>,
  title_not?: Maybe<Scalars['String']>,
  title_in?: Maybe<Array<Scalars['String']>>,
  title_not_in?: Maybe<Array<Scalars['String']>>,
  title_lt?: Maybe<Scalars['String']>,
  title_lte?: Maybe<Scalars['String']>,
  title_gt?: Maybe<Scalars['String']>,
  title_gte?: Maybe<Scalars['String']>,
  title_contains?: Maybe<Scalars['String']>,
  title_not_contains?: Maybe<Scalars['String']>,
  title_starts_with?: Maybe<Scalars['String']>,
  title_not_starts_with?: Maybe<Scalars['String']>,
  title_ends_with?: Maybe<Scalars['String']>,
  title_not_ends_with?: Maybe<Scalars['String']>,
  stateValue?: Maybe<TranslatedVideoState>,
  stateValue_not?: Maybe<TranslatedVideoState>,
  stateValue_in?: Maybe<Array<TranslatedVideoState>>,
  stateValue_not_in?: Maybe<Array<TranslatedVideoState>>,
  asrModel?: Maybe<Scalars['String']>,
  asrModel_not?: Maybe<Scalars['String']>,
  asrModel_in?: Maybe<Array<Scalars['String']>>,
  asrModel_not_in?: Maybe<Array<Scalars['String']>>,
  asrModel_lt?: Maybe<Scalars['String']>,
  asrModel_lte?: Maybe<Scalars['String']>,
  asrModel_gt?: Maybe<Scalars['String']>,
  asrModel_gte?: Maybe<Scalars['String']>,
  asrModel_contains?: Maybe<Scalars['String']>,
  asrModel_not_contains?: Maybe<Scalars['String']>,
  asrModel_starts_with?: Maybe<Scalars['String']>,
  asrModel_not_starts_with?: Maybe<Scalars['String']>,
  asrModel_ends_with?: Maybe<Scalars['String']>,
  asrModel_not_ends_with?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  s3root_not?: Maybe<Scalars['String']>,
  s3root_in?: Maybe<Array<Scalars['String']>>,
  s3root_not_in?: Maybe<Array<Scalars['String']>>,
  s3root_lt?: Maybe<Scalars['String']>,
  s3root_lte?: Maybe<Scalars['String']>,
  s3root_gt?: Maybe<Scalars['String']>,
  s3root_gte?: Maybe<Scalars['String']>,
  s3root_contains?: Maybe<Scalars['String']>,
  s3root_not_contains?: Maybe<Scalars['String']>,
  s3root_starts_with?: Maybe<Scalars['String']>,
  s3root_not_starts_with?: Maybe<Scalars['String']>,
  s3root_ends_with?: Maybe<Scalars['String']>,
  s3root_not_ends_with?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  dueDate_not?: Maybe<Scalars['DateTime']>,
  dueDate_in?: Maybe<Array<Scalars['DateTime']>>,
  dueDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  dueDate_lt?: Maybe<Scalars['DateTime']>,
  dueDate_lte?: Maybe<Scalars['DateTime']>,
  dueDate_gt?: Maybe<Scalars['DateTime']>,
  dueDate_gte?: Maybe<Scalars['DateTime']>,
  AND?: Maybe<Array<TranslatedVideoScalarWhereInput>>,
  OR?: Maybe<Array<TranslatedVideoScalarWhereInput>>,
  NOT?: Maybe<Array<TranslatedVideoScalarWhereInput>>,
};

export enum TranslatedVideoState {
  Initial = 'Initial',
  PreparingSegments = 'PreparingSegments',
  ReadyToQa = 'ReadyToQA',
  InProgressQa = 'InProgressQA',
  GeneratingOutput = 'GeneratingOutput',
  Complete = 'Complete'
}

export type TranslatedVideoUpdateDataInput = {
  title?: Maybe<Scalars['String']>,
  channel?: Maybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>,
  sourceVideo?: Maybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserUpdateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  segments?: Maybe<SegmentUpdateManyWithoutVideoInput>,
  outputGroups?: Maybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>,
};

export type TranslatedVideoUpdateInput = {
  title?: Maybe<Scalars['String']>,
  channel?: Maybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>,
  sourceVideo?: Maybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserUpdateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  segments?: Maybe<SegmentUpdateManyWithoutVideoInput>,
  outputGroups?: Maybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>,
};

export type TranslatedVideoUpdateManyDataInput = {
  title?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  asrModel?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
};

export type TranslatedVideoUpdateManyWithoutChannelInput = {
  create?: Maybe<Array<TranslatedVideoCreateWithoutChannelInput>>,
  delete?: Maybe<Array<TranslatedVideoWhereUniqueInput>>,
  connect?: Maybe<Array<TranslatedVideoWhereUniqueInput>>,
  set?: Maybe<Array<TranslatedVideoWhereUniqueInput>>,
  disconnect?: Maybe<Array<TranslatedVideoWhereUniqueInput>>,
  update?: Maybe<Array<TranslatedVideoUpdateWithWhereUniqueWithoutChannelInput>>,
  upsert?: Maybe<Array<TranslatedVideoUpsertWithWhereUniqueWithoutChannelInput>>,
  deleteMany?: Maybe<Array<TranslatedVideoScalarWhereInput>>,
  updateMany?: Maybe<Array<TranslatedVideoUpdateManyWithWhereNestedInput>>,
};

export type TranslatedVideoUpdateManyWithoutSourceVideoInput = {
  create?: Maybe<Array<TranslatedVideoCreateWithoutSourceVideoInput>>,
  delete?: Maybe<Array<TranslatedVideoWhereUniqueInput>>,
  connect?: Maybe<Array<TranslatedVideoWhereUniqueInput>>,
  set?: Maybe<Array<TranslatedVideoWhereUniqueInput>>,
  disconnect?: Maybe<Array<TranslatedVideoWhereUniqueInput>>,
  update?: Maybe<Array<TranslatedVideoUpdateWithWhereUniqueWithoutSourceVideoInput>>,
  upsert?: Maybe<Array<TranslatedVideoUpsertWithWhereUniqueWithoutSourceVideoInput>>,
  deleteMany?: Maybe<Array<TranslatedVideoScalarWhereInput>>,
  updateMany?: Maybe<Array<TranslatedVideoUpdateManyWithWhereNestedInput>>,
};

export type TranslatedVideoUpdateManyWithWhereNestedInput = {
  where: TranslatedVideoScalarWhereInput,
  data: TranslatedVideoUpdateManyDataInput,
};

export type TranslatedVideoUpdateOneInput = {
  create?: Maybe<TranslatedVideoCreateInput>,
  update?: Maybe<TranslatedVideoUpdateDataInput>,
  upsert?: Maybe<TranslatedVideoUpsertNestedInput>,
  delete?: Maybe<Scalars['Boolean']>,
  disconnect?: Maybe<Scalars['Boolean']>,
  connect?: Maybe<TranslatedVideoWhereUniqueInput>,
};

export type TranslatedVideoUpdateOneRequiredWithoutOutputGroupsInput = {
  create?: Maybe<TranslatedVideoCreateWithoutOutputGroupsInput>,
  update?: Maybe<TranslatedVideoUpdateWithoutOutputGroupsDataInput>,
  upsert?: Maybe<TranslatedVideoUpsertWithoutOutputGroupsInput>,
  connect?: Maybe<TranslatedVideoWhereUniqueInput>,
};

export type TranslatedVideoUpdateOneRequiredWithoutSegmentsInput = {
  create?: Maybe<TranslatedVideoCreateWithoutSegmentsInput>,
  update?: Maybe<TranslatedVideoUpdateWithoutSegmentsDataInput>,
  upsert?: Maybe<TranslatedVideoUpsertWithoutSegmentsInput>,
  connect?: Maybe<TranslatedVideoWhereUniqueInput>,
};

export type TranslatedVideoUpdateWithoutChannelDataInput = {
  title?: Maybe<Scalars['String']>,
  sourceVideo?: Maybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserUpdateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  segments?: Maybe<SegmentUpdateManyWithoutVideoInput>,
  outputGroups?: Maybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>,
};

export type TranslatedVideoUpdateWithoutOutputGroupsDataInput = {
  title?: Maybe<Scalars['String']>,
  channel?: Maybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>,
  sourceVideo?: Maybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserUpdateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  segments?: Maybe<SegmentUpdateManyWithoutVideoInput>,
};

export type TranslatedVideoUpdateWithoutSegmentsDataInput = {
  title?: Maybe<Scalars['String']>,
  channel?: Maybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>,
  sourceVideo?: Maybe<SourceVideoUpdateOneRequiredWithoutTranslatedVideosInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserUpdateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  outputGroups?: Maybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>,
};

export type TranslatedVideoUpdateWithoutSourceVideoDataInput = {
  title?: Maybe<Scalars['String']>,
  channel?: Maybe<TranslatedChannelUpdateOneRequiredWithoutTranslatedVideosInput>,
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<TranslatedVideoState>,
  qaer?: Maybe<UserUpdateOneInput>,
  asrModel?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  segments?: Maybe<SegmentUpdateManyWithoutVideoInput>,
  outputGroups?: Maybe<OutputGroupUpdateManyWithoutTranslatedVideoInput>,
};

export type TranslatedVideoUpdateWithWhereUniqueWithoutChannelInput = {
  where: TranslatedVideoWhereUniqueInput,
  data: TranslatedVideoUpdateWithoutChannelDataInput,
};

export type TranslatedVideoUpdateWithWhereUniqueWithoutSourceVideoInput = {
  where: TranslatedVideoWhereUniqueInput,
  data: TranslatedVideoUpdateWithoutSourceVideoDataInput,
};

export type TranslatedVideoUpsertNestedInput = {
  update: TranslatedVideoUpdateDataInput,
  create: TranslatedVideoCreateInput,
};

export type TranslatedVideoUpsertWithoutOutputGroupsInput = {
  update: TranslatedVideoUpdateWithoutOutputGroupsDataInput,
  create: TranslatedVideoCreateWithoutOutputGroupsInput,
};

export type TranslatedVideoUpsertWithoutSegmentsInput = {
  update: TranslatedVideoUpdateWithoutSegmentsDataInput,
  create: TranslatedVideoCreateWithoutSegmentsInput,
};

export type TranslatedVideoUpsertWithWhereUniqueWithoutChannelInput = {
  where: TranslatedVideoWhereUniqueInput,
  update: TranslatedVideoUpdateWithoutChannelDataInput,
  create: TranslatedVideoCreateWithoutChannelInput,
};

export type TranslatedVideoUpsertWithWhereUniqueWithoutSourceVideoInput = {
  where: TranslatedVideoWhereUniqueInput,
  update: TranslatedVideoUpdateWithoutSourceVideoDataInput,
  create: TranslatedVideoCreateWithoutSourceVideoInput,
};

export type TranslatedVideoWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  title?: Maybe<Scalars['String']>,
  title_not?: Maybe<Scalars['String']>,
  title_in?: Maybe<Array<Scalars['String']>>,
  title_not_in?: Maybe<Array<Scalars['String']>>,
  title_lt?: Maybe<Scalars['String']>,
  title_lte?: Maybe<Scalars['String']>,
  title_gt?: Maybe<Scalars['String']>,
  title_gte?: Maybe<Scalars['String']>,
  title_contains?: Maybe<Scalars['String']>,
  title_not_contains?: Maybe<Scalars['String']>,
  title_starts_with?: Maybe<Scalars['String']>,
  title_not_starts_with?: Maybe<Scalars['String']>,
  title_ends_with?: Maybe<Scalars['String']>,
  title_not_ends_with?: Maybe<Scalars['String']>,
  channel?: Maybe<TranslatedChannelWhereInput>,
  sourceVideo?: Maybe<SourceVideoWhereInput>,
  stateValue?: Maybe<TranslatedVideoState>,
  stateValue_not?: Maybe<TranslatedVideoState>,
  stateValue_in?: Maybe<Array<TranslatedVideoState>>,
  stateValue_not_in?: Maybe<Array<TranslatedVideoState>>,
  qaer?: Maybe<UserWhereInput>,
  asrModel?: Maybe<Scalars['String']>,
  asrModel_not?: Maybe<Scalars['String']>,
  asrModel_in?: Maybe<Array<Scalars['String']>>,
  asrModel_not_in?: Maybe<Array<Scalars['String']>>,
  asrModel_lt?: Maybe<Scalars['String']>,
  asrModel_lte?: Maybe<Scalars['String']>,
  asrModel_gt?: Maybe<Scalars['String']>,
  asrModel_gte?: Maybe<Scalars['String']>,
  asrModel_contains?: Maybe<Scalars['String']>,
  asrModel_not_contains?: Maybe<Scalars['String']>,
  asrModel_starts_with?: Maybe<Scalars['String']>,
  asrModel_not_starts_with?: Maybe<Scalars['String']>,
  asrModel_ends_with?: Maybe<Scalars['String']>,
  asrModel_not_ends_with?: Maybe<Scalars['String']>,
  s3root?: Maybe<Scalars['String']>,
  s3root_not?: Maybe<Scalars['String']>,
  s3root_in?: Maybe<Array<Scalars['String']>>,
  s3root_not_in?: Maybe<Array<Scalars['String']>>,
  s3root_lt?: Maybe<Scalars['String']>,
  s3root_lte?: Maybe<Scalars['String']>,
  s3root_gt?: Maybe<Scalars['String']>,
  s3root_gte?: Maybe<Scalars['String']>,
  s3root_contains?: Maybe<Scalars['String']>,
  s3root_not_contains?: Maybe<Scalars['String']>,
  s3root_starts_with?: Maybe<Scalars['String']>,
  s3root_not_starts_with?: Maybe<Scalars['String']>,
  s3root_ends_with?: Maybe<Scalars['String']>,
  s3root_not_ends_with?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  dueDate_not?: Maybe<Scalars['DateTime']>,
  dueDate_in?: Maybe<Array<Scalars['DateTime']>>,
  dueDate_not_in?: Maybe<Array<Scalars['DateTime']>>,
  dueDate_lt?: Maybe<Scalars['DateTime']>,
  dueDate_lte?: Maybe<Scalars['DateTime']>,
  dueDate_gt?: Maybe<Scalars['DateTime']>,
  dueDate_gte?: Maybe<Scalars['DateTime']>,
  segments_every?: Maybe<SegmentWhereInput>,
  segments_some?: Maybe<SegmentWhereInput>,
  segments_none?: Maybe<SegmentWhereInput>,
  outputGroups_every?: Maybe<OutputGroupWhereInput>,
  outputGroups_some?: Maybe<OutputGroupWhereInput>,
  outputGroups_none?: Maybe<OutputGroupWhereInput>,
  AND?: Maybe<Array<TranslatedVideoWhereInput>>,
  OR?: Maybe<Array<TranslatedVideoWhereInput>>,
  NOT?: Maybe<Array<TranslatedVideoWhereInput>>,
};

export type TranslatedVideoWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

export enum TypicalVideoDuration {
  Long = 'Long',
  Medium = 'Medium',
  Short = 'Short'
}

export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  cognitoSub: Scalars['String'],
  cognitoUsername: Scalars['String'],
  isArchived?: Maybe<Scalars['Boolean']>,
  userRole: UserRole,
  /** Email address from Cognito User Pool */
  email?: Maybe<Scalars['String']>,
  /** Name from Cognito User Pool */
  name?: Maybe<Scalars['String']>,
};

export type UserCreateInput = {
  id?: Maybe<Scalars['ID']>,
  cognitoSub: Scalars['String'],
  cognitoUsername: Scalars['String'],
  isArchived?: Maybe<Scalars['Boolean']>,
  userRole: UserRole,
};

export type UserCreateOneInput = {
  create?: Maybe<UserCreateInput>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export enum UserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CognitoSubAsc = 'cognitoSub_ASC',
  CognitoSubDesc = 'cognitoSub_DESC',
  CognitoUsernameAsc = 'cognitoUsername_ASC',
  CognitoUsernameDesc = 'cognitoUsername_DESC',
  IsArchivedAsc = 'isArchived_ASC',
  IsArchivedDesc = 'isArchived_DESC',
  UserRoleAsc = 'userRole_ASC',
  UserRoleDesc = 'userRole_DESC'
}

export enum UserRole {
  Unknown = 'Unknown',
  Admin = 'Admin',
  SuperUser = 'SuperUser',
  QAer = 'QAer',
  ChannelOwner = 'ChannelOwner',
  ChannelManager = 'ChannelManager'
}

export type UserUpdateDataInput = {
  cognitoSub?: Maybe<Scalars['String']>,
  cognitoUsername?: Maybe<Scalars['String']>,
  isArchived?: Maybe<Scalars['Boolean']>,
  userRole?: Maybe<UserRole>,
};

export type UserUpdateOneInput = {
  create?: Maybe<UserCreateInput>,
  update?: Maybe<UserUpdateDataInput>,
  upsert?: Maybe<UserUpsertNestedInput>,
  delete?: Maybe<Scalars['Boolean']>,
  disconnect?: Maybe<Scalars['Boolean']>,
  connect?: Maybe<UserWhereUniqueInput>,
};

export type UserUpsertNestedInput = {
  update: UserUpdateDataInput,
  create: UserCreateInput,
};

export type UserWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  cognitoSub?: Maybe<Scalars['String']>,
  cognitoSub_not?: Maybe<Scalars['String']>,
  cognitoSub_in?: Maybe<Array<Scalars['String']>>,
  cognitoSub_not_in?: Maybe<Array<Scalars['String']>>,
  cognitoSub_lt?: Maybe<Scalars['String']>,
  cognitoSub_lte?: Maybe<Scalars['String']>,
  cognitoSub_gt?: Maybe<Scalars['String']>,
  cognitoSub_gte?: Maybe<Scalars['String']>,
  cognitoSub_contains?: Maybe<Scalars['String']>,
  cognitoSub_not_contains?: Maybe<Scalars['String']>,
  cognitoSub_starts_with?: Maybe<Scalars['String']>,
  cognitoSub_not_starts_with?: Maybe<Scalars['String']>,
  cognitoSub_ends_with?: Maybe<Scalars['String']>,
  cognitoSub_not_ends_with?: Maybe<Scalars['String']>,
  cognitoUsername?: Maybe<Scalars['String']>,
  cognitoUsername_not?: Maybe<Scalars['String']>,
  cognitoUsername_in?: Maybe<Array<Scalars['String']>>,
  cognitoUsername_not_in?: Maybe<Array<Scalars['String']>>,
  cognitoUsername_lt?: Maybe<Scalars['String']>,
  cognitoUsername_lte?: Maybe<Scalars['String']>,
  cognitoUsername_gt?: Maybe<Scalars['String']>,
  cognitoUsername_gte?: Maybe<Scalars['String']>,
  cognitoUsername_contains?: Maybe<Scalars['String']>,
  cognitoUsername_not_contains?: Maybe<Scalars['String']>,
  cognitoUsername_starts_with?: Maybe<Scalars['String']>,
  cognitoUsername_not_starts_with?: Maybe<Scalars['String']>,
  cognitoUsername_ends_with?: Maybe<Scalars['String']>,
  cognitoUsername_not_ends_with?: Maybe<Scalars['String']>,
  isArchived?: Maybe<Scalars['Boolean']>,
  isArchived_not?: Maybe<Scalars['Boolean']>,
  userRole?: Maybe<UserRole>,
  userRole_not?: Maybe<UserRole>,
  userRole_in?: Maybe<Array<UserRole>>,
  userRole_not_in?: Maybe<Array<UserRole>>,
  AND?: Maybe<Array<UserWhereInput>>,
  OR?: Maybe<Array<UserWhereInput>>,
  NOT?: Maybe<Array<UserWhereInput>>,
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
  cognitoSub?: Maybe<Scalars['String']>,
};

export enum VocoderType {
  PreviewVoice = 'PreviewVoice',
  TunedVoice = 'TunedVoice'
}

export type Voice = {
   __typename?: 'Voice',
  id: Scalars['ID'],
  speakerId: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  state: Scalars['Json'],
  stateValue: VoiceState,
  text: Scalars['String'],
  samples?: Maybe<Array<Sample>>,
  phonemizedText: Array<Scalars['Int']>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  language: Scalars['String'],
  gender: Scalars['String'],
  age?: Maybe<Scalars['Int']>,
  styles: Array<Scalars['String']>,
};


export type VoiceSamplesArgs = {
  where?: Maybe<SampleWhereInput>,
  orderBy?: Maybe<SampleOrderByInput>,
  skip?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type VoiceCreateInput = {
  id?: Maybe<Scalars['ID']>,
  speakerId: Scalars['String'],
  state?: Maybe<Scalars['Json']>,
  stateValue?: Maybe<VoiceState>,
  text: Scalars['String'],
  samples?: Maybe<SampleCreateManyInput>,
  phonemizedText?: Maybe<VoiceCreatephonemizedTextInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  language: Scalars['String'],
  gender: Scalars['String'],
  age?: Maybe<Scalars['Int']>,
  styles?: Maybe<VoiceCreatestylesInput>,
};

export type VoiceCreatephonemizedTextInput = {
  set?: Maybe<Array<Scalars['Int']>>,
};

export type VoiceCreatestylesInput = {
  set?: Maybe<Array<Scalars['String']>>,
};

export enum VoiceOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SpeakerIdAsc = 'speakerId_ASC',
  SpeakerIdDesc = 'speakerId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StateValueAsc = 'stateValue_ASC',
  StateValueDesc = 'stateValue_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  PhonemizationVersionAsc = 'phonemizationVersion_ASC',
  PhonemizationVersionDesc = 'phonemizationVersion_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  GenderAsc = 'gender_ASC',
  GenderDesc = 'gender_DESC',
  AgeAsc = 'age_ASC',
  AgeDesc = 'age_DESC'
}

export enum VoiceState {
  Initial = 'Initial',
  GeneratingPhones = 'GeneratingPhones',
  Complete = 'Complete'
}

export type VoiceWhereInput = {
  id?: Maybe<Scalars['ID']>,
  id_not?: Maybe<Scalars['ID']>,
  id_in?: Maybe<Array<Scalars['ID']>>,
  id_not_in?: Maybe<Array<Scalars['ID']>>,
  id_lt?: Maybe<Scalars['ID']>,
  id_lte?: Maybe<Scalars['ID']>,
  id_gt?: Maybe<Scalars['ID']>,
  id_gte?: Maybe<Scalars['ID']>,
  id_contains?: Maybe<Scalars['ID']>,
  id_not_contains?: Maybe<Scalars['ID']>,
  id_starts_with?: Maybe<Scalars['ID']>,
  id_not_starts_with?: Maybe<Scalars['ID']>,
  id_ends_with?: Maybe<Scalars['ID']>,
  id_not_ends_with?: Maybe<Scalars['ID']>,
  speakerId?: Maybe<Scalars['String']>,
  speakerId_not?: Maybe<Scalars['String']>,
  speakerId_in?: Maybe<Array<Scalars['String']>>,
  speakerId_not_in?: Maybe<Array<Scalars['String']>>,
  speakerId_lt?: Maybe<Scalars['String']>,
  speakerId_lte?: Maybe<Scalars['String']>,
  speakerId_gt?: Maybe<Scalars['String']>,
  speakerId_gte?: Maybe<Scalars['String']>,
  speakerId_contains?: Maybe<Scalars['String']>,
  speakerId_not_contains?: Maybe<Scalars['String']>,
  speakerId_starts_with?: Maybe<Scalars['String']>,
  speakerId_not_starts_with?: Maybe<Scalars['String']>,
  speakerId_ends_with?: Maybe<Scalars['String']>,
  speakerId_not_ends_with?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['DateTime']>,
  createdAt_not?: Maybe<Scalars['DateTime']>,
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  createdAt_lt?: Maybe<Scalars['DateTime']>,
  createdAt_lte?: Maybe<Scalars['DateTime']>,
  createdAt_gt?: Maybe<Scalars['DateTime']>,
  createdAt_gte?: Maybe<Scalars['DateTime']>,
  updatedAt?: Maybe<Scalars['DateTime']>,
  updatedAt_not?: Maybe<Scalars['DateTime']>,
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>,
  updatedAt_lt?: Maybe<Scalars['DateTime']>,
  updatedAt_lte?: Maybe<Scalars['DateTime']>,
  updatedAt_gt?: Maybe<Scalars['DateTime']>,
  updatedAt_gte?: Maybe<Scalars['DateTime']>,
  stateValue?: Maybe<VoiceState>,
  stateValue_not?: Maybe<VoiceState>,
  stateValue_in?: Maybe<Array<VoiceState>>,
  stateValue_not_in?: Maybe<Array<VoiceState>>,
  text?: Maybe<Scalars['String']>,
  text_not?: Maybe<Scalars['String']>,
  text_in?: Maybe<Array<Scalars['String']>>,
  text_not_in?: Maybe<Array<Scalars['String']>>,
  text_lt?: Maybe<Scalars['String']>,
  text_lte?: Maybe<Scalars['String']>,
  text_gt?: Maybe<Scalars['String']>,
  text_gte?: Maybe<Scalars['String']>,
  text_contains?: Maybe<Scalars['String']>,
  text_not_contains?: Maybe<Scalars['String']>,
  text_starts_with?: Maybe<Scalars['String']>,
  text_not_starts_with?: Maybe<Scalars['String']>,
  text_ends_with?: Maybe<Scalars['String']>,
  text_not_ends_with?: Maybe<Scalars['String']>,
  samples_every?: Maybe<SampleWhereInput>,
  samples_some?: Maybe<SampleWhereInput>,
  samples_none?: Maybe<SampleWhereInput>,
  phonemizationVersion?: Maybe<Scalars['String']>,
  phonemizationVersion_not?: Maybe<Scalars['String']>,
  phonemizationVersion_in?: Maybe<Array<Scalars['String']>>,
  phonemizationVersion_not_in?: Maybe<Array<Scalars['String']>>,
  phonemizationVersion_lt?: Maybe<Scalars['String']>,
  phonemizationVersion_lte?: Maybe<Scalars['String']>,
  phonemizationVersion_gt?: Maybe<Scalars['String']>,
  phonemizationVersion_gte?: Maybe<Scalars['String']>,
  phonemizationVersion_contains?: Maybe<Scalars['String']>,
  phonemizationVersion_not_contains?: Maybe<Scalars['String']>,
  phonemizationVersion_starts_with?: Maybe<Scalars['String']>,
  phonemizationVersion_not_starts_with?: Maybe<Scalars['String']>,
  phonemizationVersion_ends_with?: Maybe<Scalars['String']>,
  phonemizationVersion_not_ends_with?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  language_not?: Maybe<Scalars['String']>,
  language_in?: Maybe<Array<Scalars['String']>>,
  language_not_in?: Maybe<Array<Scalars['String']>>,
  language_lt?: Maybe<Scalars['String']>,
  language_lte?: Maybe<Scalars['String']>,
  language_gt?: Maybe<Scalars['String']>,
  language_gte?: Maybe<Scalars['String']>,
  language_contains?: Maybe<Scalars['String']>,
  language_not_contains?: Maybe<Scalars['String']>,
  language_starts_with?: Maybe<Scalars['String']>,
  language_not_starts_with?: Maybe<Scalars['String']>,
  language_ends_with?: Maybe<Scalars['String']>,
  language_not_ends_with?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  gender_not?: Maybe<Scalars['String']>,
  gender_in?: Maybe<Array<Scalars['String']>>,
  gender_not_in?: Maybe<Array<Scalars['String']>>,
  gender_lt?: Maybe<Scalars['String']>,
  gender_lte?: Maybe<Scalars['String']>,
  gender_gt?: Maybe<Scalars['String']>,
  gender_gte?: Maybe<Scalars['String']>,
  gender_contains?: Maybe<Scalars['String']>,
  gender_not_contains?: Maybe<Scalars['String']>,
  gender_starts_with?: Maybe<Scalars['String']>,
  gender_not_starts_with?: Maybe<Scalars['String']>,
  gender_ends_with?: Maybe<Scalars['String']>,
  gender_not_ends_with?: Maybe<Scalars['String']>,
  age?: Maybe<Scalars['Int']>,
  age_not?: Maybe<Scalars['Int']>,
  age_in?: Maybe<Array<Scalars['Int']>>,
  age_not_in?: Maybe<Array<Scalars['Int']>>,
  age_lt?: Maybe<Scalars['Int']>,
  age_lte?: Maybe<Scalars['Int']>,
  age_gt?: Maybe<Scalars['Int']>,
  age_gte?: Maybe<Scalars['Int']>,
  AND?: Maybe<Array<VoiceWhereInput>>,
  OR?: Maybe<Array<VoiceWhereInput>>,
  NOT?: Maybe<Array<VoiceWhereInput>>,
};

export type VoiceWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>,
};

/** A channel resource from the YouTube Data API. */
export type YtChannel = {
   __typename?: 'YtChannel',
  id: Scalars['ID'],
  /** The URL of the channel */
  url: Scalars['String'],
  thumbnails: YtThumbnails,
  title: Scalars['String'],
  description: Scalars['String'],
  /** 
 * The channel's custom URL.
   * 
   * A channel does not always have a customUrl.
 **/
  customUrl?: Maybe<Scalars['String']>,
  /** The number of subscribers that the channel has. */
  subscriberCount: Scalars['Int'],
  /** Indicates whether the channel's subscriber count is publicly visible. */
  hiddenSubscriberCount: Scalars['Boolean'],
  /** The number of videos uploaded to the channel. */
  videoCount: Scalars['Int'],
  /** The number of times the channel has been viewed. */
  viewCount: Scalars['JsSafeInt'],
  /** YouTube does not always provide topic details for a channel. */
  topicDetails?: Maybe<YtTopicDetails>,
};

/** The lookup type for a YouTube channel. */
export enum YtChannelLookupType {
  Id = 'ID',
  Url = 'URL',
  Username = 'USERNAME'
}

export type YtChannelUploadPlaylistItems = {
   __typename?: 'YtChannelUploadPlaylistItems',
  kind?: Maybe<Scalars['String']>,
  etag?: Maybe<Scalars['String']>,
  nextPageToken?: Maybe<Scalars['String']>,
  prevPageToken?: Maybe<Scalars['String']>,
  items?: Maybe<Array<YtPlaylistItem>>,
  pageInfo?: Maybe<YtPageInfo>,
};

export type YtPageInfo = {
   __typename?: 'YtPageInfo',
  totalResults: Scalars['Int'],
  resultsPerPage: Scalars['Int'],
};

export type YtPlaylistItem = {
   __typename?: 'YtPlaylistItem',
  id?: Maybe<Scalars['ID']>,
  etag?: Maybe<Scalars['String']>,
  kind?: Maybe<Scalars['String']>,
  snippet: YtPlaylistItemSnippet,
};

export type YtPlaylistItemSnippet = {
   __typename?: 'YtPlaylistItemSnippet',
  publishedAt: Scalars['String'],
  channelId: Scalars['ID'],
  position: Scalars['Int'],
  title: Scalars['String'],
  description: Scalars['String'],
  channelTitle: Scalars['String'],
  playlistId: Scalars['String'],
  resourceId: YtResourceId,
  thumbnails: YtThumbnails,
};

export type YtResourceId = {
   __typename?: 'YtResourceId',
  kind: Scalars['String'],
  videoId: Scalars['ID'],
};

export type YtThumbnail = {
   __typename?: 'YtThumbnail',
  url?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['Int']>,
  height?: Maybe<Scalars['Int']>,
};

export type YtThumbnails = {
   __typename?: 'YtThumbnails',
  default: YtThumbnail,
  medium: YtThumbnail,
  high: YtThumbnail,
  standard?: Maybe<YtThumbnail>,
  maxres?: Maybe<YtThumbnail>,
};

/** The topicDetails object encapsulates information about topics associated with the resource. */
export type YtTopicDetails = {
   __typename?: 'YtTopicDetails',
  /** A list of topic IDs associated with the resource. */
  topicIds?: Maybe<Array<Scalars['ID']>>,
  /** A list of Wikipedia URLs that describe the resources' content. */
  topicCategories?: Maybe<Array<Scalars['String']>>,
  /** The topic from the topicCategory URL */
  topicCategoryValues?: Maybe<Array<Scalars['String']>>,
};

/** A video resource represents a YouTube video. */
export type YtVideo = {
   __typename?: 'YtVideo',
  /** The ID that YouTube uses to uniquely identify the video. */
  id: Scalars['ID'],
  /** The URL of the video */
  url: Scalars['String'],
  /** 
 * The video's title. The property value has a maximum length of 100 characters
   * and may contain all valid UTF-8 characters except < and >.
 **/
  title: Scalars['String'],
  /** 
 * The video's description. The property value has a maximum length of 5000 bytes
   * and may contain all valid UTF-8 characters except < and >.
 **/
  description: Scalars['String'],
  /** The language of the text in the video resource's snippet.title and snippet.description properties. */
  defaultLanguage?: Maybe<Scalars['String']>,
  /** The default_audio_language property specifies the language spoken in the video's default audio track. */
  defaultAudioLanguage?: Maybe<Scalars['String']>,
  channelId: Scalars['String'],
  channelTitle: Scalars['String'],
  /** 
 * The date and time that the video was published. Note that this time might be
   * different than the time that the video was uploaded. For example, if a video
   * is uploaded as a private video and then made public at a later time, this
   * property will specify the time that the video was made public.
 **/
  publishedAt: Scalars['String'],
  thumbnails: YtThumbnails,
  /** YouTube does not always provide topic details for a video. */
  topicDetails?: Maybe<YtTopicDetails>,
  /** 
 * A list of keyword tags associated with the video. Tags may contain spaces. The
   * property value has a maximum length of 500 characters.
 **/
  tags?: Maybe<Array<Scalars['String']>>,
  /** 
 * The number of times the video has been viewed.
   * 
   * Is of type JsSafeInt because views of Katy Perry - Roar (2,815,294,046) > Prisma Int max value (2,147,483,647).
   * 
   * In JavaScript, Number.MAX_SAFE_INTEGER is 9007199254740991
 **/
  viewCount: Scalars['JsSafeInt'],
  /** The number of users who have indicated that they liked the video. */
  likeCount: Scalars['Int'],
  /** The number of users who have indicated that they disliked the video. */
  dislikeCount: Scalars['Int'],
  /** The number of comments for the video. */
  commentCount?: Maybe<Scalars['Int']>,
};

/** The lookup type for a YouTube video. */
export enum YtVideoLookupType {
  Id = 'ID',
  Url = 'URL'
}
