import {
  SampleState,
  Segment,
  SegmentState
} from "../../@types/generated/types";
import { StudioSegment } from "../../@types/data";
import { ColoursText } from "../../@types/styling";

export const isPreviewSampleReady = (s: Segment): boolean => {
  return (
    s.stateValue !== SegmentState.Complete ||
    !s.previewSample ||
    s.previewSample.stateValue !== SampleState.Complete
  );
};

export const secondsToInput = (seconds: number) => {
  return `${~~(seconds / 60)}:${(seconds % 60).toFixed(2).padStart(5, "0")}`;
};

export const getSegmentColour = ({
  done,
  overlap
}: Pick<StudioSegment, "done" | "overlap">): ColoursText =>
  overlap > 0 ? "c-warning" : done ? "c-success" : "c-secondary";

export const isSegmentUpToDate = (s: Segment) =>
  !!s.previewSample &&
  s.translatedText === s.previewSample.text &&
  s.papercupSpeakerId === s.previewSample.papercupSpeakerId &&
  s.style === s.previewSample.style &&
  s.stretchRate === s.previewSample.stretchRate;

export const computeOverflows = (
  segments: Segment[],
  videoDuration: number
): StudioSegment[] => {
  const computeOverflow = (s: Segment, i: number): StudioSegment => {
    const previousSegmentId = i < 1 ? null : segments[i - 1].id;
    const nextSegmentId = i + 1 === segments.length ? null : segments[i + 1].id;
    const nextSourceStartTime =
      i + 1 === segments.length
        ? videoDuration
        : segments[i + 1].startTime - 0.00001;

    if (!s.previewSample || !s.previewSample.audioDuration) {
      return {
        ...s,
        overlap: 0,
        overflow: 0,
        nextTranslationStartTime: 0,
        translationEndTime: nextSourceStartTime,
        nextStartTime: nextSourceStartTime,
        isPreviewLoading: true,
        previousSegmentId,
        nextSegmentId
      };
    }

    const nextTranslationStartTime =
      i + 1 === segments.length
        ? videoDuration
        : segments[i + 1].translationStartTime - 0.00001;

    return {
      ...s,
      previousSegmentId,
      nextSegmentId,
      isPreviewLoading: isPreviewSampleReady(s),
      nextStartTime: nextSourceStartTime,
      nextTranslationStartTime: nextTranslationStartTime,
      translationEndTime: Math.min(
        s.translationStartTime + s.previewSample.audioDuration,
        videoDuration
      ),
      overlap:
        Math.round(
          (s.previewSample.audioDuration +
            s.translationStartTime -
            nextTranslationStartTime) *
            100
        ) / 100,

      overflow:
        Math.round(
          (s.previewSample.audioDuration +
            s.translationStartTime -
            nextSourceStartTime) *
            100
        ) / 100
    };
  };

  return segments
    .sort((c, n) => c.startTime - n.startTime)
    .map(computeOverflow);
};

export const setCurrentTime = (videoId: string, currentTime: number) => {
  if (!window.VideoTimes) {
    window.VideoTimes = {};
  }
  window.VideoTimes[videoId] = currentTime;
};

export const getCurrentTime = (videoId: string): number => {
  return (window.VideoTimes && window.VideoTimes[videoId]) || 0;
};

export const getRatio = (
  startTime: number,
  splitTime: number,
  totalDuration: number
): number => {
  return (splitTime - startTime) / totalDuration;
};
