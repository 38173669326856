import * as React from "react";
import classNames from "classnames";
import Select from "react-select";
import { components } from "react-select";
import { Icon } from "../Icon";
import { Checkbox } from "./Checkbox";
import { getStatusColours } from "../utils";
import { bgColours } from "../../themes/colours";
import { StylesConfig } from "react-select/src/styles";
import { ControlProps } from "react-select/src/components/Control";
import { PlaceholderProps } from "react-select/src/components/Placeholder";
import { OptionProps } from "react-select/src/components/Option";
import { ValueContainerProps } from "react-select/src/components/containers";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { MenuProps } from "react-select/src/components/Menu";
import { IndicatorProps } from "react-select/src/components/indicators";
import { Props as SelectProps } from "react-select/src/Select";
import {
  BorderProperties,
  ColoursBackground,
  DimensionProperties
} from "../../@types/styling";
import { ClassValue, Status } from "../../@types/component";
import { Container } from "../Container";
import { Radio } from "./Radio";

export interface BaseOption {
  label: string;
  value: any;
  classNames?: ClassValue;
}

const Control = (
  props: {
    menuIsOpen?: boolean;
    seamless?: boolean;
  } & ControlProps<BaseOption>
) => {
  const {
    selectProps: { mainColour, mainBGColour, mainBorderColour, seamless },
    isDisabled,
    menuIsOpen
  } = props;
  return (
    <components.Control
      {...props}
      className={classNames(
        "flex db",
        "w-100 pl4 pv3",
        "lh-f5 fw5 f5 pff",
        mainColour,
        mainBorderColour,
        !seamless && mainBGColour,
        !seamless && "b--solid bw1 br2",
        // Prevent weird white halo appearing
        !menuIsOpen && "highlight-hover-ef hover-ef before-z-1",
        !menuIsOpen && !seamless && "input-shadow",
        {
          pointer: !isDisabled
        }
      )}
    />
  );
};

const Placeholder = (props: PlaceholderProps<BaseOption>) => (
  <components.Placeholder
    {...props}
    className="pff lh-f5 f5 fw4 c-secondary i"
  />
);

const DropdownIndicator = (props: IndicatorProps<BaseOption>) => {
  const {
    selectProps: { mainColour, status, seamless, isClearable },
    hasValue
  } = props;

  if (hasValue && isClearable) {
    return null;
  }
  if (seamless) {
    return <Container dimensions={{ w: "w1" }} />;
  }

  return (
    <Icon
      name="chevron"
      colour={status === "default" ? "c-secondary" : mainColour}
      size={4}
      // No need to spread props here, since our Icon doesn't _REACT_ to them
    />
  );
};

const Menu = (props: MenuProps<BaseOption>) => {
  return (
    <components.Menu
      {...props}
      className={classNames(
        "absolute bg-secondary br3 shadow-3 z-5 b--gray-20 bw1 b--solid pv4 outline-0 min-w-100 top-0 slide-in-bottom-keyframe-ef"
      )}
    />
  );
};

const Option = (props: OptionProps<BaseOption>) => {
  const {
    data: { classNames: optionAdditionalClasses },
    selectProps: { seamless, optionsClasses, showSelected },
    isSelected,
    isMulti,
    label,
    children
  } = props;
  return (
    <components.Option
      {...props}
      className={classNames(
        "relative flex items-center",
        "c-primary",
        "w-100 h-100 pv3",
        seamless && !showSelected ? "ph4" : "pl4", // Seamless doesn't have indicator on the right
        "lh-f5 fw5 f5 pff",
        "bg-secondary bg-animate hover-bg-primary pointer",
        optionAdditionalClasses,
        optionsClasses
      )}
    >
      {isMulti ? (
        <Checkbox
          label={label}
          checked={isSelected}
          classNameOverride={"pointer-none w-100"} // We don't want the checkbox to catch any of the pointer events
          readOnly
          reverse
        />
      ) : showSelected ? (
        <Radio
          label={label}
          checked={isSelected}
          labelMargin={"mr4"}
          classNameOverride={"pointer-none w-100"} // We don't want the checkbox to catch any of the pointer events
          reverse
          readOnly
        />
      ) : (
        children
      )}
    </components.Option>
  );
};

const ValueContainer = ({
  children,
  ...rest
}: {
  menuIsOpen?: boolean;
} & ValueContainerProps<BaseOption>) => {
  const {
    selectProps: { value, mainBGColour, valueContainerClasses },
    menuIsOpen,
    isMulti
  } = rest;
  // We might not have a value
  const { classNames: optionAdditionalClasses } = (value || {}) as any; // Stop typescript complaining, I know what I'm doing
  const shadowColour = bgColours[mainBGColour as ColoursBackground];
  return (
    <components.ValueContainer
      {...rest}
      className={classNames(
        optionAdditionalClasses,
        valueContainerClasses,
        "value-container flex flex-nowrap w-100 items-center overflow-hidden"
      )}
    >
      {children}
      {!menuIsOpen && isMulti && <div className={"inner-shadow"} />}
      <style jsx>
        {`
          .inner-shadow {
            content: "";
            position: absolute;
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0),
              ${shadowColour}
            );
            z-index: 0;
            width: 30px;
            height: calc(100% - 2px);
            overflow: hidden;
            right: 34px;
          }
        `}
      </style>
      <style jsx global>
        {`
          .value-container
            > .multi-value:not(:last-of-type)
            > .multi-value
            > div:after {
            content: ",";
            margin-right: 5px;
          }
        `}
      </style>
    </components.ValueContainer>
  );
};

// Wrapping in span to be able to use last of type pseudo selector
// Otherwise the element is rendered as div, followed by another div of the input field. Thus we are unable to
// grab the last element of the list to not insert the trailing comma.
const MultiValue = (props: MultiValueProps<BaseOption>) => (
  <span className={"multi-value"}>
    <components.MultiValue {...props} className={"multi-value"} />
  </span>
);

// We don't want any of the styles
const emptyReactSelectStyles: StylesConfig = {
  container: () => ({}),
  control: () => ({}),
  dropdownIndicator: () => ({}),
  group: () => ({}),
  groupHeading: () => ({}),
  indicatorsContainer: () => ({}),
  indicatorSeparator: () => ({}),
  input: () => ({}),
  loadingIndicator: () => ({}),
  loadingMessage: () => ({}),
  menu: () => ({}),
  menuList: () => ({}),
  menuPortal: () => ({}),
  multiValue: () => ({}),
  multiValueLabel: () => ({}),
  multiValueRemove: () => ({}),
  noOptionsMessage: () => ({}),
  option: () => ({}),
  placeholder: () => ({}),
  singleValue: () => ({}),
  valueContainer: () => ({})
};

export interface InterfaceDropdown extends SelectProps<BaseOption> {
  status?: Status;
  seamless?: boolean;
  dimensions?: DimensionProperties;
  border?: BorderProperties;
  optionsClasses?: ClassValue;
  valueContainerClasses?: ClassValue;
  showSelected?: boolean;
}

export const Dropdown: React.FunctionComponent<InterfaceDropdown> = ({
  status = "default",
  dimensions,
  border,
  components,
  style,
  ...rest
}) => (
  <Container
    dimensions={Object.assign({ m: "mv2" }, dimensions)}
    border={border}
    style={style}
  >
    <Select
      components={Object.assign(
        {
          Control,
          DropdownIndicator,
          MultiValue,
          Menu,
          Placeholder,
          Option,
          ValueContainer,
          // // We don't have designs or use for these at the moment
          IndicatorSeparator: () => null,
          MultiValueRemove: () => null
        },
        components
      )}
      className={"relative"} // To ensure our highlight on hover behaves correctly
      hideSelectedOptions={false}
      closeMenuOnSelect={!rest.isMulti}
      status={status}
      styles={emptyReactSelectStyles}
      {...getStatusColours(status)}
      {...rest}
    />
  </Container>
);
