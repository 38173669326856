// Use this if you need to access colours from Styled JSX. It cannot access CSS variables defined in overrides.css
// We want to retain same naming abstraction as in our CSS
// Not using camelcase so we can easily get the needed constant by doing .replace on a classname and removing -
import { ColoursBackground, ColoursText } from "../@types/styling";
export const primaryFontFace =
  '"Helvetica Now", -apple-system, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;';
export const baseColours = {
  orange: "rgb(252, 78, 54)",
  red: "rgb(248, 34, 84)",
  "red-10": "rgba(248, 34, 84, 0.10)",
  "red-7": "rgba(248, 34, 84, 0.07)",
  yellow: "rgb(255, 140, 49)",
  "yellow-10": "rgba(255, 140, 49, 0.10)",
  "yellow-7": "rgba(255, 140, 49, 0.07)",
  green: "rgb(22, 206, 109)",
  "green-10": "rgba(22, 206, 109, 0.10)",
  "green-7": "rgba(22, 206, 109, 0.07)",
  "near-black": "rgb(46, 45, 48)",
  "near-black-20": "rgba(46, 45, 48, 0.20)",
  "near-black-80": "rgba(46, 45, 48, 0.8)",
  "dark-gray": "rgb(53, 52, 56)",
  "mid-gray": "rgb(61, 59, 64)",
  "mid-gray-90": "rgba(61, 59, 64, 0.9)",
  gray: "rgb(152, 150, 147)",
  "gray-20": "rgba(152, 150, 147, 0.20)",
  "gray-10": "rgba(152, 150, 147, 0.10)",
  "gray-7": "rgba(152, 150, 147, 0.07)",
  "near-white": "rgb(248, 247, 247)",
  "near-white-10": "rgba(248, 247, 247, 0.03)"
};

export const colours: {
  [K in ColoursText]: string;
} = {
  "c-primary": baseColours["near-white"],
  "c-secondary": baseColours.gray,
  "c-ternary": baseColours["mid-gray"],
  "c-invert": baseColours["near-black"],
  "c-brand": baseColours.orange,
  "c-error": baseColours.red,
  "c-warning": baseColours.yellow,
  "c-success": baseColours.green
};

export const bgColours: {
  [K in ColoursBackground]: string;
} = {
  "bg-primary": baseColours["near-black"],
  "bg-primary-80": baseColours["near-black-80"],
  "bg-secondary": baseColours["dark-gray"],
  "bg-ternary": baseColours["mid-gray"],
  "bg-invert": baseColours["near-white"],
  "bg-brand": baseColours.orange,
  "bg-error": baseColours.red,
  "bg-warning": baseColours.yellow,
  "bg-success": baseColours.green,
  "bg-error-10": baseColours["red-10"],
  "bg-warning-10": baseColours["yellow-10"],
  "bg-success-10": baseColours["green-10"],
  "bg-default-10": baseColours["gray-10"]
};
