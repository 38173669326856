import {
  ColoursBackground,
  ColoursText,
  ColoursBorder
} from "../@types/styling";
import { Status } from "../@types/component";
import LocaleCode from "locale-code";

export const getStatusColours = (
  status: Status,
  defaultMainColour: ColoursText = "c-primary",
  defaultMainBGColour: ColoursBackground = "bg-ternary",
  defaultMainBorderColour: ColoursBorder = "b--gray-7"
) => {
  const isColouredStatus = status === "default" ? undefined : status;

  const mainColour = isColouredStatus
    ? (`c-${status}` as ColoursText)
    : defaultMainColour;

  const mainBGColour = isColouredStatus
    ? (`bg-${status}-10` as ColoursBackground)
    : defaultMainBGColour;

  const mainBorderColour = isColouredStatus
    ? (`b--${status}-10` as ColoursBackground)
    : defaultMainBorderColour;

  return { mainColour, mainBGColour, mainBorderColour };
};

export function languageToOption(languageCode: string) {
  return {
    label: LocaleCode.getLanguageName(languageCode),
    value: languageCode
  };
}
