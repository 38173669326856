import * as React from "react";
import { useContext, useEffect } from "react";
import { FlexRow } from "./Container";
import { ProfileContext } from "../context";
import { Router, useRouter } from "next/router";
import { omit } from "lodash";
import { WithProviders } from "./withProviders";
import { LoadingPage } from "./LoadingPage";
import { useGA } from "../hooks/analytics/useGA";
import { H3 } from "./Typography";
import { Icon } from "./Icon";

const REDIRECT_ON_LOGIN_STORAGE_KEY = "REDIRECT_ON_LOGIN_STORAGE_KEY";

export interface InterfacePage {
  accessLevel?: "publicOnly" | "public" | "private";
}

const PageBase: React.FunctionComponent<InterfacePage> = ({
  accessLevel = "private",
  children
}) => {
  useGA();
  const { isAuthenticated, authChecked } = useContext(ProfileContext);

  // Hack around Next routes canceling some routes for some reason
  useEffect(() => {
    const handleRouteChange = (err: any, url: any) => {
      window.location.replace(url);
      console.log("App is changing to: ", url);
    };

    Router.events.on("routeChangeError", handleRouteChange);
    return () => {
      Router.events.off("routeChangeError", handleRouteChange);
    };
  }, []);

  if (typeof window === "undefined" || !authChecked) {
    return <LoadingPage />;
  }
  const router = useRouter();

  if (accessLevel === "publicOnly" && isAuthenticated) {
    const redirectOnLogin = JSON.parse(sessionStorage.getItem(
      REDIRECT_ON_LOGIN_STORAGE_KEY
    ) as any);

    if (redirectOnLogin) {
      sessionStorage.removeItem(REDIRECT_ON_LOGIN_STORAGE_KEY);
      router.push(redirectOnLogin);
    } else {
      router.push({
        pathname: "/dashboard",
        query: {
          ...omit(router.query, ["code"]),
          page: "channels",
          section: "channels"
        } // Remove the code query param that is used by Cognito Auth, otherwise it kept requests OAuth
      });
      //use window location replace if the router push fails. Not a nice fix.
      window.location.replace(`/dashboard?section=channels&page=channels`);
    }
    return <LoadingPage />;
  }

  if (accessLevel === "private" && !isAuthenticated) {
    const url = { pathname: router.pathname, query: router.query };
    sessionStorage.setItem(REDIRECT_ON_LOGIN_STORAGE_KEY, JSON.stringify(url));
    router.push({
      pathname: "/login",
      query: router.query
    });
    return <LoadingPage />;
  }

  return (
    <>
      {window && !window.chrome && (
        <FlexRow
          background={"bg-brand"}
          dimensions={{ pad: "pa5" }}
          flex={{
            fai: "items-center",
            fjc: "justify-center"
          }}
        >
          <Icon name={"alert"} size={4} colour={"c-primary"} />
          <H3>
            It looks like you are using a browser which we do not support.
            Please use latest version of Chrome.
          </H3>
        </FlexRow>
      )}
      <FlexRow
        id="layout"
        background={"bg-primary"}
        dimensions={{ h: "vh-100", w: "vw-100" }}
        flex={{
          fai: "items-center",
          fjc: "justify-center"
        }}
      >
        {children}
      </FlexRow>
    </>
  );
};

export const Page = WithProviders(PageBase);
