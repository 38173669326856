import * as React from "react";
import {
  WithAsElementProp,
  ClassNameOverrideInterface,
  ClassValue
} from "../@types/component";
import {
  ColoursText,
  ColoursBackground,
  Margin,
  Heights,
  Position
} from "../@types/styling";
import { Icon, IconNames } from "./Icon";
import { FlexRow, Container } from "./Container";
import { CTA } from "./Typography";
import { TooltipLabel } from "./Label";

const HOVER_EFFECT = "highlight-hover-ef hover-ef";
const SLIDE_EFFECT = "slide-in-bottom-ef";

interface InterfaceButton extends ClassNameOverrideInterface {
  asElement?: React.ElementType;
  icon?: IconNames;
  m?: Margin | Margin[];
  pos?: Position;
  basic?: boolean;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  containerClassNameOverride?: ClassValue;
  tooltip?: React.ReactNode;
  href?: any;
}

export const Button: WithAsElementProp<InterfaceButton> = ({
  icon,
  m = "mr3",
  size = 4, // Currently we have only one size, may change in the future
  pos = "relative",
  asElement = "div",
  disabled,
  children,
  basic,
  primary,
  secondary,
  tooltip,
  containerClassNameOverride, // We need ability to modify the class of the container
  loading,
  ...rest
}: any) => {
  const mainBGColour = primary && !basic ? "bg-brand" : null;
  let mainColour = primary && !basic ? "c-primary" : "c-brand";
  if (secondary) {
    mainColour = "c-secondary";
  }

  rest.classNameOverride = [
    rest.classNameOverride,
    HOVER_EFFECT,
    "overflow-hidden",
    "z-0",
    "before-z-negative", // Fixes label and hover-effect conflicting
    {
      pointer: !disabled
    }
  ];

  const containerClasses = [
    containerClassNameOverride,
    SLIDE_EFFECT,
    {
      "o-20": disabled
    }
  ];

  return (
    <Container
      layout={{ pos, d: "inline-flex" }}
      dimensions={{ m }}
      flex={{ fai: "items-stretch" }}
      classNameOverride={containerClasses} // Class to handle on hover tooltip
    >
      <FlexRow
        asElement={asElement}
        dimensions={{ h: `h${size}` as Heights, w: "w-100" }}
        flex={{ fjc: "justify-center" }}
        role="button"
        background={mainBGColour as ColoursBackground}
        border={{ br: !children ? "br-100" : "br1" }} // Assuming if no children, we have icon only
        {...rest} // we want the rest properties spread on the button it self, rather than the container with the tooltip
      >
        {icon && (
          <Icon
            size={size}
            name={icon}
            loading={loading}
            colour={mainColour as ColoursText}
          />
        )}
        {children && (
          <FlexRow dimensions={{ m: "ma3" }} flex={{ fai: "items-center" }}>
            <CTA colour={mainColour as ColoursText}>{children}</CTA>
          </FlexRow>
        )}
      </FlexRow>
      {tooltip && (
        <TooltipLabel className={["child", "absolute", "z-999"]}>
          {tooltip}
        </TooltipLabel>
      )}
    </Container>
  );
};
