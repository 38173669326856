import * as React from "react";
import { useContext, useEffect, useRef } from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { ApolloLink, concat, split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { getMainDefinition } from "apollo-utilities";

import { ProfileProvider } from "../providers/Profile";

import { ProfileContext } from "../context";
import { cacheStudioDefaultsInit, STUDIO_RESOLVERS } from "./studio/resolvers";

const ApolloProviderWrapper: React.FunctionComponent<any> = ({ children }) => {
  // Init Apollo only if the user is logged in
  const { isAuthenticated, token } = useContext(ProfileContext);
  const clientRef = useRef<ApolloClient<any>>();
  useEffect(() => {
    if (isAuthenticated) {
      const httpLink = new HttpLink({
        uri: process.env.BINDER_URI
      });

      const authMiddleware = new ApolloLink((operation, forward) => {
        // To make TypeScript stop complaining
        if (forward !== undefined) {
          operation.setContext({
            headers: {
              authorization: `Bearer ${token}`
            }
          });

          return forward(operation);
        }
        return null;
      });

      const authHttpLink = concat(authMiddleware, httpLink);

      // https://www.apollographql.com/docs/react/advanced/subscriptions/#client-setup
      // Create a WebSocket link:
      const wsLink = new WebSocketLink({
        uri: (process.env.BINDER_URI || "").replace(/http/, "ws"),
        options: {
          reconnect: true
        }
      });

      // using the ability to split links, you can send data to each link
      // depending on what kind of operation is being sent
      const link = split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        authHttpLink
      );

      clientRef.current = new ApolloClient({
        link: link,
        cache: new InMemoryCache(),
        resolvers: STUDIO_RESOLVERS
      });
      cacheStudioDefaultsInit(clientRef.current);
    }
  }, [token, isAuthenticated]);
  return clientRef.current ? (
    <ApolloProvider client={clientRef.current}>{children}</ApolloProvider>
  ) : (
    children
  );
};

// https://stackoverflow.com/questions/32308370/what-is-the-syntax-for-typescript-arrow-functions-with-generics
export const WithProviders = <T extends {}>(
  Component: React.JSXElementConstructor<T>
) => (props: T) => {
  if (typeof window === "undefined") {
    return null;
  }
  return (
    <ProfileProvider>
      <ApolloProviderWrapper>
        <Component {...props} />
      </ApolloProviderWrapper>
    </ProfileProvider>
  );
};
