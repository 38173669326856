import { gql } from "apollo-boost";
import { SegmentFragment } from "../../graphql/translatedVideo";

export const UPDATE_SEGMENT = gql`
  mutation updateSegment($id: ID!, $data: SegmentUpdateInput!) {
    updateSegment(where: { id: $id }, data: $data) {
      ...SegmentFragment
    }
  }
  ${SegmentFragment}
`;
export const CREATE_SAMPLE_FROM_SEGMENT = gql`
  mutation createSampleFromSegment($id: String!) {
    createSampleFromSegment(id: $id) {
      ...SegmentFragment
    }
  }
  ${SegmentFragment}
`;
export const TRANSLATE_SEGMENT = gql`
  mutation translateSegment(
    $id: ID!
    $targetLang: String!
    $sourceLang: String!
  ) {
    translateSegment(
      id: $id
      targetLang: $targetLang
      sourceLang: $sourceLang
    ) {
      ...SegmentFragment
    }
  }
  ${SegmentFragment}
`;
export const DELETE_SEGMENT = gql`
  mutation deleteSegment($id: ID!) {
    deleteSegment(where: { id: $id }) {
      id
    }
  }
`;
export const SPLIT_SEGMENT = gql`
  mutation splitSegment(
    $id: ID!
    $newSegment: SegmentCreateInput!
    $existingSegment: SegmentUpdateInput!
  ) {
    updateSegment(where: { id: $id }, data: $existingSegment) {
      ...SegmentFragment
    }
    createSegment(data: $newSegment) {
      ...SegmentFragment
    }
  }
  ${SegmentFragment}
`;
export const MOVE_TRANSLATED_VIDEO_STATE = gql`
  mutation moveTranslatedVideo($id: ID!, $state: String!) {
    moveTranslatedVideo(id: $id, state: $state) {
      id
      stateValue
    }
  }
`;

export const UPDATE_MANY_SEGMENTS = gql`
  mutation updateManySegments(
    $id: ID!
    $data: SegmentUpdateManyMutationInput!
  ) {
    updateManySegments(where: { video: { id: $id } }, data: $data) {
      count
    }
  }
`;

export const BULK_CREATE_SAMPLES = gql`
  mutation bulkCreateSampleFromTranslatedVideo($id: ID!) {
    bulkCreateSampleFromTranslatedVideo(id: $id) @client
  }
`;
