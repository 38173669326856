import classNames from "classnames";
import * as React from "react";
import {
  WithAsElementProp,
  ClassNameOverrideInterface
} from "../@types/component";
import { ColoursText } from "../@types/styling";
import BackSVG from "../assets/svg/icons/Dr_Back.svg";
import ChevronSVG from "../assets/svg/icons/Dr_Chevron.svg";
import CloseSVG from "../assets/svg/icons/Dr_Close.svg";
import ConfirmSVG from "../assets/svg/icons/Dr_Confirm.svg";
import DownloadSVG from "../assets/svg/icons/Dr_Download.svg";
import ForwardSVG from "../assets/svg/icons/Dr_Forward.svg";
import PlusSVG from "../assets/svg/icons/Dr_Plus.svg";
import AlertSVG from "../assets/svg/icons/Ds_Alert.svg";
import AlphabeticalSVG from "../assets/svg/icons/Ds_Alphabetical.svg";
import BellSVG from "../assets/svg/icons/Ds_Bell.svg";
import ChannelSVG from "../assets/svg/icons/Ds_Channel.svg";
import GradeSVG from "../assets/svg/icons/Ds_Grade.svg";
import DurationSVG from "../assets/svg/icons/Ds_Duration.svg";
import CheckSVG from "../assets/svg/icons/Ds_Check.svg";
import DaysSVG from "../assets/svg/icons/Ds_Days.svg";
import FilterSVG from "../assets/svg/icons/Ds_Filter.svg";
import FrequencySVG from "../assets/svg/icons/Ds_Frequency.svg";
import InfoSVG from "../assets/svg/icons/Ds_Info.svg";
import InprogressSVG from "../assets/svg/icons/Ds_Inprogress.svg";
import LayoutSVG from "../assets/svg/icons/Ds_Layout.svg";
import LockSVG from "../assets/svg/icons/Ds_Lock.svg";
import ManagerSVG from "../assets/svg/icons/Ds_Manager.svg";
import NextSVG from "../assets/svg/icons/Ds_Next.svg";
import PlaySVG from "../assets/svg/icons/Ds_Play.svg";
import PreviousSVG from "../assets/svg/icons/Ds_Previous.svg";
import SpeakerSVG from "../assets/svg/icons/Ds_Speaker.svg";
import StatusSVG from "../assets/svg/icons/Ds_Status.svg";
import TextSVG from "../assets/svg/icons/Ds_Text.svg";
import TimeSVG from "../assets/svg/icons/Ds_Time.svg";
import TranslationSVG from "../assets/svg/icons/Ds_Translation.svg";
import UploadSVG from "../assets/svg/icons/Ds_Upload.svg";
import VideoSVG from "../assets/svg/icons/Ds_Video.svg";
import VolumeSVG from "../assets/svg/icons/Ds_Volume.svg";
import UserSVG from "../assets/svg/icons/Ds_User.svg";
import ViewSVG from "../assets/svg/icons/Ds_View.svg";
import EllipsisSVG from "../assets/svg/icons/Dr_Info_More.svg";
import NavQASVG from "../assets/svg/icons/Nav_QA.svg";
import NavChannelsSVG from "../assets/svg/icons/Nav_channels.svg";
import NavVideosSVG from "../assets/svg/icons/Nav_videos.svg";
import LoadingSVG from "../assets/svg/icons/DS_Loading.svg";
import RefreshSVG from "../assets/svg/icons/Dr_Refresh.svg";
import PauseSVG from "../assets/svg/icons/Ds_Pause.svg";
import DeleteSVG from "../assets/svg/icons/Ds_Delete.svg";
import SplitSVG from "../assets/svg/icons/Ds_Split.svg";
import MergeSVG from "../assets/svg/icons/Ds_Merge.svg";

type SVGComp = React.FunctionComponent<React.SVGAttributes<SVGElement>>;

// Use a class so we can use keys of the class as part of the interface
class IconsMapping {
  public static readonly alert: SVGComp = AlertSVG;
  public static readonly alphabetical: SVGComp = AlphabeticalSVG;
  public static readonly back: SVGComp = BackSVG;
  public static readonly chevron: SVGComp = ChevronSVG;
  public static readonly close: SVGComp = CloseSVG;
  public static readonly confirm: SVGComp = ConfirmSVG;
  public static readonly plus: SVGComp = PlusSVG;
  public static readonly bell: SVGComp = BellSVG;
  public static readonly channel: SVGComp = ChannelSVG;
  public static readonly download: SVGComp = DownloadSVG;
  public static readonly duration: SVGComp = DurationSVG;
  public static readonly forward: SVGComp = ForwardSVG;
  public static readonly grade: SVGComp = GradeSVG;
  public static readonly user: SVGComp = UserSVG;
  public static readonly view: SVGComp = ViewSVG;
  public static readonly qa: SVGComp = NavQASVG;
  public static readonly channels: SVGComp = NavChannelsSVG;
  public static readonly videos: SVGComp = NavVideosSVG;
  public static readonly ellipsis: SVGComp = EllipsisSVG;
  public static readonly filter: SVGComp = FilterSVG;
  public static readonly frequency: SVGComp = FrequencySVG;
  public static readonly info: SVGComp = InfoSVG;
  public static readonly inprogress: SVGComp = InprogressSVG;
  public static readonly layout: SVGComp = LayoutSVG;
  public static readonly lock: SVGComp = LockSVG;
  public static readonly manager: SVGComp = ManagerSVG;
  public static readonly next: SVGComp = NextSVG;
  public static readonly play: SVGComp = PlaySVG;
  public static readonly previous: SVGComp = PreviousSVG;
  public static readonly speaker: SVGComp = SpeakerSVG;
  public static readonly status: SVGComp = StatusSVG;
  public static readonly text: SVGComp = TextSVG;
  public static readonly time: SVGComp = TimeSVG;
  public static readonly translation: SVGComp = TranslationSVG;
  public static readonly upload: SVGComp = UploadSVG;
  public static readonly video: SVGComp = VideoSVG;
  public static readonly volume: SVGComp = VolumeSVG;
  public static readonly check: SVGComp = CheckSVG;
  public static readonly days: SVGComp = DaysSVG;
  public static readonly loading: SVGComp = LoadingSVG;
  public static readonly refresh: SVGComp = RefreshSVG;
  public static readonly pause: SVGComp = PauseSVG;
  public static readonly merge: SVGComp = MergeSVG;
  public static readonly delete: SVGComp = DeleteSVG;
  public static readonly split: SVGComp = SplitSVG;
}

export type IconNames = keyof typeof IconsMapping;

interface InterfaceIcon extends ClassNameOverrideInterface {
  name: IconNames;
  colour?: ColoursText;
  size?: 2 | 3 | 4 | 5 | 6;
  loading?: boolean;
  asElement?: React.ElementType;
  rotate?: 90 | 180 | 270;
}

export const Icon: WithAsElementProp<InterfaceIcon> = ({
  name,
  asElement,
  colour,
  size = 2,
  loading,
  classNameOverride,
  rotate,
  ...rest
}: any) => {
  const Component: React.ElementType = asElement || "i";
  // @ts-ignore
  const IconSVG: SVGComp = (IconsMapping[name] as SVGComp) || null;
  const dimensions = `w${size} h${size}`;
  return (
    <Component
      className={classNames(
        "di",
        colour || "c-secondary",
        dimensions,
        loading && "rotate360-keyframe-ef",
        rotate && `rotate-${rotate}`,
        classNameOverride
      )}
      {...rest}
    >
      {IconSVG && <IconSVG className={dimensions} />}
    </Component>
  );
};
