import { gql } from "apollo-boost";
import { SegmentFragment } from "../../../graphql/translatedVideo";
import { StudioSegment } from "../../../@types/data";
import { isSegmentUpToDate } from "../utils";
import { CREATE_SAMPLE_FROM_SEGMENT } from "../mutations";

export const bulkCreateSampleFromTranslatedVideo = async (
  rootValue: any,
  args: any,
  { cache, getCacheKey, client }: any
) => {
  const id = getCacheKey({
    __typename: "TranslatedVideo",
    id: args.id
  });
  const fragment = gql`
    fragment segmentsWithLang on TranslatedVideo {
      channel {
        language
      }
      segments {
        ...SegmentFragment
      }
    }
    ${SegmentFragment}
  `;
  const {
    segments,
    channel: { language }
  }: {
    segments: StudioSegment[];
    channel: { language: string };
  } = cache.readFragment({
    fragment,
    id,
    fragmentName: "segmentsWithLang"
  });

  for (const s of segments) {
    if (!isSegmentUpToDate(s)) {
      const {
        data: { createSampleFromSegment: segmentUpdated }
      } = await client.mutate({
        mutation: CREATE_SAMPLE_FROM_SEGMENT,
        variables: {
          id: s.id,
          language
        }
      });
      const segmentKey = getCacheKey({
        __typename: "Segment",
        id: s.id
      });
      debugger;
      client.writeData({ id: segmentKey, data: segmentUpdated });
    }
  }
};
