import { FlexRow } from "./Container";
import { H1 } from "./Typography";
import * as React from "react";

export const LoadingPage = (props: any) => (
  <FlexRow
    id="layout"
    background={"bg-primary"}
    dimensions={{ h: "vh-100", w: "vw-100" }}
    flex={{
      fai: "items-center",
      fjc: "justify-center"
    }}
    {...props}
  >
    <H1 classNameOverride={"slide-in-bottom-keyframe-ef"}>Loading...</H1>
  </FlexRow>
);
