import * as React from "react";
import { colours, baseColours } from "../../themes/colours";
import classNames from "classnames";
import { FlexRow, Container, InterfaceContainer } from "../Container";
import { ClassNameOverrideInterface } from "../../@types/component";
import { Label } from "../Typography";
import { Margin } from "../../@types/styling";

interface CheckboxAndRadioInterface
  extends ClassNameOverrideInterface,
    Pick<InterfaceContainer, "layout" | "dimensions">,
    React.InputHTMLAttributes<HTMLInputElement> {
  reverse?: boolean;
  type: "checkbox" | "radio";
  label?: string;
  labelMargin?: Margin;
}

export type CheckboxAndRadioType = Omit<CheckboxAndRadioInterface, "type">;

export const CheckboxAndRadioBase: React.FunctionComponent<
  CheckboxAndRadioInterface
> = ({
  children,
  type,
  reverse,
  classNameOverride,
  label,
  dimensions,
  layout,
  labelMargin,
  ...rest
}) => {
  return (
    <FlexRow
      asElement={"label"}
      dimensions={Object.assign({ h: "h4" }, dimensions)}
      layout={Object.assign({ pos: "relative" }, layout)}
      flex={{
        fd: reverse ? "flex-row-reverse" : "flex-row",
        fai: "items-center",
        fjc: reverse ? "justify-between" : "justify-start"
      }}
      classNameOverride={classNames("container", classNameOverride)}
    >
      <input type={type} className="absolute o-0 h0 w0" {...rest} />
      <FlexRow
        dimensions={{ h: "h4", w: "w4" }}
        flex={{
          fai: "items-center",
          fjc: "justify-center"
        }}
        classNameOverride={"checkIconContainer"}
      >
        <Container
          layout={{ pos: "relative" }}
          dimensions={{ h: "h1", w: "w1" }}
          border={{
            bc: "b--gray-20",
            bs: "b--solid",
            bw: "bw1",
            br: type === "radio" ? "br-100" : "br2"
          }}
          classNameOverride={classNames("checkIcon overflow-hidden", {
            pointer: !rest.disabled
          })}
        >
          <Container
            dimensions={{ h: "h-100", w: "w-100" }}
            layout={{ d: "db" }}
            classNameOverride={"inner highlight-hover-ef hover-ef"}
          />
        </Container>
      </FlexRow>
      <div
        className={classNames({
          pointer: !rest.disabled
        })}
      >
        {children}
        {!children && label && (
          <Label
            colour={rest.disabled ? "c-secondary" : "c-primary"}
            m={labelMargin}
          >
            {label}
          </Label>
        )}
      </div>
      <style jsx global>{`
        .inner {
          box-shadow: inset 0 0 0px 0px ${colours["c-primary"]};
          transition: box-shadow 0.2s;
        }
        .container:hover .checkIconContainer .checkIcon,
        .container:hover
          input:not([disabled]):checked
          ~ .checkIconContainer
          .checkIcon,
        .container
          input:checked:not([disabled]):active
          ~ .checkIconContainer
          .checkIcon,
        .container
          input:checked:not([disabled]):focus
          ~ .checkIconContainer
          .checkIcon {
          border: 1px solid ${baseColours["near-white-10"]};
        }
        .container input:checked ~ .checkIconContainer > .checkIcon > .inner {
          box-shadow: inset 0 0 0px 4px ${colours["c-brand"]};
          background: ${colours["c-primary"]};
        }
      `}</style>
    </FlexRow>
  );
};

export const Checkbox: React.FunctionComponent<CheckboxAndRadioType> = (
  props: CheckboxAndRadioType
) => <CheckboxAndRadioBase type={"checkbox"} {...props} />;
