import classNames from "classnames";
import * as React from "react";
import { Optional } from "utility-types";
import { WithAsElementPropWithRef } from "../@types/component";
import { TextProperties, Margin } from "../@types/styling";

interface InterfaceTypographyFactory extends TextProperties {
  asElement: React.ElementType;
  m?: Margin | Margin[];
}

interface InterfaceBaseTypographyComponent
  extends Optional<InterfaceTypographyFactory> {
  classNameOverride?: string | { [key: string]: boolean };
  ref?: React.RefObject<any>;
}

const typographyFactory = (props: InterfaceTypographyFactory) => {
  const BaseTypographyComponent: WithAsElementPropWithRef<
    InterfaceBaseTypographyComponent
  > = React.forwardRef(
    (
      {
        asElement,
        ff,
        flh,
        fw,
        fs,
        tt,
        i,
        colour,
        m,
        children,
        classNameOverride,
        ...rest
      }: any,
      ref: any
    ) => {
      const Component: React.ElementType = asElement || props.asElement;

      return (
        <Component
          ref={ref}
          className={classNames(
            ff || props.ff || "pff",
            flh || props.flh,
            fs || props.fs,
            fw || props.fw,
            tt || props.tt,
            colour || props.colour || "c-primary",
            i || props.i ? "i" : null,
            m,
            classNameOverride
          )}
          {...rest}
        >
          {children}
        </Component>
      );
    }
    // This took a while to figure out, and this solution worked
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-488848720
  ) as WithAsElementPropWithRef<InterfaceBaseTypographyComponent>;

  return BaseTypographyComponent;
};

export const H1 = typographyFactory({
  asElement: "h1",
  flh: "lh-f1",
  fw: "fw8",
  fs: "f1"
});
export const H2 = typographyFactory({
  asElement: "h2",
  flh: "lh-f2",
  fw: "fw8",
  fs: "f2"
});
export const H3 = typographyFactory({
  asElement: "h3",
  flh: "lh-f3",
  fw: "fw8",
  fs: "f3"
});
export const H4 = typographyFactory({
  asElement: "h4",
  flh: "lh-f4",
  fw: "fw6",
  fs: "f4"
});
export const CTA = typographyFactory({
  asElement: "span",
  flh: "lh-f5",
  fw: "fw8",
  fs: "f5",
  colour: "c-brand",
  tt: "ttu"
});
export const Label = typographyFactory({
  asElement: "span",
  flh: "lh-f5",
  fw: "fw5",
  fs: "f5"
});
export const Paragraph = typographyFactory({
  asElement: "p",
  flh: "lh-f5",
  fw: "fw5",
  fs: "f5"
});
export const LabelSmall = typographyFactory({
  asElement: "span",
  flh: "lh-f6",
  fw: "fw5",
  fs: "f6"
});
export const Placeholder = typographyFactory({
  asElement: "span",
  flh: "lh-f5",
  fw: "fw3",
  fs: "f5",
  colour: "c-secondary",
  i: true
});
