import { InMemoryCache, Resolvers } from "apollo-boost";
import ApolloClient from "apollo-client";
import { bulkCreateSampleFromTranslatedVideo } from "./localMutations/bulkCreateSampleFromTranslatedVideo";

export const STUDIO_RESOLVERS: Resolvers = {
  Mutation: {
    bulkCreateSampleFromTranslatedVideo
  }
};

export const cacheStudioDefaultsInit = (
  client: ApolloClient<InMemoryCache>
) => {
  client.cache.writeData({
    data: {
      sourceAudio: "source",
      sourceVolume: 1,
      sidebarSize: "mw3",
      isPlaying: false,
      syncState: "Saved",
      mediaDuration: -1
    }
  });
};
