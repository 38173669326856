import * as React from "react";
import { AuthClass } from "@aws-amplify/auth";
import { Profile, EventTypes } from "../@types/data";
import { PlaySegmentType, UpdateSegmentType } from "../@types/functionTypes";

export interface InterfaceProfileContext {
  profile: Profile;
  isAuthenticated: boolean;
  authChecked: boolean;
  token: string;
  signUpWithEmail: AuthClass["signUp"];
  loginWithEmail: AuthClass["signIn"];
  loginWithSocial: AuthClass["federatedSignIn"];
  logout: AuthClass["signOut"];
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const ProfileContext = React.createContext<InterfaceProfileContext>({});

export interface InterfaceStudioContext {
  updateSegment: UpdateSegmentType;
  createSampleFromSegment: (id: string) => void;
  deleteSegmentFromTimeline: (id: string) => void;
  playSegment: PlaySegmentType;
  on: (event: EventTypes, f: Function) => void;
  translationLanguage: string;
  sourceLanguage: string;
  speakers: { value: string; label: string }[];
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const StudioContext = React.createContext<InterfaceStudioContext>({});
